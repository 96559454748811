<template>
  <Dialog>
    <p>You're already up to level {{ maxLevel + 1 }}!</p>
    <p>Starting a new game will clear your progress.</p>
    <p>Are you sure you want to do this?</p>
    <div class="controls-container">
      <Button @click="yes">Yes</Button>
      <Button @click="no">No</Button>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Button from '@/components/Button.vue'

import { logger } from '@/lib/firebase.js'

export default {
  name: 'ClearProgressDialog',
  components: { Dialog, Button },
  computed: {
    maxLevel() {
      return this.$store.getters.maxLevel
    },
  },
  mounted() {
    logger.showPopup('clear_progress_dialog')
  },
  beforeDestroy() {
    logger.closePopup('clear_progress_dialog')
  },
  methods: {
    yes() {
      logger.buttonClick('clear_progress_yes')
      const callBack = this.$store.getters.showClearProgressDialog?.yesCallback
      setTimeout(() => {
        callBack && callBack()
      }, 300)

      this.$store.commit('SET_SHOW_CLEAR_PROGRESS_DIALOG', undefined)
    },
    no() {
      logger.buttonClick('clear_progress_no')
      this.$store.getters.showClearProgressDialog?.noCallback &&
        this.$store.getters.showClearProgressDialog?.noCallback()
      this.$store.commit('SET_SHOW_CLEAR_PROGRESS_DIALOG', undefined)
    },
  },
}
</script>

<style scoped>
.controls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.controls-container > * + * {
  margin-left: 1rem;
}

p:first-of-type {
  margin-top: 0px;
}

p {
  text-align: center;
}
</style>
