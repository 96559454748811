<template>
  <div id="main-container" class="fullscreen-container">
    <div id="level-complete-anim"></div>
  </div>
</template>

<script>
import lottie from 'lottie-web'
import gsap from 'gsap'
import * as levelCompleteAnimData from '@/assets/animations/levelCompleteAnim.json'
import { config } from '@/lib/dynamicConfig.js'

export default {
  name: 'LevelComplete2',
  data() {
    return {
      levelCompleteAnimData,
    }
  },
  mounted() {
    const tl = gsap.timeline({ repeat: 0 })
    setTimeout(() => {
      this.$emit('next_level')
    }, 800)

    tl.fromTo(
      '#main-container',
      {
        backgroundColor: 'rgb(0,0,0,0)',
      },
      {
        backgroundColor: 'rgb(0,0,0,0.3)',
        duration: 0.3,
      }
    )

    tl.to(
      '#main-container',
      {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          this.$emit('close')
        },
      },
      1.2
    )
    const anim = lottie.loadAnimation({
      container: document.getElementById('level-complete-anim'),
      animationData: levelCompleteAnimData.default,
      renderer: 'svg',
      autoplay: false,
      loop: false,
    })

    if (config.SCREENSHOT_MODE) {
      anim.goToAndStop(12, true)
    } else {
      anim.goToAndPlay(0)
    }
  },
}
</script>

<style scoped>
.fullscreen-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
}

#level-complete-anim {
  width: 150%;
  max-width: 30rem;
}
</style>
