<template>
  <div>
    <MultiSwitcher
      :currentStage="
        stageMap({
          intro: ['intro', 'purchasing', 'remind'],
          thanks: ['thanks'],
          error: ['error'],
        })
      "
      @switched="isPurchaseInProgress = false"
    >
      <template v-slot:intro>
        <span v-html="settings['introText']"></span>
        <div class="controls-container">
          <Button
            @click="purchase(PREMIUM_VERSION_ITEM)"
            :loading="isPurchaseInProgress"
            :disabled="!productsKnown.includes(PREMIUM_VERSION_ITEM)"
          >
            <span v-if="productsKnown.includes(PREMIUM_VERSION_ITEM)">
              {{ settings['ctaText'].replace('{PRICE}', premiumVersionPrice) }}
            </span>
            <span v-else>Upgrade temporarily unavailable</span>
          </Button>
          <Button @click="restorePurchases" :loading="restoringPurchases">
            Restore purchases
          </Button>
        </div>
      </template>
      <template v-slot:thanks>
        <span v-html="settings['thanksText']"></span>
      </template>
      <template v-slot:error>
        <span v-html="settings['errorText']"></span>
      </template>
    </MultiSwitcher>
  </div>
</template>

<script>
import MultiSwitcher from '@/components/MultiSwitcher.vue'
import Button from '@/components/Button.vue'

import { config } from '@/lib/dynamicConfig.js'
import { providerStore } from '@/lib/purchases.js'
import purchaseMixin from '@/lib/purchaseMixin.js'
import popupAnalyticsMixin from '@/lib/popupAnalyticsMixin.js'

export default {
  name: 'UpgradeFromSettingsContent',
  components: { MultiSwitcher, Button },
  mixins: [purchaseMixin, popupAnalyticsMixin],
  data() {
    return {
      isPurchaseInProgress: false,
      itemPurchased: undefined,
      stage: undefined,
      restoringPurchases: false,
      settings: config.SETTINGS_UPGRADE_TEXT,
      PREMIUM_VERSION_ITEM: config.PREMIUM_VERSION_ITEM,
      productsOffered: [config.PREMIUM_VERSION_ITEM],
    }
  },
  created() {
    if (this.anyPurchaseMade) {
      this.stage = 'thanks'
    } else {
      this.stage = 'intro'
    }
  },
  mounted() {
    this.logOpen()
  },
  beforeDestroy() {
    this.logClose()
  },
  computed: {
    anyPurchaseMade() {
      return this.$store.getters.anyPurchaseMade
    },
    premiumVersionPrice() {
      return this.$store.getters.getPriceForItem(config.PREMIUM_VERSION_ITEM)
    },
    name() {
      return `settings_${config.MONETISATION_STRATEGY}`
    },
  },
  methods: {
    stageMap(stageMap) {
      return (
        Object.keys(stageMap).filter((k) =>
          stageMap[k].includes(this.stage)
        )[0] || ''
      )
    },
    restorePurchases() {
      this.logClick('restore')
      this.restoringPurchases = true
      providerStore.refresh().finished(() => {
        this.restoringPurchases = false
      })
    },
  },
  watch: {
    stage(new_val, old_val) {
      if (new_val == 'remind') {
        this.isPurchaseInProgress = false
      } else if (!(old_val === undefined)) {
        this.logTransition()
      }
    },
    anyPurchaseMade(new_val, old_val) {
      if (new_val && !old_val) {
        this.stage = 'thanks'
      }
    },
  },
}
</script>

<style scoped>
.controls-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
