<template>
  <Dialog
    :canScroll="['rectangles_as_well_got_stuck'].includes(instructionStage)"
  >
    <template v-if="instructionStage == 'draw_dots'">
      <MultiSwitcher :currentStage="subStage">
        <template v-slot:one>
          <p>Hey there!</p>
          <p>Would you like some help to get started?</p>
          <div class="controls-container">
            <Button @click="yesToHelp">Yes</Button>
            <Button @click="noToHelp">No</Button>
          </div>
        </template>
        <template v-slot:two>
          <div class="no-scroll-wrapper">
            <p>
              Let's start by drawing a square on the dots to make them move.
            </p>
            <InstructionDiagram
              :ballGrid="[
                [2, 1],
                [1, 1],
              ]"
              :goalGrid="[
                [0, 0],
                [0, 2],
              ]"
              :drawPoints="[
                [0, 0],
                [0, 1],
                [1, 1],
                [1, 0],
              ]"
              :playAnimation="
                instructionStage == 'draw_dots' && subStage == 'two'
              "
            />
            <div class="controls-container">
              <Button @click="close" style="margin-top: 1em">Ok</Button>
            </div>
          </div>
        </template>
      </MultiSwitcher>
    </template>
    <template v-else-if="instructionStage == 'green_into_target'">
      <div class="no-scroll-wrapper">
        <p>Nice work!</p>
        <p>
          Now use what you've learnt to move the green dot into the green target
          to beat the level.
        </p>
        <InstructionDiagram
          :ballGrid="[
            [1, 1],
            [1, 2],
          ]"
          :goalGrid="[
            [0, 0],
            [0, 2],
          ]"
        />
        <div class="controls-container">
          <Button @click="close" style="margin-top: 1em">Ok</Button>
        </div>
      </div>
    </template>
    <template v-else-if="instructionStage == 'rectangles_as_well_1'">
      <MultiSwitcher :currentStage="subStage">
        <template v-slot:one>
          <div class="no-scroll-wrapper">
            <p>
              Squares are great, but you can also move those dots by drawing
              rectangles as well.
            </p>
            <InstructionDiagram
              :ballGrid="[
                [1, 2],
                [0, 1],
                [1, 1],
              ]"
              :goalGrid="[
                [0, 0],
                [0, 0],
                [0, 2],
              ]"
              :drawPoints="[
                [0, 0],
                [1, 0],
                [1, 1],
                [1, 2],
                [0, 2],
                [0, 1],
              ]"
              :playAnimation="instructionStage == 'rectangles_as_well_1'"
            />
            <div class="controls-container">
              <Button @click="subStage = 'two'" style="margin-top: 1em">
                Ok
              </Button>
            </div>
          </div>
        </template>
        <template v-slot:two>
          <p>Here's a hint:</p>
          <p style="font-size: 2rem; margin-top: 0rem; margin-bottom: 0rem">
            &#x1F4A1;
          </p>
          <p>
            To beat this level, you'll need to draw a rectangle and then at
            least one square.
          </p>
          <div class="controls-container">
            <Button @click="close">Ok</Button>
          </div>
        </template>
      </MultiSwitcher>
    </template>
    <template v-else-if="instructionStage == 'rectangles_as_well_2'">
      <MultiSwitcher :currentStage="subStage">
        <template v-slot:one>
          <div class="no-scroll-wrapper">
            <p>The direction you draw your shapes also matters.</p>
            <InstructionDiagram
              :ballGrid="[
                [2, 1],
                [1, 0],
                [1, 1],
              ]"
              :goalGrid="[
                [0, 0],
                [0, 0],
                [2, 0],
              ]"
              :drawPoints="[
                [0, 1],
                [0, 2],
                [1, 2],
                [1, 1],
                [1, 0],
                [0, 0],
              ]"
              :playAnimation="instructionStage == 'rectangles_as_well_2'"
            />
            <div class="controls-container">
              <Button @click="subStage = 'two'" style="margin-top: 1em">
                Ok
              </Button>
            </div>
          </div>
        </template>
        <template v-slot:two>
          <p>Here's a hint:</p>
          <p style="font-size: 2rem; margin-top: 1rem; margin-bottom: 1rem">
            &#x1F4A1;
          </p>
          <p>
            This level is the same as the last. Just draw everything in the
            opposite direction.
          </p>
          <div class="controls-container">
            <Button @click="close">Ok</Button>
          </div>
        </template>
      </MultiSwitcher>
    </template>
    <template v-else-if="instructionStage == 'rectangles_as_well_got_stuck'">
      <MultiSwitcher :currentStage="subStage">
        <template v-slot:one>
          <p>Uh oh, looks like you're stuck!</p>
          <p>You won't be able to complete the level from this position.</p>
          <p>Hit the reset button at the bottom to go back to the start.</p>
          <svg class="icon" viewBox="0 0 24 24">
            <path :d="mdiRestore" />
          </svg>
          <div class="controls-container">
            <Button @click="subStage = 'two'">Ok</Button>
          </div>
        </template>
        <template v-slot:two>
          <p>Here's a hint:</p>
          <p style="font-size: 2rem; margin-top: 1rem; margin-bottom: 1rem">
            &#x1F4A1;
          </p>
          <p>
            Next time, try drawing that first rectangle in the opposite
            direction.
          </p>
          <div class="controls-container">
            <Button @click="close">Ok</Button>
          </div>
        </template>
      </MultiSwitcher>
    </template>
    <template v-else-if="instructionStage == 'big_shapes'">
      <div class="no-scroll-wrapper">
        <p>
          If all four corners are present, you can draw squares and rectangles
          of any size.
        </p>
        <InstructionDiagram
          :ballGrid="[
            [2, 0, 1],
            [1, 1, 0],
            [1, 0, 1],
          ]"
          :goalGrid="[
            [0, 0, 0],
            [0, 0, 0],
            [0, 2, 0],
          ]"
          :drawPoints="[
            [0, 0],
            [0, 1],
            [0, 2],
            [1, 2],
            [2, 2],
            [2, 1],
            [2, 0],
            [1, 0],
          ]"
          :playAnimation="instructionStage == 'big_shapes'"
        />
        <div class="controls-container">
          <Button @click="close" style="margin-top: 1em">Ok</Button>
        </div>
      </div>
    </template>
    <template v-else-if="instructionStage == 'big_shapes_got_stuck'">
      <MultiSwitcher :currentStage="subStage">
        <template v-slot:one>
          <p>Uh oh, looks like you're stuck!</p>
          <p>You won't be able to complete the level from this position.</p>
          <p>Hit the reset button at the bottom to go back to the start.</p>
          <svg class="icon" viewBox="0 0 24 24">
            <path :d="mdiRestore" />
          </svg>
          <div class="controls-container">
            <Button @click="subStage = 'two'">Ok</Button>
          </div>
        </template>
        <template v-slot:two>
          <p>Here's a hint:</p>
          <p style="font-size: 2rem; margin-top: 1rem; margin-bottom: 1rem">
            &#x1F4A1;
          </p>
          <p>
            Next time, try drawing that first square in the opposite direction.
          </p>
          <div class="controls-container">
            <Button @click="close">Ok</Button>
          </div>
        </template>
      </MultiSwitcher>
    </template>
    <template v-else-if="instructionStage == 'off_you_go'">
      <p>Time to put everything you've learnt into practice!</p>
      <p style="font-size: 2rem; margin-top: 0rem; margin-bottom: 0rem">
        &#x1F914;
      </p>
      <p>
        We'll let you know if you get stuck. But after this level you'll need to
        figure that out on your own.
      </p>
      <div class="controls-container">
        <Button @click="tutorialEnd">Ok</Button>
      </div>
    </template>
    <template v-else-if="instructionStage == 'got_stuck'">
      <p>Uh oh, looks like you're stuck!</p>
      <p>
        Hit that reset button at the bottom to go back to the start and have
        another go.
      </p>
      <svg class="icon" viewBox="0 0 24 24">
        <path :d="mdiRestore" />
      </svg>
      <div class="controls-container">
        <Button @click="close">Ok</Button>
      </div>
    </template>
    <template v-else-if="instructionStage == 'lives_tip'">
      <MultiSwitcher :currentStage="livesStage">
        <template v-slot:lives>
          <p>Ever wish you could go back in time?</p>
          <p>
            Hit the undo button to undo the last move. You only have a limited
            number of these, so use them wisely!
          </p>
          <svg class="icon" viewBox="0 0 24 24">
            <path :d="mdiUndoVariant" />
          </svg>
          <div class="controls-container">
            <Button @click="moveFromLivesStage">Ok</Button>
          </div>
        </template>
        <template v-slot:reset>
          <p>Sometimes it's easier to start afresh.</p>
          <p>
            If you've got yourself into a real mess, you can always hit the
            reset button to go back to the start. This will also reset your
            undos.
          </p>
          <svg class="icon" viewBox="0 0 24 24">
            <path :d="mdiRestore" />
          </svg>
          <div class="controls-container">
            <Button @click="close">Ok</Button>
          </div>
        </template>
      </MultiSwitcher>
    </template>
  </Dialog>
</template>

<script>
import { mdiRestore, mdiUndoVariant } from '@mdi/js'

import Button from '@/components/Button.vue'
import Dialog from '@/components/Dialog.vue'
import MultiSwitcher from '@/components/MultiSwitcher.vue'
import InstructionDiagram from '@/components/InstructionDiagram.vue'

import { logger } from '@/lib/firebase.js'

export default {
  name: 'InstructionPopup',
  components: { Button, Dialog, MultiSwitcher, InstructionDiagram },
  data() {
    return {
      mdiRestore,
      mdiUndoVariant,
      drawDotsStage: 'one',
      youreStuckStage: 'one',
      subStage: 'one',
      livesStage: 'lives',
    }
  },
  mounted() {
    logger.showPopup('instructions', this.analyticsParams)
  },
  computed: {
    instructionStage() {
      return this.$store.getters.showInstructionStage
    },
    analyticsParams() {
      if (this.instructionStage == 'draw_dots') {
        return {
          stage: this.instructionStage,
          substage: this.drawDotsStage,
        }
      } else if (this.instructionStage == 'lives_tip') {
        return {
          stage: this.instructionStage,
          substage: this.livesStage,
        }
      } else {
        return {
          stage: this.instructionStage,
        }
      }
    },
  },
  methods: {
    moveFromLivesStage() {
      if (this.$store.getters.hasStageBeenSeen('got_stuck')) {
        this.close()
      } else {
        this.livesStage = 'reset'
        this.$store.dispatch('mark_stage_seen', 'got_stuck')
      }
    },
    yesToHelp() {
      logger.buttonClick('instructions_yes')
      logger.tutorialBegin()
      this.subStage = 'two'
    },
    noToHelp() {
      logger.buttonClick('instructions_no')
      this.closeForever()
    },
    tutorialEnd() {
      logger.tutorialComplete()
      this.close()
    },
    close() {
      logger.buttonClick('instructions_close')
      this.logClose()
      this.$store.dispatch('hide_instruction')
    },
    closeForever() {
      this.logClose()
      this.$store.dispatch('hide_instruction_forever')
    },
    logScreenView() {
      logger.transitionPopup('instructions', this.analyticsParams)
    },
    logClose() {
      logger.closePopup('instructions', this.analyticsParams)
    },
  },
  watch: {
    /*drawDotsStage() {
      this.logScreenView()
    },*/
    livesStage() {
      this.logScreenView()
    },
  },
}
</script>

<style scoped>
.controls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/*
Container that won't go any higher than the fullscreen display height
of the Dialog box
*/
.no-scroll-wrapper {
  max-height: calc(90vh - 6rem);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon {
  width: 3.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon path {
  fill: var(--main-item-colour);
}

.controls-container > * + * {
  margin-left: 1rem;
}

p {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 1em;
}

p + p {
  margin-top: 1em;
}
</style>
