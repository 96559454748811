<template>
  <Dialog :allowModalClose="true" @modal_close="close">
    <p>Change MONETISATION_STRATEGY</p>
    <p>Current setting: {{ currentStrategy }}</p>
    <Button v-if="currentStrategy != 'none'" @click="changeTo('none')">
      Change to 'none'
    </Button>
    <Button
      v-if="currentStrategy != 'level_def'"
      @click="changeTo('level_def')"
    >
      Change to 'level_def'
    </Button>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Button from '@/components/Button.vue'

import { config } from '@/lib/dynamicConfig.js'
export default {
  name: 'MonetisationStrategyBackdoorDialog',
  components: { Dialog, Button },
  data() {
    return {
      currentStrategy: undefined,
    }
  },
  created() {
    this.currentStrategy = config.MONETISATION_STRATEGY
  },
  methods: {
    close() {
      this.$store.commit('SET_SHOW_BACKDOOR_DIALOG', false)
    },
    changeTo(val) {
      config.MONETISATION_STRATEGY = val
      this.close()
    },
  },
}
</script>

<style scoped></style>
