import { config } from '@/lib/dynamicConfig.js'
import { Storage } from '@capacitor/storage'
import { isJson, isString } from '@/lib/helpers.js'
import consoleLog from '@/lib/consoleLog.js'

export const state = {
  storeAvailable: false,
  products: {},
  premiumEnabledTest: false,
  persistedAnyPurchaseMade: false,
  persistedProductsOwned: [],
}

export const mutations = {
  SET_STORE_AVAILABLE(state, val) {
    state.storeAvailable = val
  },
  SET_PRODUCTS(state, val) {
    state.products = val
  },
  SET_PREMIUM_ENABLED_TEST(state, val) {
    state.premiumEnabledTest = val
  },
  SET_PERSISTED_PRODUCTS_OWNED(state, val) {
    state.persistedProductsOwned = val
    Storage.set({ key: 'persistedProductsOwned', value: JSON.stringify(val) })
  },
}

export const actions = {
  async initialise_purchases({ commit, state, getters }) {
    const { value } = await Storage.get({
      key: 'persistedProductsOwned',
    })
    const persistedProductsOwned = isJson(value) ? JSON.parse(value) : null
    commit(
      'SET_PERSISTED_PRODUCTS_OWNED',
      Array.isArray(persistedProductsOwned) &&
        persistedProductsOwned.every((x) => isString(x))
        ? persistedProductsOwned
        : []
    )
    consoleLog('persistedProductsOwned = ', state.persistedProductsOwned)
    consoleLog('getters.anyPurchaseMade = ', getters.anyPurchaseMade)
  },
  update_product_in_store({ commit, state }, product) {
    commit('SET_PRODUCTS', {
      ...state.products,
      [product.id]: product,
    })
  },
  add_product_owned({ commit, state }, productId) {
    if (!state.persistedProductsOwned.includes(productId)) {
      commit('SET_PERSISTED_PRODUCTS_OWNED', [
        ...state.persistedProductsOwned,
        productId,
      ])
    }
  },
  remove_product_owned({ commit, state }, productId) {
    if (state.persistedProductsOwned.includes(productId)) {
      commit(
        'SET_PERSISTED_PRODUCTS_OWNED',
        state.persistedProductsOwned.filter((x) => x != productId)
      )
    }
  },
}

export const getters = {
  products: (state) => state.products,
  productsKnown: (state) =>
    Object.keys(state.products).filter((k) => state.products[k].valid),
  productsOwned: (state) => {
    return Object.keys(state.products).filter((k) => state.products[k].owned)
  },
  beveragesOwned: (state) =>
    state.persistedProductsOwned.filter((p) =>
      config.BEGGING_SETTINGS.items.map((i) => i.id).includes(p)
    ),
  anyPurchaseMade: (state, getters) => {
    const eligibleProducts = config.PREMIUM_VERSION_PRODUCTS
    return eligibleProducts.some(
      (p) =>
        state.persistedProductsOwned.includes(p) ||
        getters.productsOwned.includes(p)
    )
  },
  getPriceForItem: (state, getters) => (itemId) =>
    getters.products[itemId]?.price || '????',
}
