var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"svg-container",staticClass:"svg-container",style:(_vm.svgContainerWidthStyle)},[_c('svg',{style:(_vm.svgHeightStyle),attrs:{"version":"1.1","width":"100%","viewBox":("-35 -35 " + (_vm.xDim * 100 + 70) + " " + (_vm.yDim * 100 + 70)),"preserveAspectRatio":"xMidYMid meet","xmlns":"http://www.w3.org/2000/svg","id":"svg-main"}},[_vm._l((_vm.goalCoordinates),function(g){return [_c('rect',{key:g.id,staticClass:"goal-rectangle",class:{
          key2: g.type == 2,
          key3: g.type == 3,
          key4: g.type == 4,
          key5: g.type == 5,
          complete: _vm.completeGoals.map(function (x) { return x.id; }).includes(g.id),
        },attrs:{"id":g.id,"x":g.x,"y":g.y,"width":"90","height":"90","rx":"10","ry":"10"}})]}),_vm._l((_vm.ballCoordinates),function(b){
        var _obj, _obj$1, _obj$2;
return [_c('circle',{key:b.id,staticClass:"ball",class:( _obj = {
          hidden: b.type == 0 || _vm.hideAllBalls,
          normal: b.type == 1,
          key2: b.type == 2,
          key3: b.type == 3,
          key4: b.type == 4,
          key5: b.type == 5
        }, _obj[b.id] = true, _obj ),attrs:{"id":b.id,"cx":b.x,"cy":b.y,"r":"35"},on:{"mousedown":function($event){return _vm.mousedownOnBall(b, $event)},"touchstart":function($event){return _vm.mousedownOnBall(b, $event)}}}),(_vm.colourblindModeEnabled && b.type >= 2)?_c('circle',{key:((b.id) + "_bg"),staticClass:"ball",class:( _obj$1 = {}, _obj$1[b.id] = true, _obj$1 ),attrs:{"id":b.id,"cx":b.x,"cy":b.y,"r":"20","fill":"rgba(0,0,0,0.5)"},on:{"mousedown":function($event){return _vm.mousedownOnBall(b, $event)},"touchstart":function($event){return _vm.mousedownOnBall(b, $event)}}}):_vm._e(),(_vm.colourblindModeEnabled && b.type >= 2)?_c('text',{key:((b.id) + "_text"),staticClass:"noselect ball text",class:( _obj$2 = {}, _obj$2[b.id] = true, _obj$2 ),attrs:{"text-anchor":"middle","dominant-baseline":"central","x":b.x,"y":b.y,"fill":"white"},on:{"mousedown":function($event){return _vm.mousedownOnBall(b, $event)},"touchstart":function($event){return _vm.mousedownOnBall(b, $event)}}},[_vm._v(" "+_vm._s(b.type - 1)+" ")]):_vm._e()]}),(_vm.colourblindModeEnabled)?[_vm._l((_vm.goalCoordinates),function(g){return [_c('rect',{key:((g.id) + "_bg"),attrs:{"x":g.x,"y":g.y + 92,"width":"90","height":"35","rx":"10","ry":"10","fill":"rgba(0,0,0,0.5)"}}),_c('text',{key:((g.id) + "_text"),staticClass:"noselect text",attrs:{"text-anchor":"middle","dominant-baseline":"central","x":g.x + 45,"y":g.y + 109.5,"fill":"white"}},[_vm._v(" "+_vm._s(g.type - 1)+" ")])]})]:_vm._e(),(_vm.linePath.length > 0)?_c('path',{staticClass:"line",class:{ square: _vm.squareMade },attrs:{"d":_vm.linePath}}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }