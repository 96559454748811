<template>
  <div class="multiswitcher-main-container" ref="multiswitcher-main-container">
    <div class="multiswitcher-height-strut" ref="height-strut"></div>
    <div
      v-for="stage in stages"
      class="multiswitcher-content-wrapper"
      :ref="stage"
      :key="stage"
    >
      <slot :name="stage"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSwitcher',
  props: {
    currentStage: String,
  },
  computed: {
    stages() {
      return Object.keys(this.$scopedSlots)
    },
  },
  mounted() {
    this.stages
      .filter((s) => s != this.currentStage)
      .forEach((s) => {
        const elem = this.$refs[s][0]
        elem.style.visibility = 'hidden'
        elem.style.position = 'absolute'
        elem.style.display = 'none'
        elem.style.opacity = 0
      })
  },
  methods: {
    debug() {
      debugger
    },
    transitionElement(elem) {
      return new Promise((resolve) => {
        elem.addEventListener(
          'transitionend',
          function transitionEnd(event) {
            event.stopPropagation()
            if (
              (event.target.classList.contains('multiswitcher-height-strut') &&
                event.propertyName == 'height') ||
              (event.target.classList.contains(
                'multiswitcher-content-wrapper'
              ) &&
                event.propertyName == 'opacity')
            ) {
              elem.removeEventListener('transitionend', transitionEnd)
              resolve()
            }
          }.bind(this)
        )
      })
    },
    fadeOut(ref) {
      if (!this.stages.includes(ref)) {
        return Promise.resolve()
      }
      const elem = this.$refs[ref][0]
      elem.style.opacity = 0

      return this.transitionElement(elem)
    },
    fadeIn(ref) {
      if (!this.stages.includes(ref)) {
        return Promise.resolve()
      }

      const elem = this.$refs[ref][0]
      elem.style.visibility = null
      elem.style.opacity = 1

      return this.transitionElement(elem)
    },
    transitionHeightStrutFromTo(old_ref, new_ref) {
      const oldElem = this.stages.includes(old_ref)
        ? this.$refs[old_ref][0]
        : undefined
      const oldHeight = oldElem?.getBoundingClientRect().height || 0

      const newElem = this.stages.includes(new_ref)
        ? this.$refs[new_ref][0]
        : undefined
      if (!(newElem === undefined)) {
        newElem.style.display = null
      }
      const newHeight = newElem?.getBoundingClientRect().height || 0

      const hideOldElemIfExists = () => {
        if (!(oldElem === undefined)) {
          oldElem.style.position = 'absolute'
          oldElem.style.visibility = 'hidden'
          oldElem.style.display = 'none'
          oldElem.style.opacity = 0
        }
      }

      const showNewElemIfExists = () => {
        if (!(newElem === undefined)) {
          newElem.style.position = null
          newElem.style.visibility = null
        }
      }

      if (oldHeight == newHeight) {
        hideOldElemIfExists()
        showNewElemIfExists()
        return Promise.resolve()
      } else {
        hideOldElemIfExists()
        this.$refs['height-strut'].style.height = oldHeight + 'px'

        return new Promise((resolve) => {
          setTimeout(resolve, 0)
        })
          .then(() => {
            this.$refs['height-strut'].style.height = newHeight + 'px'
            return this.transitionElement(this.$refs['height-strut'])
          })
          .then(() => {
            showNewElemIfExists()
            this.$refs['height-strut'].style.height = null
          })
      }
    },
  },

  watch: {
    currentStage(new_val, old_val) {
      this.fadeOut(old_val)
        .then(() => {
          return this.transitionHeightStrutFromTo(old_val, new_val)
        })
        .then(() => {
          return this.fadeIn(new_val)
        })
        .then(() => {
          this.$emit('switched')
        })
    },
  },
}
</script>

<style scoped>
.multiswitcher-height-strut {
  transition: height 0.2s;
  visibility: hidden;
}

.multiswitcher-main-container {
  position: relative;
  width: 100%;
}

/*
add negligible padding to prevent collapsing margins and allow correct height
of element to be determined from the boundingClientRect
*/
.multiswitcher-content-wrapper {
  width: 100%;
  transition: opacity 0.3333s;
  padding: 0.05px;
  top: 0px;
  opacity: 1;
}
</style>
