import Vue from 'vue'
import Vuex from 'vuex'

import * as levels from '@/store/modules/levels'
import * as instructions from '@/store/modules/instructions'
import * as information from '@/store/modules/information'
import * as purchases from '@/store/modules/purchases'

import { Storage } from '@capacitor/storage'
import { isBool, isJson } from '@/lib/helpers.js'
import { config } from '@/lib/dynamicConfig.js'
import consoleLog from '@/lib/consoleLog.js'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    levels: levels,
    instructions: instructions,
    information: information,
    purchases: purchases,
  },
  state: {
    canReset: false,
    canUndo: false,
    isUndoUnlockedForLevel: false,
    isUndoUnlockedForSession: false,
    animating: false,
    showSettingsPopup: false,
    showUpgradeDialog: undefined,
    showRatingsDialog: undefined,
    showClearProgressDialog: undefined,
    hasAttemptedRating: false,
    showBackdoorDialog: false,
    colourblindModeEnabled: false,
    musicEnabled: true,
    soundEffectsEnabled: true,
    showBlackScreen: false,
    throbResetButton: false,
    printMe: 'print me',
  },
  mutations: {
    SET_CAN_RESET(state, val) {
      state.canReset = val
    },
    SET_CAN_UNDO(state, val) {
      state.canUndo = val
    },
    SET_IS_UNDO_UNLOCKED_FOR_LEVEL(state, val) {
      state.isUndoUnlockedForLevel = val
    },
    SET_IS_UNDO_UNLOCKED_FOR_SESSION(state, val) {
      state.isUndoUnlockedForSession = val
    },
    SET_ANIMATING(state, val) {
      state.animating = val
    },
    SET_SHOW_SETTINGS_POPUP(state, val) {
      state.showSettingsPopup = val
    },
    SET_COLOUR_BLIND_MODE_ENABLED(state, val) {
      state.colourblindModeEnabled = val
      Storage.set({ key: 'colourblindModeEnabled', value: JSON.stringify(val) })
    },
    SET_MUSIC_ENABLED(state, val) {
      state.musicEnabled = val
      Storage.set({ key: 'musicEnabled', value: JSON.stringify(val) })
    },
    SET_SOUND_EFFECTS_ENABLED(state, val) {
      state.soundEffectsEnabled = val
      Storage.set({ key: 'soundEffectsEnabled', value: JSON.stringify(val) })
    },
    SET_SHOW_BLACK_SCREEN(state, val) {
      state.showBlackScreen = val
    },
    SET_SHOW_UPGRADE_DIALOG(state, val) {
      state.showUpgradeDialog = val
    },
    SET_SHOW_BACKDOOR_DIALOG(state, val) {
      state.showBackdoorDialog = val
    },
    SET_THROB_RESET_BUTTON(state, val) {
      state.throbResetButton = val
    },
    SET_PRINT_ME(state, val) {
      state.printMe = val
    },
    SET_SHOW_RATINGS_DIALOG(state, val) {
      state.showRatingsDialog = val
    },
    SET_HAS_ATTEMPTED_RATING(state, val) {
      state.hasAttemptedRating = val
      Storage.set({ key: 'hasAttemptedRating', value: JSON.stringify(val) })
    },
    SET_SHOW_CLEAR_PROGRESS_DIALOG(state, val) {
      state.showClearProgressDialog = val
    },
  },
  actions: {
    async initialise({ commit, dispatch }) {
      {
        const { value } = await Storage.get({
          key: 'colourblindModeEnabled',
        })
        consoleLog('colourblindModeEnabled:', value)
        const colourblindModeEnabled = isJson(value) ? JSON.parse(value) : null
        commit(
          'SET_COLOUR_BLIND_MODE_ENABLED',
          isBool(colourblindModeEnabled) ? colourblindModeEnabled : false
        )
      }

      {
        const { value } = await Storage.get({ key: 'musicEnabled' })
        const musicEnabled = isJson(value) ? JSON.parse(value) : null
        commit('SET_MUSIC_ENABLED', isBool(musicEnabled) ? musicEnabled : true)
      }

      {
        const { value } = await Storage.get({
          key: 'soundEffectsEnabled',
        })
        const soundEffectsEnabled = isJson(value) ? JSON.parse(value) : null
        commit(
          'SET_SOUND_EFFECTS_ENABLED',
          isBool(soundEffectsEnabled) ? soundEffectsEnabled : true
        )
      }

      {
        const { value } = await Storage.get({
          key: 'hasAttemptedRating',
        })
        const hasAttemptedRating = isJson(value) ? JSON.parse(value) : null
        commit(
          'SET_HAS_ATTEMPTED_RATING',
          isBool(hasAttemptedRating) ? hasAttemptedRating : false
        )
      }

      await dispatch('initialise_levels')
      await dispatch('initialise_instructions')
      await dispatch('initialise_purchases')
    },
    unlock_undo_for_level({ commit, state }) {
      commit('SET_IS_UNDO_UNLOCKED_FOR_LEVEL', true)
      if (!state.isUndoUnlockedForSession) {
        commit('SET_IS_UNDO_UNLOCKED_FOR_SESSION', true)
      }
    },
  },
  getters: {
    canReset: (state) => state.canReset,
    canUndo: (state) => state.canUndo,
    isUndoUnlockedForLevel: (state) => state.isUndoUnlockedForLevel,
    isUndoUnlockedForSession: (state) => state.isUndoUnlockedForSession,
    isUndoUnlocked: (state, getters) => {
      const unlockUndosWithRewarded =
        config?.LEVEL_DEF?.unlockUndosWithRewarded || 'never'
      if (unlockUndosWithRewarded == 'all') {
        return getters.isUndoUnlockedForLevel
      } else if (unlockUndosWithRewarded == 'first') {
        return getters.isUndoUnlockedForSession
      } else {
        return true
      }
    },
    animating: (state) => state.animating,
    showSettingsPopup: (state) => state.showSettingsPopup,
    colourblindModeEnabled: (state) => state.colourblindModeEnabled,
    musicEnabled: (state) => state.musicEnabled,
    soundEffectsEnabled: (state) => state.soundEffectsEnabled,
    showBlackScreen: (state) => state.showBlackScreen,
    showUpgradeDialog: (state) => state.showUpgradeDialog,
    showBackdoorDialog: (state) => state.showBackdoorDialog,
    throbResetButton: (state) => state.throbResetButton,
    showRatingsDialog: (state) => state.showRatingsDialog,
    hasAttemptedRating: (state) => state.hasAttemptedRating,
    showClearProgressDialog: (state) => state.showClearProgressDialog,
  },
})
