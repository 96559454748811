<template>
  <div class="top-section">
    <div
      class="transition-opacity"
      style="position: absolute; left: 4rem; top: 1rem"
      :style="{
        display: devMode || config.SCREENSHOT_MODE ? 'flex' : 'none',
        opacity: currentLevel == -1 || config.SCREENSHOT_MODE ? 0 : 1,
      }"
    >
      <div class="icon-wrapper" v-ripple @click="$emit('prev_level')">
        <svg class="icon throb" viewBox="0 0 24 24">
          <path :d="mdiChevronLeft" />
        </svg>
      </div>
      <div class="icon-wrapper" v-ripple @click="$emit('next_level')">
        <svg class="icon throb" viewBox="0 0 24 24">
          <path :d="mdiChevronRight" />
        </svg>
      </div>
    </div>
    <div
      class="settings-button icon-wrapper"
      :class="{ ios: isiOS }"
      v-ripple
      @click="settingsClick"
    >
      <svg class="icon" viewBox="0 0 24 24">
        <path :d="mdiCog" />
      </svg>
    </div>
    <div
      class="home-button icon-wrapper transition-opacity"
      :class="{ ios: isiOS }"
      v-ripple
      @click="homeClick"
      :style="{ opacity: currentLevel == -1 ? 0 : 1 }"
    >
      <svg class="icon" viewBox="0 0 24 24">
        <path :d="mdiHome" />
      </svg>
    </div>
    <div
      class="top-row transition-opacity"
      :class="{ ios: isiOS }"
      :style="{ opacity: currentLevel == -1 ? 0 : 1 }"
    >
      <span
        class="text transition-opacity"
        :style="{ opacity: currentLevel == levelCount ? 0 : 1 }"
      >
        {{ Math.min(currentLevelDisplayed + 1, levelCount) }}&nbsp;/&nbsp;{{
          levelCount
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { mdiChevronLeft, mdiChevronRight, mdiCog, mdiHome } from '@mdi/js'
import Vue from 'vue'
import { logger } from '@/lib/firebase.js'
import { isiOS } from '@/lib/helpers.js'
import { config } from '@/lib/dynamicConfig.js'

export default {
  name: 'TopSection',
  data() {
    return {
      mdiChevronLeft,
      mdiChevronRight,
      mdiCog,
      mdiHome,
      devMode: Vue.config.devtools,
      currentLevelDisplayed: undefined,
      isiOS,
      config,
    }
  },
  mounted() {
    this.currentLevelDisplayed = this.currentLevel
  },
  computed: {
    currentLevel() {
      return this.$store.getters.currentLevel
    },
    levelCount() {
      return this.$store.getters.levels.length
    },
    /*moveCount() {
      return this.$store.getters.moveCount
    },
    targetCount() {
      return this.$store.getters.targetCount
    },
    bestCount() {
      return this.$store.getters.bestCount
    },*/
  },
  methods: {
    settingsClick() {
      this.$store.commit('SET_SHOW_SETTINGS_POPUP', true)
      logger.buttonClick('settings')
    },
    homeClick() {
      this.$emit('home')
      logger.buttonClick('home')
    },
  },
  watch: {
    currentLevel(new_val) {
      if (new_val > -1) {
        this.currentLevelDisplayed = new_val
      }
    },
  },
}
</script>

<style scoped>
.settings-button {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.home-button {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

.settings-button.ios {
  margin-top: 2rem;
}

.home-button.ios {
  margin-top: 2rem;
}

.top-row.ios {
  margin-top: 2rem;
}

.transition-opacity {
  transition: opacity 0.5s;
}

.top-section {
  /*width: 100%;
  max-width: 30rem;*/
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem 2rem 1rem;
  transition: opacity 0.5s;
}

.top-section > .top-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0.5rem;
  align-items: center;
}

.top-section > .top-row > .text {
  font-size: 1.25rem;
  font-weight: 300;
}

.top-section > .top-row h1 {
  margin: 0;
}

.top-section > .bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  visibility: hidden;
}

.top-section > .bottom-row > .text-box {
  width: 6rem;
  text-align: center;
}

.top-section > .bottom-row > * + * {
  margin-left: 2rem;
}

.top-section > .bottom-row .text {
  font-size: 1rem;
}

.top-section > .bottom-row .number {
  font-size: 1rem;
  font-weight: 300;
  color: var(--yellow-colour);
}

.icon-wrapper {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 2.5rem;
  cursor: pointer;
}

.icon path {
  fill: var(--main-item-colour);
}

.icon.throb path {
  animation-name: icon-throb-animation;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: center;
}

@keyframes icon-throb-animation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
</style>
