import { providerStore } from '@/lib/purchases.js'
import { config } from '@/lib/dynamicConfig.js'
import { logger } from '@/lib/firebase.js'
import store from '@/store'
import consoleLog from '@/lib/consoleLog.js'

export default {
  data() {
    return {
      timeout: undefined,
    }
  },
  beforeDestroy() {
    consoleLog(`${this.$options.name} ${this._uid}: beforeDestroy`)
    this.clearAllListeners()
    clearTimeout(this.timeout)
  },
  computed: {
    productsKnown() {
      return this.$store.getters.productsKnown
    },
  },
  methods: {
    purchase(itemId) {
      if (!this.productsKnown.includes(itemId)) {
        return
      }

      this.stage = 'purchasing'
      this.isPurchaseInProgress = true

      if (providerStore === undefined) {
        consoleLog(`${this.$options.name} ${this._uid}: store not available`)
        this.stage = 'error'
        return
      }

      consoleLog(`${this.$options.name} ${this._uid}: purchase: ${itemId}`)

      providerStore
        .order(itemId)
        .then((val) => {
          consoleLog(
            `${this.$options.name} ${this._uid}: providerStore: ${itemId} order initiated, ${val}`
          )
          this.stage = 'purchasing'

          this.timeout = setTimeout(() => {
            this.clearAllListeners()
            this.stage = 'error'
            consoleLog(
              `${this.$options.name} ${this._uid}: providerStore: TIMEOUT`
            )
            logger.purchaseTimeout({ item: itemId })
          }, config.PURCHASE_TIMEOUT)

          providerStore.error(this.errorHandler)
          providerStore.when(itemId).owned(this.ownedHandler)
          providerStore.when(itemId).cancelled(this.cancelledHandler)
        })
        .error((err) => {
          consoleLog(
            `${this.$options.name} ${this._uid}: providerStore: ${itemId} error initiating order, ${err}`
          )
          this.stage = 'error'
        })
    },
    errorHandler(error) {
      consoleLog(
        `${this.$options.name} ${this._uid}: providerStore ERROR ${error.code}: ${error.message}`
      )

      consoleLog(`${this.$options.name} ${this._uid}: stage is ${this.stage}`)
      this.stage = 'error'
      consoleLog(
        `${this.$options.name} ${this._uid}: changed stage to ${this.stage}`
      )

      providerStore.off(this.errorHandler)
      clearTimeout(this.timeout)
      logger.purchaseError({
        error_code: error.code,
        error_message: error.message,
      })
    },
    ownedHandler(val) {
      consoleLog(
        `${this.$options.name} ${this._uid}: providerStore: product owned ${val.id}`
      )
      this.stage = 'thanks'
      this.itemPurchased = val.id
      providerStore.off(this.ownedHandler)
      clearTimeout(this.timeout)
      store.dispatch('add_product_owned', val.id)

      logger.purchase({
        currency: val.currency,
        value: val.priceMicros / 1000000,
        items: [{ item_id: val.id }],
      })
    },
    cancelledHandler(val) {
      consoleLog(
        `${this.$options.name} ${this._uid}: providerStore: cancelled ${val.id}`
      )
      this.stage = 'remind'
      providerStore.off(this.cancelledHandler)
      clearTimeout(this.timeout)

      logger.purchaseCancelled({
        currency: val.currency,
        value: val.priceMicros / 1000000,
        item: val.id,
      })
    },
    clearAllListeners() {
      consoleLog(
        `${this.$options.name} ${
          this._uid
        }: clearing listeners, providerStore exists = ${!(
          providerStore === undefined
        )}`
      )
      providerStore?.off(this.cancelledHandler)
      providerStore?.off(this.ownedHandler)
      providerStore?.off(this.errorHandler)
    },
  },
}
