var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fullscreen-container"},[_c('TopSection',{on:{"next_level":_vm.nextLevel,"prev_level":_vm.prevLevel,"home":_vm.homeScreen}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.showBlackScreen)?_c('div',{staticClass:"black-screen"}):_vm._e()]),_c('div',{staticClass:"game-container-outer-wrapper",style:(_vm.slideVarStyle)},[_c('transition',{attrs:{"name":"slide"}},[_c('div',{key:(_vm.currentLevel + "_" + _vm.showInterstitialAd),staticClass:"game-container-inner-wrapper"},[(_vm.currentLevel == -1 && !_vm.showInterstitialAd)?_c('WelcomeScreen',{on:{"start":_vm.nextLevel,"continue_from_max":_vm.continueFromMaxLevel}}):_vm._e(),(
            _vm.currentLevel >= 0 &&
            _vm.currentLevel < _vm.levelCount &&
            !_vm.showInterstitialAd
          )?_c('GameContainer',{attrs:{"level":_vm.currentLevel,"resetGame":_vm.resetGame,"undoMove":_vm.undoMove},on:{"level_complete":_vm.levelComplete,"move_undone":_vm.moveUndone}}):_vm._e(),(_vm.currentLevel == _vm.levelCount && !_vm.showInterstitialAd)?_c('EndOfLevelsScreen'):_vm._e(),(_vm.showInterstitialAd)?_c('InterstitialAd',{on:{"done":_vm.showInterstitialAdDone}}):_vm._e()],1)])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.currentLevel == -1)?_c('CompanyText'):_vm._e()],1),_c('BottomSection',{style:({
      opacity:
        _vm.currentLevel == -1 || _vm.currentLevel == _vm.levelCount || _vm.showBlackScreen
          ? 0
          : 1,
    }),attrs:{"lives":_vm.lives,"undosUsed":_vm.undoCount},on:{"reset":_vm.reset,"undo":_vm.undo}}),(_vm.showLevelComplete)?_c('LevelComplete',{on:{"next_level":_vm.nextLevel,"close":function($event){_vm.showLevelComplete = false}}}):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.showRewardedAd)?_c('RewardedAd',{on:{"done":_vm.showRewardedAdDone}}):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(_vm.showInstructionPopup)?_c('InstructionPopup'):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(_vm.showSettingsPopup)?_c('SettingsDialog'):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(_vm.$store.getters.showUpgradeDialog == 'welcome_upgrade')?_c('UpgradeDialogWelcome',{on:{"close":function($event){return _vm.$store.commit('SET_SHOW_UPGRADE_DIALOG', undefined)}}}):_vm._e(),(_vm.$store.getters.showUpgradeDialog == 'interstitial')?_c('UpgradeDialogInterstitial',{on:{"close":function($event){return _vm.$store.commit('SET_SHOW_UPGRADE_DIALOG', undefined)}}}):_vm._e(),(_vm.$store.getters.showUpgradeDialog == 'undo_reward')?_c('UpgradeDialogUndoReward',{on:{"close":function($event){return _vm.$store.commit('SET_SHOW_UPGRADE_DIALOG', undefined)},"watch_video":function($event){_vm.showRewardedAd = true}}}):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(_vm.showBackdoorDialog)?_c('BackdoorDialog'):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(!(_vm.showRatingsDialog === undefined))?_c('RatingsDialog',{attrs:{"variant":_vm.showRatingsDialog}}):_vm._e()],1),_c('transition',{attrs:{"name":"popup"}},[(!(_vm.showClearProgressDialog === undefined))?_c('ClearProgressDialog'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }