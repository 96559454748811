import { Storage } from '@capacitor/storage'
import { isBool, isJson, isString } from '@/lib/helpers.js'
import { isStuck } from '@/lib/levelStuckCheckers.js'
import consoleLog from '@/lib/consoleLog.js'

export const state = {
  showInstructionStage: undefined,
  stagesSeen: [],
  userRefusedHelp: false,
  hasReset: false,
  hasUndone: false,
}
export const mutations = {
  SET_SHOW_INSTRUCTION_STAGE(state, val) {
    state.showInstructionStage = val
  },
  SET_STAGES_SEEN(state, val) {
    state.stagesSeen = val
    Storage.set({ key: 'instructionStagesSeen', value: JSON.stringify(val) })
  },
  SET_USER_REFUSED_HELP(state, val) {
    state.userRefusedHelp = val
    Storage.set({ key: 'userRefusedHelp', value: JSON.stringify(val) })
  },
  SET_HAS_RESET(state, val) {
    state.hasReset = val
    Storage.set({ key: 'hasReset', value: JSON.stringify(val) })
  },
  SET_HAS_UNDONE(state, val) {
    state.hasUndone = val
    Storage.set({ key: 'hasUndone', value: JSON.stringify(val) })
  },
}
export const actions = {
  async initialise_instructions({ commit }) {
    {
      const { value } = await Storage.get({
        key: 'instructionStagesSeen',
      })
      const stagesSeen = isJson(value) ? JSON.parse(value) : null
      commit(
        'SET_STAGES_SEEN',
        Array.isArray(stagesSeen) && stagesSeen.every((x) => isString(x))
          ? stagesSeen
          : []
      )
    }

    {
      const { value } = await Storage.get({
        key: 'userRefusedHelp',
      })
      const userRefusedHelp = isJson(value) ? JSON.parse(value) : null
      commit(
        'SET_USER_REFUSED_HELP',
        isBool(userRefusedHelp) ? userRefusedHelp : false
      )
    }

    {
      const { value } = await Storage.get({
        key: 'hasReset',
      })
      const hasReset = isJson(value) ? JSON.parse(value) : null
      commit('SET_HAS_RESET', isBool(hasReset) ? hasReset : false)
    }

    {
      const { value } = await Storage.get({
        key: 'hasUndone',
      })
      const hasUndone = isJson(value) ? JSON.parse(value) : null
      commit('SET_HAS_UNDONE', isBool(hasUndone) ? hasUndone : false)
    }
  },
  reset_instruction_status({ commit }) {
    commit('SET_STAGES_SEEN', [])
    commit('SET_USER_REFUSED_HELP', false)
    commit('SET_HAS_RESET', false)
    commit('SET_HAS_UNDONE', false)
  },
  mark_stage_seen({ commit }, stage) {
    if (!state.stagesSeen.includes(stage)) {
      commit('SET_STAGES_SEEN', [...state.stagesSeen, stage])
    }
  },
  show_instruction_if_needed({ commit, state, dispatch, getters }, vals) {
    consoleLog('dispatch: show_instruction_if_needed:', vals)
    consoleLog(
      `isStuck ${getters.currentLevelDef.key}: `,
      isStuck(vals.ballGrid, getters.currentLevelDef.key)
    )

    const currentLevelDef = getters.levels[vals.currentLevel]

    if (state.userRefusedHelp) {
      return
    }

    const showStage = (stage) => {
      commit('SET_SHOW_INSTRUCTION_STAGE', stage)
      dispatch('mark_stage_seen', stage)
    }

    if (
      vals.currentLevel == 0 &&
      vals.moveCount == 0 &&
      !getters.hasStageBeenSeen('draw_dots')
    ) {
      setTimeout(() => {
        showStage('draw_dots')
      }, 1500)
    } else if (
      vals.currentLevel == 0 &&
      vals.moveCount == 1 &&
      !getters.hasStageBeenSeen('green_into_target')
    ) {
      setTimeout(() => {
        showStage('green_into_target')
      }, 500)
    } else if (
      vals.currentLevel == 1 &&
      vals.moveCount == 0 &&
      !getters.hasStageBeenSeen('rectangles_as_well_1')
    ) {
      setTimeout(() => {
        showStage('rectangles_as_well_1')
      }, 1500)
    } else if (
      vals.currentLevel == 1 &&
      isStuck(vals.ballGrid, vals.currentLevelKey)
    ) {
      setTimeout(() => {
        showStage('rectangles_as_well_got_stuck')
        dispatch('mark_stage_seen', 'got_stuck')
      }, 500)
      commit('SET_THROB_RESET_BUTTON', true)
    } else if (
      vals.currentLevel == 2 &&
      vals.moveCount == 0 &&
      !getters.hasStageBeenSeen('rectangles_as_well_2')
    ) {
      setTimeout(() => {
        showStage('rectangles_as_well_2')
      }, 1500)
    } else if (
      vals.currentLevel == 2 &&
      isStuck(vals.ballGrid, vals.currentLevelKey)
    ) {
      setTimeout(() => {
        showStage('rectangles_as_well_got_stuck')
        dispatch('mark_stage_seen', 'got_stuck')
      }, 500)
      commit('SET_THROB_RESET_BUTTON', true)
    } else if (
      vals.currentLevel == 3 &&
      vals.moveCount == 0 &&
      !getters.hasStageBeenSeen('big_shapes')
    ) {
      setTimeout(() => {
        showStage('big_shapes')
      }, 1500)
    } else if (
      vals.currentLevel == 3 &&
      isStuck(vals.ballGrid, vals.currentLevelKey)
    ) {
      setTimeout(() => {
        showStage('big_shapes_got_stuck')
        dispatch('mark_stage_seen', 'got_stuck')
      }, 500)
      commit('SET_THROB_RESET_BUTTON', true)
    } else if (
      vals.currentLevel == 4 &&
      vals.moveCount == 0 &&
      !getters.hasStageBeenSeen('off_you_go')
    ) {
      setTimeout(() => {
        showStage('off_you_go')
      }, 1500)
    } else if (
      vals.currentLevel == 4 &&
      isStuck(vals.ballGrid, vals.currentLevelKey)
    ) {
      setTimeout(() => {
        showStage('got_stuck')
      }, 500)
      commit('SET_THROB_RESET_BUTTON', true)
    } else if (
      currentLevelDef.lives > 0 &&
      !getters.hasStageBeenSeen('lives_tip') &&
      vals.moveCount == 0
    ) {
      setTimeout(() => {
        showStage('lives_tip')
      }, 1500)
    }
  },
  hide_instruction({ commit }) {
    commit('SET_SHOW_INSTRUCTION_STAGE', undefined)
  },
  hide_instruction_forever({ commit }) {
    commit('SET_SHOW_INSTRUCTION_STAGE', undefined)
    commit('SET_USER_REFUSED_HELP', true)
  },
}
export const getters = {
  showInstructionPopup: (state) => !(state.showInstructionStage === undefined),
  showInstructionStage: (state) => state.showInstructionStage,
  hasStageBeenSeen: (state) => (stage) => state.stagesSeen.includes(stage),
}
