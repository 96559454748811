<template>
  <Dialog :allowModalClose="true" @modal_close="close">
    <span class="content-wrapper">
      <div class="settings-row">
        <div>
          <div
            class="icon-wrapper"
            v-ripple
            @click.stop="toggleColourblindMode"
          >
            <svg class="icon" viewBox="0 0 24 24">
              <path v-if="colourblindModeEnabled" :d="mdiEye" />
              <path v-else :d="mdiEyeOff" />
            </svg>
          </div>
        </div>
        <p v-if="colourblindModeEnabled">Colourblind mode enabled</p>
        <p v-else>Colourblind mode disabled</p>
      </div>
      <div class="settings-row">
        <div>
          <div class="icon-wrapper" v-ripple @click.stop="toggleMusicEnabled">
            <svg class="icon" viewBox="0 0 24 24">
              <path v-if="musicEnabled" :d="mdiVolumeHigh" />
              <path v-else :d="mdiVolumeOff" />
            </svg>
          </div>
        </div>
        <p v-if="musicEnabled">Music on</p>
        <p v-else>Music off</p>
      </div>
      <div class="settings-row">
        <div>
          <div
            class="icon-wrapper"
            v-ripple
            @click.stop="toggleSoundEffectsEnabled"
          >
            <svg class="icon" viewBox="0 0 24 24">
              <path v-if="soundEffectsEnabled" :d="mdiVolumeHigh" />
              <path v-else :d="mdiVolumeOff" />
            </svg>
          </div>
        </div>
        <p v-if="soundEffectsEnabled">Sound effects on</p>
        <p v-else>Sound effects off</p>
      </div>
    </span>
    <div
      v-if="MONETISATION_STRATEGY == 'level_def'"
      class="upgrade-content-container"
    >
      <UpgradeFromSettingsContent />
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import UpgradeFromSettingsContent from '@/components/UpgradeFromSettingsContent.vue'

import { config } from '@/lib/dynamicConfig.js'
import { mdiEye, mdiEyeOff, mdiVolumeHigh, mdiVolumeOff } from '@mdi/js'
import { logger } from '@/lib/firebase.js'

export default {
  name: 'SettingsPopup',
  components: {
    UpgradeFromSettingsContent,
    Dialog,
  },
  data() {
    return {
      name: 'settings',
      mdiEye,
      mdiEyeOff,
      mdiVolumeHigh,
      mdiVolumeOff,
      MONETISATION_STRATEGY: config.MONETISATION_STRATEGY,
    }
  },
  mounted() {
    logger.showPopup('settings')
  },
  computed: {
    colourblindModeEnabled() {
      return this.$store.getters.colourblindModeEnabled
    },
    musicEnabled() {
      return this.$store.getters.musicEnabled
    },
    soundEffectsEnabled() {
      return this.$store.getters.soundEffectsEnabled
    },
  },
  methods: {
    close() {
      logger.closePopup('settings')
      this.$store.commit('SET_SHOW_SETTINGS_POPUP', false)
    },
    toggleColourblindMode() {
      this.$store.commit(
        'SET_COLOUR_BLIND_MODE_ENABLED',
        !this.colourblindModeEnabled
      )
      logger.buttonClick('settings_colour_blind_mode', {
        set: this.colourblindModeEnabled ? 'on' : 'off',
      })
    },
    toggleMusicEnabled() {
      this.$store.commit('SET_MUSIC_ENABLED', !this.musicEnabled)
      logger.buttonClick('settings_music', {
        set: this.musicEnabled ? 'on' : 'off',
      })
    },
    toggleSoundEffectsEnabled() {
      this.$store.commit('SET_SOUND_EFFECTS_ENABLED', !this.soundEffectsEnabled)
      logger.buttonClick('settings_sound_effects', {
        set: this.soundEffectsEnabled ? 'on' : 'off',
      })
    },
  },
}
</script>

<style scoped>
.fullscreen-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-container {
  max-width: 30rem;
  width: 90%;
  background-color: var(--main-background-colour);
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.upgrade-content-container {
  border-top: 0.5px dotted var(--main-item-colour);
  padding-top: 1.5rem;
  margin-top: 1.5rem;
  /*margin-bottom: 1rem;*/
  /*padding-left: 1rem;
  padding-right: 1rem;*/
  width: 100%;
}

.content-wrapper {
  display: inline-block;
  width: 100%;
  max-width: 15rem;
}

.settings-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
}

.settings-row:first-of-type {
  margin-top: 0px;
}

.controls-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon-wrapper {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 2.5rem;
}

.icon path {
  fill: var(--main-item-colour);
}

.controls-container > * + * {
  margin-left: 1rem;
}

p {
  margin: 0px 0px 0px 1rem;
}
</style>

<style>
.upgrade-content-container p {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.upgrade-content-container p + p {
  margin-top: 1rem;
}
</style>
