import store from '@/store'
import { Capacitor } from '@capacitor/core'
import { NativeAudio } from '@capacitor-community/native-audio'
import consoleLog from '@/lib/consoleLog.js'

const useNative = ['ios', 'android'].includes(Capacitor.getPlatform())

let popWeb
if (useNative) {
  NativeAudio.preload({
    assetId: 'pop',
    assetPath: 'public/pop.mp3',
  })
    .then(() => {
      consoleLog('NativeAudio: pop.mp3 loaded')
      NativeAudio.setVolume({ assetId: 'pop', volume: 0.25 })
        .then(() => {
          consoleLog('NativeAudio: pop.mp3 volume changed')
        })
        .catch((e) => {
          consoleLog('NativeAudio: pop.mp3 volume FAILED to change:', e)
        })
    })
    .catch((e) => {
      consoleLog('NativeAudio: ERROR loading pop.mp3:', e)
    })
} else {
  popWeb = new Audio('pop.mp3')
  popWeb.volume = 0.25
  popWeb.preload = 'auto'
}

let winWeb
if (useNative) {
  NativeAudio.preload({
    assetId: 'win',
    assetPath: 'public/win.mp3',
  })
    .then(() => {
      consoleLog('NativeAudio: win.mp3 loaded')
    })
    .catch((e) => {
      consoleLog('NativeAudio: ERROR loading win.mp3:', e)
    })
} else {
  winWeb = new Audio('win.mp3')
  winWeb.preload = 'auto'
}

let targetWeb
if (useNative) {
  NativeAudio.preload({
    assetId: 'target',
    assetPath: 'public/target.mp3',
  })
    .then(() => {
      consoleLog('NativeAudio: target.mp3 loaded')
      NativeAudio.setVolume({ assetId: 'target', volume: 0.25 })
        .then(() => {
          consoleLog('NativeAudio: target.mp3 volume changed')
        })
        .catch((e) => {
          consoleLog('NativeAudio: target.mp3 volume FAILED to change:', e)
        })
    })
    .catch((e) => {
      consoleLog('NativeAudio: ERROR loading target.mp3:', e)
    })
} else {
  targetWeb = new Audio('target.mp3')
  targetWeb.volume = 0.25
  targetWeb.preload = 'auto'
}

let musicWeb
if (useNative) {
  NativeAudio.preload({
    assetId: 'music',
    assetPath: 'public/music.mp3',
  })
    .then(() => {
      consoleLog('NativeAudio: music.mp3 loaded')
      NativeAudio.setVolume({ assetId: 'music', volume: 0.0 })
        .then(() => {
          consoleLog('NativeAudio: music.mp3 volume changed')
        })
        .catch((e) => {
          consoleLog('NativeAudio: music.mp3 volume FAILED to change:', e)
        })
    })
    .catch((e) => {
      consoleLog('NativeAudio: ERROR loading music.mp3:', e)
    })
} else {
  musicWeb = new Audio('music.mp3')
  musicWeb.loop = true
  musicWeb.volume = 0.0
  musicWeb.preload = 'auto'
}
let musicIsPlaying = false

export function playPop() {
  if (!store.getters.soundEffectsEnabled) {
    return
  }

  if (useNative) {
    NativeAudio.play({
      assetId: 'pop',
    })
  } else {
    popWeb.play()
  }
}

export function playWin() {
  if (!store.getters.soundEffectsEnabled) {
    return
  }

  if (useNative) {
    NativeAudio.play({
      assetId: 'win',
    })
  } else {
    winWeb.play()
  }
}

export function playTarget() {
  if (!store.getters.soundEffectsEnabled) {
    return
  }

  if (useNative) {
    NativeAudio.play({
      assetId: 'target',
    })
  } else {
    targetWeb.play()
  }
}

export function startMusic() {
  consoleLog('startMusic()')
  if (musicIsPlaying) {
    return
  }

  if (useNative) {
    let vol = 0.0
    NativeAudio.setVolume({ assetId: 'music', volume: vol })
    NativeAudio.resume({
      assetId: 'music',
    })
    NativeAudio.loop({
      assetId: 'music',
    })

    const v = setInterval(() => {
      vol = Math.min(vol + 0.05, 0.5)
      NativeAudio.setVolume({ assetId: 'music', volume: vol })
      if (vol == 0.5) {
        clearInterval(v)
      }
    }, 75)
  } else {
    musicWeb.volume = 0.0
    musicWeb.play()
    const v = setInterval(() => {
      musicWeb.volume = Math.min(musicWeb.volume + 0.05, 0.5)
      if (musicWeb.volume == 0.5) {
        clearInterval(v)
      }
    }, 75)
  }

  musicIsPlaying = true
}

export function stopMusic() {
  consoleLog('stopMusic()')
  if (useNative) {
    let vol = 0.5

    const v = setInterval(() => {
      vol = Math.max(vol - 0.05, 0.0)
      NativeAudio.setVolume({ assetId: 'music', volume: vol })
      if (vol == 0.0) {
        clearInterval(v)
        NativeAudio.pause({
          assetId: 'music',
        })
      }
    }, 75)
  } else {
    const v = setInterval(() => {
      musicWeb.volume = Math.max(musicWeb.volume - 0.05, 0.0)
      if (musicWeb.volume == 0.0) {
        clearInterval(v)
        musicWeb.pause()
      }
    }, 75)
  }

  musicIsPlaying = false
}
