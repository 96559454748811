<template>
  <div class="dialog-fullscreen-container" @click="modalClose($event)">
    <div class="dialog-container" @click.stop>
      <div
        v-if="allowModalClose"
        class="dialog-close-icon icon-wrapper"
        v-ripple
        @click="modalClose($event)"
      >
        <svg class="icon" viewBox="0 0 24 24">
          <path :d="mdiClose" />
        </svg>
      </div>
      <div class="dialog-inner-container" :class="{ 'can-scroll': canScroll }">
        <div class="dialog-inner-flex-container text-small">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  name: 'Dialog',
  props: {
    allowModalClose: { type: Boolean, default: false },
    canScroll: { type: Boolean, default: true },
  },
  data() {
    return {
      mdiClose,
    }
  },
  methods: {
    modalClose(event) {
      if (this.allowModalClose) {
        this.$emit('modal_close', event)
      }
    },
  },
}
</script>

<style scoped>
.dialog-fullscreen-container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 101;
}

.dialog-container {
  max-width: 30rem;
  max-height: 90%;
  width: 90%;
  background-color: var(--main-background-colour);
  padding: 3rem 0.5rem 3rem 0.5rem;
  border-radius: 10px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.3) 5px 5px 12px;
}

.dialog-inner-container {
  width: 100%;
  height: 100%;
  padding: 0rem 1.5rem 0rem 1.5rem;
}

.dialog-inner-container.can-scroll {
  overflow-y: scroll;
}

.dialog-inner-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dialog-close-icon {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
}

.icon-wrapper {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.icon {
  width: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon path {
  fill: var(--main-item-colour);
}
</style>
