/*
Points are expected to be in the format:
[{id, gridX, gridY}]
*/

function canPointsBeCombined(points) {
  if (points.length == 1) {
    return true
  }

  // If last two points are not neighbours, don't add
  const lastPoint = points[points.length - 2]
  const newPoint = points[points.length - 1]
  if (lastPoint.gridX != newPoint.gridX && lastPoint.gridY != newPoint.gridY) {
    return false
  }

  let i
  let horizontalLines = []
  let verticalLines = []
  for (i = 1; i < points.length; i++) {
    const line = {
      p1: { gridX: points[i - 1].gridX, gridY: points[i - 1].gridY },
      p2: { gridX: points[i].gridX, gridY: points[i].gridY },
    }
    if (isVerticalLine(line)) {
      verticalLines.push(line)
    } else {
      horizontalLines.push(line)
    }
  }

  const horizontalXindices = horizontalLines.flatMap((l) => [
    l.p1.gridX,
    l.p2.gridX,
  ])
  const horizontalYindices = horizontalLines.flatMap((l) => [
    l.p1.gridY,
    l.p2.gridY,
  ])
  const verticalXindices = verticalLines.flatMap((l) => [
    l.p1.gridX,
    l.p2.gridX,
  ])
  const verticalYindices = verticalLines.flatMap((l) => [
    l.p1.gridY,
    l.p2.gridY,
  ])

  if (horizontalLines.length > 0 && verticalLines.length > 0) {
    const verticalMinY = Math.min(...verticalYindices)
    const verticalMaxY = Math.max(...verticalYindices)
    const horizontalMinX = Math.min(...horizontalXindices)
    const horizontalMaxX = Math.max(...horizontalXindices)
    if (
      verticalXindices.some((i) => i > horizontalMinX && i < horizontalMaxX)
    ) {
      return false
    }
    if (horizontalYindices.some((i) => i > verticalMinY && i < verticalMaxY)) {
      return false
    }
  }

  return true
}

function findCornerPoints(points) {
  //debugger
  let i
  let lines = []
  for (i = 1; i < points.length; i++) {
    lines.push({
      p1: {
        gridX: points[i - 1].gridX,
        gridY: points[i - 1].gridY,
        id: points[i - 1].id,
      },
      p2: { gridX: points[i].gridX, gridY: points[i].gridY, id: points[i].id },
    })
  }

  let cornerPoints = []
  for (i = 1; i < lines.length; i++) {
    if (isVerticalLine(lines[i - 1]) != isVerticalLine(lines[i])) {
      cornerPoints.push(lines[i].p1)
    }
  }
  if (isVerticalLine(lines[lines.length - 1]) != isVerticalLine(lines[0])) {
    cornerPoints.push(lines[0].p1)
  }
  return cornerPoints
}

function findAllPointsOnSquare(points, allPoints) {
  const cornerPoints = findCornerPoints(points)
  if (cornerPoints.length != 4) {
    return
  }

  const fullPointsDuped = [
    ...findAllPointsBetween(cornerPoints[0], cornerPoints[1], allPoints),
    ...findAllPointsBetween(cornerPoints[1], cornerPoints[2], allPoints),
    ...findAllPointsBetween(cornerPoints[2], cornerPoints[3], allPoints),
    ...findAllPointsBetween(cornerPoints[3], cornerPoints[0], allPoints),
  ]
  const fullPointsDeduped = new Set(fullPointsDuped)
  return [...fullPointsDeduped.values()]
}

function isBetween(val, bounds, strict = false) {
  if (bounds[0] > bounds[1]) {
    return strict
      ? val < bounds[0] && val > bounds[1]
      : val <= bounds[0] && val >= bounds[1]
  } else {
    return strict
      ? val > bounds[0] && val < bounds[1]
      : val >= bounds[0] && val <= bounds[1]
  }
}

function isVerticalLine(line) {
  return line.p1.gridX == line.p2.gridX && line.p1.gridY != line.p2.gridY
}

function isHorizontalLine(line) {
  return line.p1.gridY == line.p2.gridY && line.p1.gridX != line.p2.gridX
}

function doPointsMakeASquare(points) {
  if (
    points.length >= 4 &&
    findCornerPoints(points).length == 4 &&
    canPointsBeCombined(points) &&
    points[0].id == points[points.length - 1].id
  ) {
    return true
  } else {
    false
  }
}

function findAllPointsBetween(startPoint, endPoint, allPoints, strict = false) {
  //debugger
  let points = [
    ...allPoints.filter(
      (p) =>
        isBetween(p.gridX, [startPoint.gridX, endPoint.gridX], strict) &&
        isBetween(p.gridY, [startPoint.gridY, endPoint.gridY], strict)
    ),
  ]

  const sortSignX = Math.sign(startPoint.gridX - endPoint.gridX)
  const sortSignY = Math.sign(startPoint.gridY - endPoint.gridY)
  points.sort((a, b) => (b.gridX - a.gridX) * sortSignX)
  points.sort((a, b) => (b.gridY - a.gridY) * sortSignY)

  return points
}

export {
  canPointsBeCombined,
  doPointsMakeASquare,
  isVerticalLine,
  isHorizontalLine,
  findCornerPoints,
  findAllPointsOnSquare,
  findAllPointsBetween,
}
