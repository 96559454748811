<template>
  <div class="end-of-levels-container">
    <p class="title">Congratulations!</p>
    <p>You are now a Bikit master.</p>
    <p class="emoji">&#x1F3C6;</p>
    <p>We hope you enjoyed your journey here.</p>
    <p>It's been a pleasure!</p>
  </div>
</template>

<script>
import { logger } from '@/lib/firebase.js'
import consoleLog from '@/lib/consoleLog.js'

export default {
  name: 'EndOfLevelsScreen',
  mounted() {
    logger.logEvent('end_of_game')

    if (!this.$store.getters.hasAttemptedRating) {
      setTimeout(() => {
        if (!(window?.cordova?.plugins?.AppReview === undefined)) {
          window.cordova.plugins.AppReview.requestReview()
            .catch(() => {
              consoleLog('FAILED to open review screen')
              return window.cordova.plugins.AppReview.openStoreScreen()
            })
            .then(() => {
              consoleLog('SUCCESS opening review screen')
            })
        }
        this.$store.commit('SET_HAS_ATTEMPTED_RATING', true)
        consoleLog('RATING TRIGGERED')
      }, 3000)
    }
  },
}
</script>

<style scoped>
.end-of-levels-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 0rem 2rem 0rem 2rem;
}

p {
  text-align: center;
}

.title {
  font-size: 2rem;
}

.emoji {
  margin-top: 1rem;
  font-size: 3rem;
  margin-bottom: 1rem;
}
</style>
