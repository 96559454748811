const checkers = {
  '3x3_t2_1': (ballGrid) => {
    return ballGridMatches(ballGrid, [
      [0, 2, 0],
      [1, 1, 1],
      [1, 1, 1],
    ])
  },
  '3x3_t2_2': (ballGrid) => {
    return ballGrid[2][2] == 0
  },
  '3x4_t2_1': (ballGrid) => {
    return (
      ballGridMatches(ballGrid, [
        [0, 2, 0],
        [1, 1, 1],
        [-1, -1, -1],
        [-1, -1, -1],
      ]) || ballGrid[3][2] == 0
    )
  },
  '3x4_t2_2': (ballGrid) => {
    return (
      (ballGrid[0][0] == 0 && ballGrid[0][1] == 2 && ballGrid[0][2] == 0) ||
      ballGrid[4][2] == 0
    )
  },
  '2x3_t2_1': (ballGrid) => {
    return ballGrid[2][0] == 0
  },
  '2x3_t2_2': (ballGrid) => {
    return ballGrid[2][1] == 0
  },
  '3x3_t2_6': (ballGrid) => {
    return ballGrid[2][0] == 0
  },
}

function isStuck(ballGrid, levelKey) {
  return levelKey in checkers ? checkers[levelKey](ballGrid) : false
}

function ballGridMatches(ballGrid, template) {
  if (
    template.length == ballGrid.length &&
    template[0].length == ballGrid[0].length
  ) {
    return template.every((row, yIndex) => {
      return row.every(
        (val, xIndex) => ballGrid[yIndex][xIndex] == val || val == -1
      )
    })
  } else {
    return false
  }
}

export { isStuck }
