<template>
  <Dialog>
    <div class="ratings-content" v-html="settings"></div>
    <Button @click="rateYes">Ok</Button>
    <Button @click="rateNo">Not now</Button>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import Button from '@/components/Button.vue'
import consoleLog from '@/lib/consoleLog.js'
import popupAnalyticsMixin from '@/lib/popupAnalyticsMixin.js'
import { config } from '@/lib/dynamicConfig.js'
import { isAndroid } from '@/lib/helpers.js'

export default {
  name: 'RatingsDialog',
  components: { Dialog, Button },
  mixins: [popupAnalyticsMixin],
  data() {
    return {
      name: 'ratings_dialog',
      isAndroid: isAndroid,
    }
  },
  props: { variant: String },

  mounted() {
    this.logOpen({ variant: this.variant })
  },
  beforeDestroy() {
    this.logClose({ variant: this.variant })
  },
  computed: {
    settings() {
      switch (this.variant) {
        case 'first':
          return this.isAndroid
            ? config.RATINGS_DIALOG_TEXT.firstContentAndroid
            : config.RATINGS_DIALOG_TEXT.firstContentiOS
        case 'second':
          return this.isAndroid
            ? config.RATINGS_DIALOG_TEXT.secondContentAndroid
            : config.RATINGS_DIALOG_TEXT.secondContentiOS
        default:
          return config.RATINGS_DIALOG_TEXT.firstContentAndroid
      }
    },
  },
  methods: {
    rateYes() {
      this.logClick('yes', { variant: this.variant })
      this.$store.commit('SET_SHOW_RATINGS_DIALOG', undefined)
      this.$store.commit('SET_HAS_ATTEMPTED_RATING', true)
      if (!(window?.cordova?.plugins?.AppReview === undefined)) {
        window.cordova.plugins.AppReview.requestReview()
          .catch(() => {
            consoleLog('FAILED to open review screen')
            return window.cordova.plugins.AppReview.openStoreScreen()
          })
          .then(() => {
            consoleLog('SUCCESS opening review screen')
          })
      }
    },
    rateNo() {
      this.logClick('not_now', { variant: this.variant })
      this.$store.commit('SET_SHOW_RATINGS_DIALOG', undefined)
    },
  },
}
</script>

<style>
.ratings-content p {
  text-align: center;
}

.ratings-content p:first-of-type {
  margin-top: 0px;
}
</style>
