import { AdMob } from '@capacitor-community/admob'
import { config } from '@/lib/dynamicConfig.js'
import { devMode, isAndroid, isiOS } from '@/lib/helpers.js'
import consoleLog from '@/lib/consoleLog.js'
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'

const productionInterstitialAdOptions = isAndroid
  ? {
      adId: 'ca-app-pub-4841219939244336/6242831378',
    }
  : {
      adId: 'ca-app-pub-4841219939244336/4902527649',
    }

const testInterstitialAdOptions = {
  adId: 'ca-app-pub-3940256099942544/1033173712',
  isTesting: true,
}

const productionRewardedAdOptions = isAndroid
  ? {
      adId: 'ca-app-pub-4841219939244336/3010163372',
    }
  : {
      adId: 'ca-app-pub-4841219939244336/6178798913',
    }

const testRewardedAdOptions = {
  adId: 'ca-app-pub-3940256099942544/5224354917',
  isTesting: true,
}

let interstitialAdOptions, rewardedAdOptions

async function requestAppTracking() {
  if (isiOS) {
    const { status } = await AppTrackingTransparency.requestPermission()
    consoleLog('AppTrackingTransparency status = ', status)
    if (
      status == 'authorized' &&
      !(window.facebookConnectPlugin === undefined)
    ) {
      window.facebookConnectPlugin.setAdvertiserTrackingEnabled(
        true,
        () => {
          consoleLog('setAdvertiserTrackingEnabled success')
        },
        () => {
          consoleLog('setAdvertiserTrackingEnabled failure')
        }
      )
    }
  } else {
    return Promise.resolve()
  }
}

async function initializeAdMob() {
  await requestAppTracking()
  /*const { status } = await AdMob.trackingAuthorizationStatus()

  if (status === 'notDetermined') {
    /**
     * If you want to explain TrackingAuthorization before showing the iOS dialog,
     * you can show the modal here.
     * ex)
     * const modal = await this.modalCtrl.create({
     *   component: RequestTrackingPage,
     * });
     * await modal.present();
     * await modal.onDidDismiss();  // Wait for close modal
     **/
  //}

  AdMob.initialize({
    requestTrackingAuthorization: false,
    testingDevices: [
      // Samsung A12
      'F81BEB48883D7A88F0D05544DF367B25',
      // iPhone 7 plus
      '2b90a68ac0c39a0a4249c636b822c3a7',
    ],
    initializeForTesting: true,
  })

  interstitialAdOptions =
    devMode || !config.USE_PRODUCTION_ADS
      ? testInterstitialAdOptions
      : productionInterstitialAdOptions

  consoleLog('Using Interstitial AdMob ID:', interstitialAdOptions.adId)

  rewardedAdOptions =
    devMode || !config.USE_PRODUCTION_ADS
      ? testRewardedAdOptions
      : productionRewardedAdOptions

  consoleLog('Using Rewarded AdMob ID:', rewardedAdOptions.adId)
}

export { initializeAdMob, interstitialAdOptions, rewardedAdOptions }
