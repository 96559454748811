<template>
  <div class="fullscreen-container">
    <button v-if="!isMobile" @click="doneButton">Done</button>
  </div>
</template>

<script>
import { AdMob, RewardAdPluginEvents } from '@capacitor-community/admob'

import { isMobile } from '@/lib/helpers.js'
import { logger } from '@/lib/firebase.js'
import consoleLog from '@/lib/consoleLog.js'
import { rewardedAdOptions } from '@/lib/admob.js'

export default {
  name: 'RewardedAd',
  data() {
    return {
      listeners: [],
      fadeToBlack: false,
      isMobile,
    }
  },
  computed: {
    showUpgradeDialog() {
      return this.$store.getters.showUpgradeDialog
    },
  },
  mounted() {
    AdMob.addListener(RewardAdPluginEvents.Showed, this.adShowed).then((x) => {
      this.listeners.push(x)
    })

    AdMob.addListener(
      RewardAdPluginEvents.FailedToShow,
      this.failedToShow
    ).then((x) => {
      this.listeners.push(x)
    })

    AdMob.addListener(RewardAdPluginEvents.Dismissed, this.dismissed).then(
      (x) => {
        this.listeners.push(x)
      }
    )

    AdMob.addListener(RewardAdPluginEvents.Rewarded, this.rewarded).then(
      (x) => {
        this.listeners.push(x)
      }
    )

    setTimeout(() => {
      consoleLog('Admob: attempting to show rewarded ad')
      AdMob.showRewardVideoAd().catch(this.error)
    }, 1000)
  },
  beforeDestroy() {
    consoleLog('RewardedAd beforeDestroy')
    this.listeners.forEach((x) => x.remove())
  },
  methods: {
    adShowed(x) {
      consoleLog('Admob: Rewarded ad showed')
      consoleLog(x)
      logger.logEvent('rewarded_ad_impression', {
        ad_id: rewardedAdOptions.adId,
        status: 'adShowed',
      })
    },
    failedToShow(x) {
      consoleLog('Admob: Rewarded ad failed to show')
      consoleLog(x)
      this.$store.dispatch('unlock_undo_for_level')
      logger.unlockUndos({ error: 'failed_to_show', error_text: x })
      logger.logEvent('rewarded_ad_impression', {
        ad_id: rewardedAdOptions.adId,
        status: 'failedToShow',
        error_text: x,
      })
      this.done()
    },
    error(x) {
      consoleLog('Admob: Rewarded ad error')
      consoleLog(x)
      this.$store.dispatch('unlock_undo_for_level')
      logger.unlockUndos({ error: 'error', error_text: x })
      logger.logEvent('rewarded_ad_impression', {
        ad_id: rewardedAdOptions.adId,
        status: 'error',
        error_text: x,
      })
      this.done()
    },
    dismissed(x) {
      consoleLog('Admob: Rewarded ad dismissed')
      consoleLog(x)
      logger.logEvent('rewarded_ad_impression', {
        ad_id: rewardedAdOptions.adId,
        status: 'dismissed',
      })
      this.done()
    },
    rewarded(x) {
      consoleLog('Admob: Rewarded ad rewarded')
      consoleLog(x)
      this.$store.dispatch('unlock_undo_for_level')
      logger.logEvent('rewarded_ad_impression', {
        ad_id: rewardedAdOptions.adId,
        status: 'rewarded',
      })
      logger.unlockUndos()
      this.done()
    },
    doneButton() {
      this.$store.dispatch('unlock_undo_for_level')
      this.done()
    },
    done() {
      this.$emit('done')
    },
  },
}
</script>

<style scoped>
.fullscreen-container {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}
</style>
