export default {
  'main-background-colour': '#003745',
  'main-background-colour-opaque': 'rgba(0, 55, 69, 0.9)',
  'main-item-colour': '#cdf4dd',
  'green-colour': '#468b18',
  'yellow-colour': '#e0a800',
  'red-colour': '#ff3633',
  'blue-colour': '#0D70D3',
  'purple-colour': '#C90397',
  'indigo-colour': '#6610F2',
  'orange-colour': '#FF8F1F',
  'grey-colour': '#8B867E',
}
