import { logger } from '@/lib/firebase.js'
/*
Popup must implement:

Properties

name: name of dialog
settings: config settings object


productsOffered: product ids being sold (not necessarily available)

*/

export default {
  computed: {
    productsAvailable() {
      if (
        this.productsOffered === undefined ||
        this.productsKnown === undefined
      ) {
        return undefined
      } else {
        // productsKnown comes from purchase mixin
        return this.productsOffered.filter((x) =>
          this.productsKnown.includes(x)
        )
      }
    },
    productPrices() {
      if (this.productsAvailable === undefined) {
        return undefined
      } else {
        return this.productsAvailable.map((x) =>
          this.$store.getters.getPriceForItem(x)
        )
      }
    },
  },
  methods: {
    purchaseWrapper(item) {
      this.logClick('purchase', { item: item })
      this.purchase(item)
    },
    logClick(button, additionalVars = {}) {
      logger.buttonClick(`${this.name}_${button}`, {
        stage: this.stage,
        ...additionalVars,
      })
    },
    makePopupParams() {
      let params = {}

      if (!(this.settings?.version === undefined)) {
        params['version'] = this.settings['version']
      }
      if (!(this.stage === undefined)) {
        params['stage'] = this.stage
      }
      if (!(this.productsAvailable === undefined)) {
        params['products_available'] = JSON.stringify(this.productsAvailable)
      }
      if (!(this.productPrices === undefined)) {
        params['product_prices'] = JSON.stringify(this.productPrices)
      }
      return params
    },
    logOpen(params = {}) {
      logger.showPopup(this.name, { ...this.makePopupParams(), ...params })
    },
    logTransition(params = {}) {
      logger.transitionPopup(this.name, {
        ...this.makePopupParams(),
        ...params,
      })
    },
    logClose(params = {}) {
      logger.closePopup(this.name, { ...this.makePopupParams(), ...params })
    },
  },
}
