<template>
  <div class="welcome-container">
    <!-- eslint-disable -->
    <!-- prettier-ignore -->
    <span class="title-text"><span id="title-B" @click="titleClick('B')">B</span>iki<span id="title-t" @mousedown="titleClick('t')" @touchstart="titleClick('t')">t</span></span>
    <!-- eslint-enable -->
    <span v-if="anyPurchaseMade" class="subtitle-text text-small">Premium</span>
    <Button @click="start">Start new game</Button>
    <Button
      v-if="maxLevel > 0 && maxLevel < levelCount"
      @click="continueFromMax"
    >
      Continue from level {{ maxLevel + 1 }}
    </Button>
    <template v-if="!anyPurchaseMade">
      <Button
        v-if="MONETISATION_STRATEGY == 'level_def'"
        @click="upgrade('welcome_upgrade')"
      >
        Show us some love
        <span style="color: red">&nbsp;&#x2764;</span>
      </Button>
    </template>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'
import { startMusic } from '@/lib/sound.js'
import { logger } from '@/lib/firebase.js'

import { config } from '@/lib/dynamicConfig.js'
import consoleLog from '@/lib/consoleLog.js'

export default {
  name: 'WelcomeScreen',
  components: {
    Button,
  },
  data() {
    return {
      testLoading: false,
      showUpgradeDialog: undefined,
      config: config,
      titleClickVal: 0,
      titleClickTimeout: undefined,
    }
  },
  mounted() {
    logger.setScreen('welcome')
    consoleLog('completedLevels = ', this.$store.getters.completedLevels)
    consoleLog('level order is:')
    consoleLog(this.$store.getters.levels.map((l) => l.key))
  },
  computed: {
    maxLevel() {
      return this.$store.getters.maxLevel
    },
    levelCount() {
      return this.$store.getters.levels.length
    },
    products() {
      return this.$store.getters.products
    },
    anyPurchaseMade() {
      return this.$store.getters.anyPurchaseMade
    },
    MONETISATION_STRATEGY() {
      return config.MONETISATION_STRATEGY
    },
  },
  methods: {
    upgrade(flow) {
      this.$store.commit('SET_SHOW_UPGRADE_DIALOG', flow)
    },
    start() {
      logger.buttonClick('start_new_game')

      const startGame = () => {
        this.$store.commit('SET_MAX_LEVEL', 0)
        this.$store.commit('SET_COMPLETED_LEVELS', [])
        this.$store.dispatch('reset_instruction_status')
        this.$emit('start')
        if (this.$store.getters.musicEnabled) {
          startMusic()
        }
      }
      if (this.maxLevel > 0 && this.maxLevel < this.levelCount) {
        this.$store.commit('SET_SHOW_CLEAR_PROGRESS_DIALOG', {
          yesCallback: startGame,
        })
      } else {
        startGame()
      }
    },
    continueFromMax() {
      this.$emit('continue_from_max')
      if (this.$store.getters.musicEnabled) {
        startMusic()
      }
      logger.buttonClick('continue_from_max', { max_level_id: this.maxLevel })
    },
    titleClick(letter) {
      if (letter == 'B') {
        this.titleClickVal += 1
        consoleLog(`backdoor: B clicked ${this.titleClickVal}`)
        clearTimeout(this.titleClickTimeout)
        this.titleClickTimeout = setTimeout(() => {
          this.titleClickVal = 0
          consoleLog('backdoor: timeout')
        }, 1000)
      }

      if (this.titleClickVal == 3 && letter == 't') {
        consoleLog('backdoor: t hold started')
        this.titleClickVal = 0
        clearTimeout(this.titleClickTimeout)

        const timeout = setTimeout(() => {
          consoleLog('backdoor: TRIGGERED')
          this.$store.commit('SET_SHOW_BACKDOOR_DIALOG', true)
          clearEventListeners()
        }, 2000)

        const clearEventListeners = () => {
          window.removeEventListener('mousemove', cancelHold)
          window.removeEventListener('touchmove', cancelHold)
          window.removeEventListener('mouseup', cancelHold)
          window.removeEventListener('touchend', cancelHold)
        }

        const cancelHold = (event) => {
          if (
            (event.type == 'mousemove' && event.target.id != 'title-t') ||
            event.type != 'mousemove'
          ) {
            consoleLog('backdoor: cancelled')
            clearTimeout(timeout)
            clearEventListeners()
          }
        }
        window.addEventListener('mousemove', cancelHold)
        window.addEventListener('touchmove', cancelHold)
        window.addEventListener('mouseup', cancelHold)
        window.addEventListener('touchend', cancelHold)
      }
    },
  },
}
</script>

<style scoped>
.welcome-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
  /* three buttons fit all the way down to iphone 4; no need to scroll */
  /* overflow-y: scroll; */
}

.title-text {
  font-size: 5rem;
  font-weight: 100;
}

.subtitle-text {
  color: var(--yellow-colour);
  transition: opacity 0.5s;
  display: block;
  text-align: center;
}
</style>
