<template>
  <div class="fullscreen-container">
    <button v-if="!isMobile" @click="done">Done</button>
  </div>
</template>

<script>
import { AdMob, InterstitialAdPluginEvents } from '@capacitor-community/admob'

import { isMobile } from '@/lib/helpers.js'
import consoleLog from '@/lib/consoleLog.js'
import { logger } from '@/lib/firebase.js'
import { interstitialAdOptions } from '@/lib/admob.js'

export default {
  name: 'InterstitialAd',
  data() {
    return {
      listeners: [],
      fadeToBlack: false,
      isMobile,
    }
  },
  computed: {
    showUpgradeDialog() {
      return this.$store.getters.showUpgradeDialog
    },
  },
  mounted() {
    AdMob.addListener(InterstitialAdPluginEvents.Showed, this.adShowed).then(
      (x) => {
        this.listeners.push(x)
      }
    )
    AdMob.addListener(
      InterstitialAdPluginEvents.FailedToShow,
      this.failedToShow
    ).then((x) => {
      this.listeners.push(x)
    })
    AdMob.addListener(
      InterstitialAdPluginEvents.Dismissed,
      this.dismissed
    ).then((x) => {
      this.listeners.push(x)
    })

    setTimeout(() => {
      this.$store.commit('SET_SHOW_BLACK_SCREEN', true)
    }, 500)

    setTimeout(() => {
      consoleLog('Admob: attempting to show ad')
      AdMob.showInterstitial()
    }, 1000)
  },
  beforeDestroy() {
    consoleLog('InterstitialAd beforeDestroy')
    this.listeners.forEach((x) => x.remove())
  },
  methods: {
    adShowed(x) {
      consoleLog('Admob: Ad showed')
      consoleLog(x)
      logger.logEvent('interstitial_ad_impression', {
        ad_id: interstitialAdOptions.adId,
        status: 'adShowed',
      })
    },
    failedToShow(x) {
      consoleLog('Admob: Ad failed to show')
      consoleLog(x)
      logger.logEvent('interstitial_ad_impression', {
        ad_id: interstitialAdOptions.adId,
        status: 'failedToShow',
        error_text: x,
      })
      this.done()
    },
    dismissed(x) {
      consoleLog('Admob: ad dismissed')
      consoleLog(x)
      logger.logEvent('interstitial_ad_impression', {
        ad_id: interstitialAdOptions.adId,
        status: 'dismissed',
      })
      this.done()
    },
    done() {
      if (!this.$store.getters.interstitialInfoShown) {
        this.$store.commit('SET_SHOW_UPGRADE_DIALOG', 'interstitial')
        this.$store.commit('SET_INTERSTITIAL_INFO_SHOWN', true)
      } else {
        this.$store.commit('SET_SHOW_BLACK_SCREEN', false)
        this.$emit('done')
      }
    },
  },
  watch: {
    showUpgradeDialog(new_val, old_val) {
      if (new_val === undefined && old_val == 'interstitial') {
        this.$store.commit('SET_SHOW_BLACK_SCREEN', false)
        this.$emit('done')
      }
    },
  },
}
</script>

<style scoped>
.fullscreen-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
