<template>
  <div style="z-index: 999">
    <div class="cookie-fullscreen-overlay"></div>
    <div class="cookie-popup-container">
      <p class="text-small">
        We use cookies to help make this game as fun as possible. If you
        continue from this point, we'll assume you're ok with that.
      </p>
      <Button @click="ok">Ok</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
  name: 'CookiePopup',
  components: { Button },
  methods: {
    ok() {
      window.localStorage.setItem('cookieConsentGiven', 'true')
      this.$emit('hide')
    },
  },
}
</script>

<style scoped>
p {
  text-align: center;
  margin-bottom: 0px;
}

.cookie-fullscreen-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

.cookie-popup-container {
  position: absolute;
  bottom: 0px;
  background-color: var(--main-background-colour);
  width: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 40rem;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 1rem 3rem 1rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
