<template>
  <Dialog>
    <div class="interstitial-dialog-inner-container">
      <MultiSwitcher
        :currentStage="
          stageMap({
            main: ['intro', 'purchasing', 'thanks', 'error'],
            remind: ['remind'],
          })
        "
      >
        <template v-slot:main>
          <MultiSwitcher
            :currentStage="
              stageMap({
                intro: ['intro', 'purchasing'],
                thanks: ['thanks'],
                error: ['error'],
              })
            "
            @switched="isPurchaseInProgress = false"
          >
            <template v-slot:intro>
              <span v-html="settings['introText']"></span>
              <!-- intro and purchasing -->
            </template>
            <template v-slot:thanks>
              <span v-html="settings['thanksText']"></span>
              <!-- thanks -->
            </template>
            <template v-slot:error>
              <span v-html="settings['errorText']"></span>
              <svg class="icon" viewBox="0 0 24 24">
                <path :d="mdiCog" />
              </svg>
              <!-- thanks -->
            </template>
            <!-- remind is blank -->
          </MultiSwitcher>
          <div class="controls-container">
            <Button
              v-if="['intro', 'purchasing', 'remind'].includes(stage)"
              @click="purchaseWrapper(PREMIUM_VERSION_ITEM)"
              :loading="isPurchaseInProgress"
              :disabled="!productsKnown.includes(PREMIUM_VERSION_ITEM)"
            >
              <span v-if="productsKnown.includes(PREMIUM_VERSION_ITEM)">
                {{
                  settings['ctaText'].replace('{PRICE}', premiumVersionPrice)
                }}
              </span>
              <span v-else>Upgrade temporarily unavailable</span>
            </Button>
            <Button
              v-if="['thanks', 'error'].includes(stage)"
              @click="continueButton($event)"
              :loading="isPurchaseInProgress"
            >
              Continue
            </Button>
          </div>
          <MultiSwitcher :currentStage="stageMap({ show: ['intro'] })">
            <template v-slot:show>
              <!-- show only during intro stage -->
              <div class="controls-container">
                <div v-if="countDown > 0" class="countdown-container">
                  <!--width="4rem"-->
                  <svg
                    version="1.1"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid meet"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="50"
                      cy="50"
                      r="45"
                      :stroke-dasharray="countdownStrokeDashArray"
                      :stroke-dashoffset="countdownStrokeDashOffset"
                    />
                    <text
                      x="50"
                      y="50"
                      text-anchor="middle"
                      dominant-baseline="central"
                    >
                      {{ Math.ceil(countDown) }}
                    </text>
                  </svg>
                </div>
                <Button
                  class="transition-opacity"
                  :style="{ opacity: countDown == 0 ? 1 : 0 }"
                  @click="notNow"
                >
                  Not now
                </Button>
              </div>
            </template>
          </MultiSwitcher>
        </template>
        <template v-slot:remind>
          <span v-html="settings['remindText']"></span>
          <svg class="icon" viewBox="0 0 24 24">
            <path :d="mdiCog" />
          </svg>
          <div class="controls-container">
            <Button @click="continueButton($event)">Continue</Button>
          </div>
        </template>
      </MultiSwitcher>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import MultiSwitcher from '@/components/MultiSwitcher.vue'
import Button from '@/components/Button.vue'

import { config } from '@/lib/dynamicConfig.js'
import purchaseMixin from '@/lib/purchaseMixin.js'
import popupAnalyticsMixin from '@/lib/popupAnalyticsMixin.js'

import gsap from 'gsap'
import { mdiCog } from '@mdi/js'

export default {
  name: 'UpgradeDialogInterstitial',
  components: { Dialog, MultiSwitcher, Button },
  mixins: [purchaseMixin, popupAnalyticsMixin],
  data() {
    return {
      name: 'interstitial_dialog',
      isPurchaseInProgress: false,
      itemPurchased: undefined,
      stage: undefined,
      startCountdown: 5,
      countDown: undefined,
      countdownStrokeDashOffset: (2 * Math.PI * 45) / 4,
      countdownInterval: undefined,
      countdownTween: undefined,
      mdiCog,
      PREMIUM_VERSION_ITEM: config.PREMIUM_VERSION_ITEM,
      settings: config.INTERSTITIAL_UPGRADE_TEXT,
      productsOffered: [config.PREMIUM_VERSION_ITEM],
    }
  },
  created() {
    if (this.anyPurchaseMade) {
      this.stage = 'thanks'
    } else {
      this.stage = 'intro'
    }
  },
  mounted() {
    this.logOpen()
    this.countDown = this.startCountdown
    this.countdownInterval = setInterval(() => {
      this.countdownTween = gsap.to(this, {
        countDown: this.countDown - 1,
        duration: 0.5,
        onComplete: () => {
          if (this.countDown == 0) {
            clearInterval(this.countdownInterval)
          }
        },
      })
    }, 1000)
  },
  computed: {
    anyPurchaseMade() {
      return this.$store.getters.anyPurchaseMade
    },
    premiumVersionPrice() {
      return this.$store.getters.getPriceForItem(this.PREMIUM_VERSION_ITEM)
    },
    countdownStrokeDashArray() {
      const v = this.countDown
      const epsilon = 0.0000001
      return `${2 * Math.PI * (v / (this.startCountdown + epsilon)) * 45} ${
        2 * Math.PI * (1 - v / (this.startCountdown + epsilon)) * 45
      }`
    },
  },
  methods: {
    debug() {
      debugger
    },
    stageMap(stageMap) {
      return (
        Object.keys(stageMap).filter((k) =>
          stageMap[k].includes(this.stage)
        )[0] || ''
      )
    },
    notNow() {
      if (this.countDown == 0) {
        this.logClick('not_now')
        this.stage = 'remind'
      }
    },
    continueButton(event) {
      this.logClick('continue')
      this.logClose()
      this.$emit('close', event)
    },
  },
  watch: {
    stage(new_val, old_val) {
      if (!(old_val === undefined)) {
        this.logTransition()
      }
    },
  },
}
</script>

<style scoped>
.interstitial-dialog-inner-container {
  width: 100%;
  text-align: center;
}

.controls-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.countdown-container {
  position: absolute;
  top: 0px;
  padding-top: 1.5rem;
  width: 100%;
  height: 100%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countdown-container svg {
  width: 3rem;
}

.countdown-container text {
  fill: var(--main-item-colour);
  font-size: 2.5rem;
}

.countdown-container circle {
  stroke: var(--main-item-colour);
  fill: none;
  stroke-width: 4;
}

.transition-opacity {
  transition: opacity 0.5s;
}

.icon {
  width: 3.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.icon path {
  fill: var(--main-item-colour);
}
</style>

<style>
.interstitial-dialog-inner-container p:first-of-type {
  margin-top: 0px;
}
</style>
