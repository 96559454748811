<template>
  <div class="float-at-bottom">
    <div>A Just Think of the Rain production</div>
    <div class="links-container" v-if="isWeb">
      <a
        href="https://justthinkoftherain.com/bikit/privacy-policy.html"
        target="_blank"
      >
        Privacy policy
      </a>
      <span>|</span>
      <a
        href="https://justthinkoftherain.com/bikit/cookie-policy.html"
        target="_blank"
      >
        Cookie policy
      </a>
    </div>
    <div class="store-links-container" v-if="isWeb">
      <a
        href="https://apps.apple.com/us/app/bikit/id1609104375?itsct=apps_box_link&itscg=30200"
        target="_blank"
        @click="appStoreLinkClick"
      >
        <img
          src="@/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
          class="appstore"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=app.web.squarepusher&utm_source=bikit_web"
        target="_blank"
        @click="playStoreLinkClick"
      >
        <img
          src="@/assets/images/google-play-badge-cropped.png"
          class="playstore"
        />
      </a>
    </div>
  </div>
</template>

<script>
import { isWeb } from '@/lib/helpers.js'
import { logger } from '@/lib/firebase.js'

export default {
  name: 'CompanyText',
  data() {
    return {
      isWeb,
    }
  },
  methods: {
    appStoreLinkClick() {
      logger.buttonClick('appstore_download')
    },
    playStoreLinkClick() {
      logger.buttonClick('playstore_download')
    },
  },
}
</script>

<style scoped>
.float-at-bottom {
  position: absolute;
  bottom: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

span {
  font-size: 0.7rem;
}

a {
  color: var(--main-item-colour);
}

div {
  font-size: 0.7rem;
  display: flex;
  flex-direction: row;
}

.links-container > * + * {
  margin-left: 0.5em;
}

.store-links-container {
  margin-top: 1rem;
}

.store-links-container > * + * {
  margin-left: 1rem;
}

img.appstore {
  width: 125px;
}

img.playstore {
  width: calc(125px * (58.5 / 52.13));
}
</style>
