
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
                       
  "2x2_t2_1": {
      
                                    
                                          
                                 
                                                    
                         
    
                   
                               
                               
        
    "key": "2x2_t2_1",
    "startGrid": [
      [2, 1],
      [1, 1]
    ],
    "goalGrid": [
      [0, 0],
      [0, 2]
    ]
  },

  "2x3_t2_1": {
    "key": "2x3_t2_1",
    "startGrid": [
      [1, 1],
      [0, 2],
      [1, 1]
    ],
    "goalGrid": [
      [0, 0],
      [0, 0],
      [2, 0]
    ]
  },

  "2x3_t2_2": {
    "key": "2x3_t2_2",
    "startGrid": [
      [1, 1],
      [2, 0],
      [1, 1]
    ],
    "goalGrid": [
      [0, 0],
      [0, 0],
      [0, 2]
    ]
  },

  "3x3_t2_6": {
    "key": "3x3_t2_6",
    "startGrid": [
      [2, 0, 1],
      [1, 1, 0],
      [1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 2, 0]
    ]
  },

  "3x3_t2_2": {
      
                                    
                                           
                                 
                                                      
                         
    
                   
                              
                              
                              
                              
        
    "startGrid": [
      [2, 1, 0],
      [1, 1, 1],
      [1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 2]
    ]
  },
                           

                                           
    
                                
                                      
                             
                                               
                      

                                                  
                   

                
                           
                           
                           
                            
                           
                           
                           
                           
                          
                          
                          
                           
                          
                          
                           
                          
                          
                           
    
  "6x6_t22_1": {
    "key": "6x6_t22_1",
    "startGrid": [
      [2, 1, 2, 0, 0, 0],
      [0, 0, 1, 0, 0, 0],
      [1, 1, 1, 1, 1, 1],
      [1, 0, 0, 0, 0, 1],
      [1, 0, 0, 1, 0, 1],
      [1, 0, 0, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 2, 0, 2]
    ]
  },

  "6x4_t2233_1": {
      
                                  
                                        
                               
                                                 
                        

                   

                  
                             
                              
                              
                              
                             
                              
                             
                             
                              
                             
                             
                             
                            
                            
                            
                             
                            
                            
      
    "startGrid": [
      [2, 1, 0, 0, 1, 1],
      [3, 0, 1, 0, 1, 1],
      [3, 1, 0, 1, 0, 1],
      [2, 1, 0, 0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 3],
      [0, 0, 0, 0, 0, 2],
      [0, 0, 0, 0, 0, 2],
      [0, 0, 0, 0, 0, 3]
    ]
  },

  "5x6_t23_2": {
      
                                  
                                        
                               
                                                  
                        

                     

                  
                             
                            
                             
                            
                            
                            
                             
                            
                             
                             
                             
                             
      
    "key": "5x6_t23_2",
    "startGrid": [
      [2, 0, 3, 0, 1],
      [1, 0, 0, 0, 0],
      [1, 1, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [0, 0, 1, 0, 0],
      [1, 1, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [2, 0, 3, 0, 0]
    ]
  },

  "3x3_t2_4": {
    "key": "3x3_t2_4",
    "startGrid": [
      [2, 1, 1],
      [1, 0, 1],
      [1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 2]
    ]
  },

  "3x3_t2_5": {
    "key": "2x3_t2_5",
    "startGrid": [
      [2, 0, 1],
      [1, 1, 1],
      [1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 2]
    ]
  },

  "3x3_t2_1": {
      
                                  
                                         
                               
                                                    
                       
  
                 
                             
                             
                             
                             
      
    "startGrid": [
      [2, 1, 0],
      [0, 1, 1],
      [1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 2]
    ]
  },

  "3x3_t22_1": {
      
                                  
                                         
                               
                                                    
                       
  
                 
                            
                            
                            
                            
      
    "startGrid": [
      [2, 0, 2],
      [1, 1, 1],
      [1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [2, 0, 2]
    ]
  },

  "3x3_t23_1": {
      
                                  
                                        
                               
                                                    
                       
  
                 
                             
                             
                             
                             
                             
      
    "startGrid": [
      [2, 0, 3],
      [1, 1, 1],
      [1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [3, 0, 2]
    ]
  },

  "3x4_t2_1": {
      
                                  
                                         
                               
                                                    
                       
  
                 
                             
                             
                            
                            
                             
      
    "startGrid": [
      [2, 1, 0],
      [0, 1, 1],
      [1, 1, 0],
      [1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 2]
    ]
  },

  "4x4_t23_1": {
      
                                  
                                         
                               
                                                    
                       
  
                           
                            
                           
                            
                            
                            
      
    "startGrid": [
      [2, 0, 0, 3],
      [1, 1, 1, 1],
      [0, 1, 1, 0],
      [1, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 3, 2, 0]
    ]
  },

  "3x4_t2_2": {
      
                                  
                                         
                               
                                                   
                       
  
                 
                            
                             
                             
                             
                             
                            
      
    "startGrid": [
      [0, 1, 2],
      [1, 1, 0],
      [0, 1, 1],
      [1, 1, 0],
      [0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 2]
    ]
  },

  "5x5_t2_1": {
      
                                  
                                         
                               
                                                    
                       
  
                 
                             
                             
                             
                            
                            
                            
                             
                            
      
    "startGrid": [
      [2, 1, 1, 0, 0],
      [1, 0, 1, 0, 1],
      [1, 1, 0, 1, 0],
      [0, 0, 1, 1, 1],
      [0, 1, 0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 2]
    ]
  },

  "5x6_t23_1": {
      
                                  
                                        
                               
                                                   
                       
  
                       
  
                  
                             
                              
                             
                             
                            
                             
                             
                            
      
    "startGrid": [
      [0, 3, 1, 2, 0],
      [1, 0, 0, 0, 1],
      [0, 1, 1, 1, 0],
      [0, 0, 1, 0, 0],
      [1, 1, 0, 0, 1],
      [1, 0, 1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 2, 0, 3, 0]
    ]
  },

  "5x4_t2_1": {
      
                                  
                                         
                               
                                                   
                       
  
                                         
  
                 
                             
                            
                             
                             
                             
      
    "startGrid": [
      [1, 0, 1, 0, 0],
      [1, 0, 1, 0, 1],
      [0, 1, 0, 1, 0],
      [2, 0, 1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 2, 0]
    ]
  },

  "5x5_t23_1": {
      
                                  
                                        
                               
                                                   
                       
  
                                  
  
                  
                             
                              
                              
                             
                            
                             
                             
                             
      
    "startGrid": [
      [2, 3, 1, 0, 1],
      [0, 1, 0, 1, 0],
      [1, 0, 1, 0, 1],
      [0, 1, 0, 1, 0],
      [1, 0, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 3, 2]
    ]
  },

  "5x4_t2_2": {
      
                                  
                                        
                               
                                                    
                       
  
  
                                                                         
  
                 
                            
                            
                             
                             
                             
      
    "startGrid": [
      [1, 0, 2, 0, 1],
      [1, 0, 0, 0, 1],
      [0, 1, 0, 1, 0],
      [1, 1, 0, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 2, 0, 0]
    ]
  },

  "5x5_t2_2": {
      
                                        
                               
                                                    
                       
  
                 
                             
                            
                             
                            
                             
                             
      
    "startGrid": [
      [1, 0, 2, 0, 1],
      [0, 1, 0, 1, 0],
      [0, 0, 0, 0, 0],
      [0, 1, 1, 1, 0],
      [1, 0, 1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 2, 0, 0]
    ]
  },

  "6x6_t23_1": {
      
                                  
                                        
                               
                                                  
                        
  
                             
                                         
  
                  
                              
                              
                             
                             
                              
                             
                              
                             
                             
                            
                            
                            
                            
                            
                             
                             
                            
                             
                             
      
    "startGrid": [
      [3, 1, 0, 0, 1, 2],
      [0, 0, 0, 0, 0, 0],
      [1, 1, 0, 0, 1, 1],
      [0, 0, 1, 1, 0, 0],
      [1, 1, 0, 0, 1, 1],
      [1, 1, 1, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [2, 0, 0, 0, 0, 3]
    ]
  },

  "6x5_t2_1": {
      
                                  
                                        
                               
                                                 
                       
  
                          
  
                 
                            
                             
                            
                            
                            
      
    "startGrid": [
      [0, 2, 0, 0, 1, 1],
      [1, 0, 1, 0, 0, 1],
      [0, 1, 0, 0, 1, 0],
      [0, 0, 1, 1, 0, 0],
      [1, 1, 0, 0, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 2, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0]
    ]
  },

  "6x5_t23_1": {
      
                          
      
                                  
                                        
                               
                                                   
                        
  
                  
                             
                              
                              
                            
                             
                            
                             
                             
                            
                             
      
    "startGrid": [
      [3, 1, 1, 0, 1, 2],
      [0, 0, 1, 1, 0, 0],
      [1, 1, 0, 0, 1, 1],
      [0, 1, 0, 0, 1, 0],
      [0, 1, 1, 1, 0, 0]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 2, 3, 0, 0]
    ]
  },

  "4x4_t23_2": {
      
                                  
                                        
                               
                                                    
                        
  
                  
                              
                              
                             
                             
                             
                             
                             
                             
                             
                             
      
    "startGrid": [
      [2, 1, 1, 0],
      [1, 0, 1, 0],
      [0, 1, 0, 1],
      [0, 1, 1, 3]
    ],
    "goalGrid": [
      [3, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 2]
    ]
  },

  "5x5_t23_2": {
      
                                  
                                        
                               
                                                  
                        
  
                          
  
                  
                              
                              
                              
                            
                             
                             
                             
                            
                            
                            
                            
                            
      
    "startGrid": [
      [0, 2, 0, 3, 0],
      [1, 0, 1, 0, 1],
      [0, 0, 1, 0, 0],
      [1, 1, 0, 1, 1],
      [1, 1, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [3, 0, 0, 0, 2]
    ]
  },

  "4x3_t23_1": {
      
                                  
                                         
                               
                                                  
                       
  
                  
                             
                            
                             
                            
                             
                            
      
    "startGrid": [
      [2, 1, 0, 1],
      [1, 0, 1, 1],
      [3, 1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 3],
      [0, 0, 0, 0],
      [0, 0, 0, 2]
    ]
  },
                                              

  "5x5_t234_1": {
      
                                
                                      
                             
                                                 
                     
  
                                       
  
               
                           
                          
                           
                          
                           
      
    "startGrid": [
      [0, 2, 3, 4, 0],
      [1, 0, 1, 0, 1],
      [0, 0, 1, 0, 0],
      [0, 1, 0, 1, 0],
      [1, 1, 1, 0, 1]
    ],
    "goalGrid": [
      [0, 4, 2, 3, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0]
    ]
  },

  "4x5_t234_1": {
      
                                  
                                        
                               
                                                  
                        
  
                  
                              
                              
                              
                              
                             
                            
                            
                            
                             
                            
                            
                            
                             
                             
                            
      
    "startGrid": [
      [0, 0, 1, 1],
      [2, 1, 0, 1],
      [3, 0, 1, 1],
      [4, 1, 0, 1],
      [0, 0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 2],
      [0, 0, 0, 0],
      [0, 0, 0, 3],
      [0, 0, 0, 0],
      [0, 0, 0, 4]
    ]
  },

  "6x6_t2_1": {
      
                                
                                      
                             
                                                 
                     
  
                        
  
               
                           
                           
                           
                           
                          
                          
                           
                           
                           
      
    "startGrid": [
      [1, 0, 0, 0, 1, 1],
      [0, 0, 1, 0, 0, 1],
      [0, 1, 0, 1, 1, 0],
      [0, 0, 1, 0, 0, 0],
      [1, 0, 0, 0, 1, 0],
      [1, 2, 0, 0, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 2, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0]
    ]
  },

  "5x5_t2_3": {
      
                                  
                                        
                               
                                                  
                       
  
                 
                             
                             
                             
                            
                            
                             
                             
                            
                            
      
    "startGrid": [
      [2, 0, 0, 1, 0],
      [0, 1, 0, 0, 1],
      [1, 0, 1, 0, 1],
      [1, 0, 0, 1, 0],
      [1, 0, 0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 2]
    ]
  },

  "6x6_t2_2": {
      
                                  
                                        
                               
                                                  
                        
  
                  
                            
                             
                             
                             
                            
                            
                             
                             
                             
                            
                            
                             
                            
                            
                            
      
    "startGrid": [
      [2, 1, 0, 1, 0, 1],
      [0, 1, 0, 1, 0, 1],
      [0, 0, 1, 0, 0, 0],
      [0, 1, 0, 0, 1, 1],
      [1, 0, 0, 0, 1, 0],
      [1, 1, 0, 0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 2]
    ]
  },

  "6x5_t2_2": {
      
                                  
  
                      
  
                                        
                               
                                                   
                       
  
                 
                            
                            
                             
                             
                            
                            
                            
      
    "startGrid": [
      [1, 2, 0, 0, 1, 1],
      [0, 0, 0, 0, 0, 0],
      [1, 1, 0, 0, 1, 1],
      [0, 1, 0, 0, 1, 0],
      [1, 1, 0, 0, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 2, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0]
    ]
  },

  "6x6_t2345_1": {
      
                                  
                                        
                               
                                                  
                        
  
                            
  
                  
                             
                              
                             
                              
                             
                              
                              
                             
                            
                             
                            
                            
                            
                            
                            
      
    "startGrid": [
      [5, 1, 0, 0, 1, 3],
      [0, 0, 0, 0, 0, 0],
      [0, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 1, 0],
      [1, 1, 0, 0, 1, 1],
      [2, 0, 0, 0, 0, 4]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 2, 3, 0, 0],
      [0, 0, 4, 5, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0]
    ]
  },

  "6x4_t2_1": {
      
                                       
                               
                                                  
                        
  
                        
  
                 
                             
                             
                            
                             
                            
                             
                             
                            
                            
                             
      
    "startGrid": [
      [0, 1, 1, 1, 1, 0],
      [0, 1, 0, 0, 1, 0],
      [1, 1, 0, 0, 1, 1],
      [2, 0, 1, 1, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 2]
    ]
  },

  "4x4_t2345_1": {
      
                                  
                                        
                               
                                                 
                        
  
                            
  
                  
                              
                              
                             
                             
                             
                             
                            
                            
                             
                            
                            
                             
                            
                            
                             
                            
      
    "startGrid": [
      [2, 3, 4, 5],
      [1, 0, 1, 0],
      [0, 1, 0, 1],
      [1, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [0, 0, 0, 0],
      [2, 3, 4, 5]
    ]
  },

  "6x6_t23_2": {
      
                                  
                                        
                               
                                                 
                        
  
                       
  
                  
                              
                             
                             
                             
                             
                             
                            
                             
                             
                             
                             
                             
                             
      
    "startGrid": [
      [1, 1, 0, 0, 0, 1],
      [0, 0, 1, 1, 0, 1],
      [2, 1, 0, 0, 0, 0],
      [3, 1, 0, 0, 0, 0],
      [0, 0, 1, 1, 0, 1],
      [1, 1, 0, 0, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 3],
      [0, 0, 0, 0, 0, 2],
      [0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0]
    ]
  },

  "5x5_t2345_1": {
      
                                  
                                       
                               
                                                   
                        
  
                        
  
                  
                              
                              
                             
                             
                            
                             
                             
                            
                             
                            
                             
      
    "startGrid": [
      [1, 0, 5, 0, 1],
      [0, 0, 1, 0, 0],
      [2, 1, 1, 1, 4],
      [0, 0, 1, 0, 0],
      [1, 0, 3, 0, 1]
    ],
    "goalGrid": [
      [0, 0, 4, 0, 0],
      [0, 0, 0, 0, 0],
      [5, 0, 0, 0, 3],
      [0, 0, 0, 0, 0],
      [0, 0, 2, 0, 0]
    ]
  },
                           

  "5x5_t2345_2": {
      
                                  
                                        
                               
                                                 
                        
  
                                    
  
                  
                             
                              
                              
                              
                             
                             
                            
                            
                            
                            
                             
                            
                            
                            
                             
      
    "startGrid": [
      [2, 3, 0, 4, 5],
      [0, 1, 0, 1, 0],
      [1, 0, 1, 0, 1],
      [0, 1, 0, 1, 0],
      [1, 1, 1, 1, 1]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [4, 0, 0, 0, 5],
      [0, 0, 0, 0, 0],
      [0, 2, 0, 3, 0]
    ]
  },

  "5x5_t234_2": {
      
                                  
                                        
                               
                                                  
                        
  
                                                           
  
                  
                             
                             
                            
                            
                            
                             
                             
                            
                            
                             
                            
                             
                             
                             
                             
                            
                            
     
    "startGrid": [
      [2, 3, 4, 0, 0],
      [0, 1, 1, 1, 0],
      [0, 0, 1, 1, 1],
      [0, 1, 1, 1, 0],
      [1, 1, 1, 0, 0]
    ],
    "goalGrid": [
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0],
      [2, 3, 4, 0, 0]
    ]
  }
}
