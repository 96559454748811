import store from '@/store'
import { config } from '@/lib/dynamicConfig.js'
import consoleLog from '@/lib/consoleLog.js'

const providerStore = window.store

function initStore() {
  if (!window.store) {
    consoleLog('Store not available')
    return
  }

  store.commit('SET_STORE_AVAILABLE', true)
  consoleLog('providerStore available')
  providerStore.ready(() => {
    consoleLog('providerStore ready')
  })

  // Purchasable items
  const items = config.PREMIUM_VERSION_PRODUCTS.map((i) => {
    return {
      id: i,
      type: providerStore?.NON_CONSUMABLE,
    }
  })

  items.forEach((i) => {
    consoleLog('registering:', i.id)
    providerStore.register(i)
    providerStore.when(i.id).updated(updateProduct)
    providerStore.when(i.id).approved(approvePurchase)
    providerStore.when(i.id).verified(finishPurchase)
  })

  providerStore.when('product').refunded(refundAnyProduct)

  providerStore.refresh()
}

function refundAnyProduct(p) {
  consoleLog(`refundAnyProduct handler: ${p.id} = ${p.state}`)
  store.dispatch('remove_product_owned', p.id)
}

function updateProduct(p) {
  consoleLog(`updateProduct handler: ${p.id} = ${p.state}`)
  store.dispatch('update_product_in_store', p)
}

function approvePurchase(p) {
  consoleLog(`approvePurchase handler: ${p.id} = ${p.state}`)
  p.verify()
}

function finishPurchase(p) {
  consoleLog(`finishPurchase handler: ${p.id} = ${p.state}`)
  p.finish()
}

export { initStore, providerStore }
