export const state = {
  showInformationPopup: false,
  interstitialInfoShown: false,
}

export const mutations = {
  SET_SHOW_INFORMATION_POPUP(state, val) {
    state.showInformationPopup = val
  },
  SET_INTERSTITIAL_INFO_SHOWN(state, val) {
    state.interstitialInfoShown = val
  },
}

export const getters = {
  showInformationPopup: (state) => state.showInformationPopup,
  interstitialInfoShown: (state) => state.interstitialInfoShown,
}
