<template>
  <div class="svg-container" :style="svgContainerStyle" ref="svg-container">
    <!-- 
      Using height="100%" on the svg element works on Chrome + Firefox, but not
      on stupid Safari. So we set its height explicitly to match the container
      with the svgHeightStyle.
     -->
    <svg
      version="1.1"
      width="100%"
      :viewBox="`-35 -35 ${xDim * 100 + 70} ${yDim * 100 + 70}`"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      id="svg-main"
    >
      <!--  :style="svgHeightStyle" -->
      <template v-for="g in goalCoordinates">
        <rect
          :key="g.id"
          :id="g.id"
          :x="g.x"
          :y="g.y"
          width="90"
          height="90"
          rx="10"
          ry="10"
          class="goal-rectangle"
          :class="{
            key2: g.type == 2,
            key3: g.type == 3,
            key4: g.type == 4,
            key5: g.type == 5,
            complete: completeGoals.map((x) => x.id).includes(g.id),
          }"
        />
      </template>
      <template v-for="b in ballCoordinates">
        <circle
          :key="b.id"
          :id="b.id"
          :cx="b.x"
          :cy="b.y"
          r="35"
          class="ball"
          :class="{
            hidden: b.type == 0 || hideAllBalls,
            normal: b.type == 1,
            key2: b.type == 2,
            key3: b.type == 3,
            key4: b.type == 4,
            key5: b.type == 5,
            [b.id]: true,
          }"
        />
        <circle
          v-if="colourblindModeEnabled && b.type >= 2"
          :key="`${b.id}_bg`"
          :id="b.id"
          :cx="b.x"
          :cy="b.y"
          class="ball"
          :class="{ [b.id]: true }"
          r="20"
          fill="rgba(0,0,0,0.5)"
        />
        <text
          v-if="colourblindModeEnabled && b.type >= 2"
          text-anchor="middle"
          dominant-baseline="central"
          :key="`${b.id}_text`"
          :x="b.x"
          :y="b.y"
          class="noselect ball text"
          :class="{ [b.id]: true }"
          fill="white"
        >
          {{ b.type - 1 }}
        </text>
      </template>

      <template v-if="colourblindModeEnabled">
        <template v-for="g in goalCoordinates">
          <rect
            :key="`${g.id}_bg`"
            :x="g.x"
            :y="g.y + 92"
            width="90"
            height="35"
            rx="10"
            ry="10"
            fill="rgba(0,0,0,0.5)"
          />
          <text
            text-anchor="middle"
            dominant-baseline="central"
            :key="`${g.id}_text`"
            :x="g.x + 45"
            :y="g.y + 109.5"
            fill="white"
            class="noselect text"
          >
            {{ g.type - 1 }}
          </text>
        </template>
      </template>
      <path
        v-if="linePath.length > 0"
        :d="linePath"
        class="line"
        :class="{ square: squareMade }"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: 'InstructionDiagram',
  data() {
    return {
      svgHeightStyle: undefined,

      linePointsLog: [],
      lineEnd: undefined,
      squareMade: false,
      drawing: false,
      svgPoint: undefined,
      overElementId: undefined,
      hideAllBalls: false,
      moveCount: 0,
      lineProgress: 0,
      offset: 0,
      drawInterval: undefined,
    }
  },
  props: {
    ballGrid: {
      type: Array,
      default: () => {
        return []
      },
    },
    goalGrid: {
      type: Array,
      default: () => {
        return []
      },
    },
    drawPoints: {
      type: Array,
      default: () => {
        return []
      },
    },
    playAnimation: Boolean,
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    this.resize()
    if (this.playAnimation) {
      this.startAnimation()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
    clearInterval(this.drawInterval)
  },
  computed: {
    svgContainerStyle() {
      return {
        /*
        Viewbox width is 100*xDim + 70 
        */
        width: `clamp(0%, calc(4rem * ${this.xDim} + 4rem * (7/10)), 100%)`,
        /*maxHeight: `calc(4rem * ${this.yDim} + 4rem * (7/10))`,*/
        height: `calc(4rem * ${this.yDim} + 4rem * (7/10))`,
      }
    },
    yDim() {
      return this.ballGrid.length
    },
    xDim() {
      if (this.yDim > 0) {
        return this.ballGrid[0].length
      } else {
        return 0
      }
    },
    ballCoordinates() {
      let i = 0
      return this.ballGrid.flatMap((row, yIndex) =>
        this.ballGrid[yIndex].map((val, xIndex) => {
          return {
            index: i++,
            id: `inst-ball-${val}_${xIndex}_${yIndex}`,
            gridX: xIndex,
            gridY: yIndex,
            x: 100 * xIndex + 50,
            y: 100 * yIndex + 50,
            type: val,
            goal: this.goalGrid[yIndex][xIndex],
          }
        })
      )
    },
    goalCoordinates() {
      let i = 0
      return this.goalGrid
        .flatMap((row, yIndex) =>
          this.goalGrid[yIndex].map((val, xIndex) => {
            return {
              index: i++,
              id: `inst-goal-${val}_${xIndex}_${yIndex}`,
              gridX: xIndex,
              gridY: yIndex,
              x: 100 * xIndex + 5,
              y: 100 * yIndex + 5,
              type: val,
            }
          })
        )
        .filter((x) => x.type > 0)
    },
    completeGoals() {
      if (this.ballGrid.length == 0) {
        return []
      }
      return this.goalCoordinates.filter(
        (g) => this.ballGrid[g.gridY][g.gridX] == g.type
      )
    },
    linePoints() {
      let linePoints = []
      this.drawPoints.forEach((p) => {
        const index = this.ballCoordinates.findIndex(
          (b) => b.gridX == p[0] && b.gridY == p[1]
        )
        if (index > -1) {
          linePoints.push(this.ballCoordinates[index])
        }
      })

      return linePoints
    },
    linePath() {
      if (this.linePoints.length == 0 || this.lineProgress == 0) {
        return ''
      }

      //debugger
      const offset = this.offset

      let linePointsShifted = []
      this.linePoints.forEach((x, i) => {
        linePointsShifted.push(
          this.linePoints[(i + offset) % this.linePoints.length]
        )
      })
      linePointsShifted.push(linePointsShifted[0])
      const p = this.lineProgress

      const seg = 1 / (linePointsShifted.length - 1)
      const startIndex = Math.floor(p / seg)
      const progress = (p - startIndex * seg) / seg

      const linePointIndices = Array(startIndex + 1)
        .fill()
        .map((x, i) => i)

      let d = linePointIndices.reduce((s, pIndex, i) => {
        const p = linePointsShifted[pIndex]
        return s + `${i == 0 ? 'M' : 'L'} ${p.x} ${p.y} `
      }, '')

      if (startIndex + 1 < linePointsShifted.length) {
        const endX =
          linePointsShifted[startIndex].x +
          (linePointsShifted[startIndex + 1].x -
            linePointsShifted[startIndex].x) *
            progress
        const endY =
          linePointsShifted[startIndex].y +
          (linePointsShifted[startIndex + 1].y -
            linePointsShifted[startIndex].y) *
            progress
        d = d + `L ${endX} ${endY}`
      }

      return d
    },
    colourblindModeEnabled() {
      return this.$store.getters.colourblindModeEnabled
    },
  },
  methods: {
    resize() {
      const height = this.$refs['svg-container'].getBoundingClientRect().height
      this.svgHeightStyle = { height: height + 'px' }
    },
    calculateNewOffset() {
      const validOffsets = this.linePoints
        .map((p, i) => [p.type, i])
        .filter((p) => p[0] > 0)
        .map((p) => p[1])

      const oldOffset = this.offset
      while (this.offset == oldOffset) {
        this.offset =
          validOffsets[Math.floor(Math.random() * validOffsets.length)]
      }
    },
    startAnimation() {
      const restartAnim = () => {
        setTimeout(() => {
          this.drawInterval = setInterval(() => {
            this.lineProgress += 0.0075
            if (this.lineProgress >= 1) {
              clearInterval(this.drawInterval)
              this.squareMade = true
              setTimeout(() => {
                this.lineProgress = 0
                this.squareMade = false
                this.calculateNewOffset()
                restartAnim()
              }, 750)
            }
          }, 10)
        }, 1000)
      }
      restartAnim()
    },
  },
  watch: {
    playAnimation(new_val, old_val) {
      if (new_val && !old_val) {
        this.startAnimation()
      }
    },
  },
}
</script>

<style scoped>
.svg-container {
  width: 100%;
  min-height: 0px;
  flex-grow: 1;
  flex-shrink: 1;
  display: flex;
  justify-content: center;
}

.ball {
  cursor: pointer;
}

.ball.hidden {
  visibility: hidden;
}

.ball.normal {
  fill: var(--main-item-colour);
}

.ball.square {
  stroke: black;
}

.line {
  stroke: var(--red-colour);
  opacity: 0.5;
  stroke-width: 20;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
  fill: none;
}

.line.square {
  stroke: var(--yellow-colour);
}

.goal-rectangle {
  opacity: 0.5;
  transition: stroke 0.3s;
  stroke-width: 4;
  stroke: transparent;
}

.goal-rectangle.complete {
  stroke: var(--yellow-colour);
}

.key2 {
  fill: var(--green-colour);
}

.key3 {
  fill: var(--blue-colour);
}

.key4 {
  fill: var(--purple-colour);
}

.key5 {
  fill: var(--grey-colour);
}

.text {
  font-size: 24px;
}
</style>
