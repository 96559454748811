<template>
  <Dialog :allowModalClose="true" @modal_close="modalClose">
    <div class="undo-unlock-inner-container">
      <MultiSwitcher
        :currentStage="
          stageMap({
            main: ['intro', 'purchasing'],
            thanks: ['thanks'],
            error: ['error'],
          })
        "
        @switched="isPurchaseInProgress = false"
      >
        <template v-slot:main>
          <span
            v-if="rewardingType == 'first'"
            v-html="settings['introTextFirst']"
          ></span>
          <span
            v-else-if="rewardingType == 'all'"
            v-html="settings['introTextAll']"
          ></span>
        </template>
        <template v-slot:thanks>
          <span v-html="settings['thanksText']"></span>
        </template>
        <template v-slot:error>
          <span v-html="settings['errorText']"></span>
          <!-- thanks -->
        </template>
      </MultiSwitcher>
      <MultiSwitcher :currentStage="stageMap({ show: ['intro'] })">
        <template v-slot:show>
          <div class="controls-container">
            <Button @click="watchVideo">Watch video</Button>
          </div>
        </template>
      </MultiSwitcher>
      <div class="controls-container">
        <Button
          v-if="['intro', 'purchasing'].includes(stage)"
          @click="purchaseWrapper(PREMIUM_VERSION_ITEM)"
          :loading="isPurchaseInProgress"
          :disabled="!productsKnown.includes(PREMIUM_VERSION_ITEM)"
        >
          <span v-if="productsKnown.includes(PREMIUM_VERSION_ITEM)">
            {{ settings['ctaText'].replace('{PRICE}', premiumVersionPrice) }}
          </span>
          <span v-else>Upgrade temporarily unavailable</span>
        </Button>
        <Button
          v-if="['thanks', 'error'].includes(stage)"
          @click="continueButton($event)"
          :loading="isPurchaseInProgress"
        >
          Continue
        </Button>
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import MultiSwitcher from '@/components/MultiSwitcher.vue'
import Button from '@/components/Button.vue'

import { config } from '@/lib/dynamicConfig.js'
import purchaseMixin from '@/lib/purchaseMixin.js'
import popupAnalyticsMixin from '@/lib/popupAnalyticsMixin.js'

export default {
  name: 'UpgradeDialogUndoReward',
  components: { Dialog, MultiSwitcher, Button },
  mixins: [purchaseMixin, popupAnalyticsMixin],
  data() {
    return {
      name: 'rewarded_dialog',
      stage: undefined,
      isPurchaseInProgress: false,
      itemPurchased: undefined,
      PREMIUM_VERSION_ITEM: config.PREMIUM_VERSION_ITEM,
      rewardingType: config.LEVEL_DEF.unlockUndosWithRewarded,
      settings: config.REWARDED_UPGRADE_TEXT,
      productsOffered: [config.PREMIUM_VERSION_ITEM],
    }
  },
  created() {
    if (this.anyPurchaseMade) {
      this.stage = 'thanks'
    } else {
      this.stage = 'intro'
    }
  },
  mounted() {
    this.logOpen(this.additionalLogVars)
  },
  computed: {
    anyPurchaseMade() {
      return this.$store.getters.anyPurchaseMade
    },
    premiumVersionPrice() {
      return this.$store.getters.getPriceForItem(this.PREMIUM_VERSION_ITEM)
    },
    additionalLogVars() {
      return { rewarding_type: this.rewardingType }
    },
  },
  methods: {
    stageMap(stageMap) {
      return (
        Object.keys(stageMap).filter((k) =>
          stageMap[k].includes(this.stage)
        )[0] || ''
      )
    },
    modalClose() {
      this.logClick('modal_close', this.additionalLogVars)
      this.logClose(this.additionalLogVars)
      this.$emit('close')
    },
    watchVideo() {
      this.logClick('watch_video', this.additionalLogVars)
      this.$emit('watch_video')
      this.logClose(this.additionalLogVars)
      this.$emit('close')
    },
    continueButton(event) {
      this.logClick('continue', this.additionalLogVars)
      this.logClose(this.additionalLogVars)
      this.$emit('close', event)
    },
  },
  watch: {
    stage(new_val, old_val) {
      if (new_val == 'remind') {
        this.logClose(this.additionalLogVars)
        this.$emit('close')
      } else if (!(old_val === undefined)) {
        this.logTransition(this.additionalLogVars)
      }
    },
  },
}
</script>

<style scoped>
.undo-unlock-inner-container {
  width: 100%;
  text-align: center;
}

.controls-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.undo-unlock-inner-container p:first-of-type {
  margin-top: 0px;
}
</style>
