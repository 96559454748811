<template>
  <Dialog>
    <div class="welcome-upgrade-inner-container">
      <MultiSwitcher
        :currentStage="
          stageMap({
            intro: ['intro', 'purchasing'],
            thanks: ['thanks'],
            error: ['error'],
          })
        "
        @switched="isPurchaseInProgress = false"
      >
        <template v-slot:intro>
          <span v-html="settings['introText']"></span>
        </template>
        <template v-slot:thanks>
          <span v-html="settings['thanksText']"></span>
        </template>
        <template v-slot:error>
          <span v-html="settings['errorText']"></span>
        </template>
      </MultiSwitcher>
      <div class="controls-container">
        <Button
          v-if="['intro', 'purchasing', 'remind'].includes(stage)"
          @click="purchase(PREMIUM_VERSION_ITEM)"
          :loading="isPurchaseInProgress"
          :disabled="!productsKnown.includes(PREMIUM_VERSION_ITEM)"
        >
          <span v-if="productsKnown.includes(PREMIUM_VERSION_ITEM)">
            {{ settings['ctaText'].replace('{PRICE}', premiumVersionPrice) }}
          </span>
          <span v-else>Upgrade temporarily unavailable</span>
        </Button>
        <Button
          v-if="['thanks', 'error'].includes(stage)"
          @click="continueButton($event)"
          :loading="isPurchaseInProgress"
        >
          Continue
        </Button>
      </div>
      <MultiSwitcher :currentStage="stageMap({ show: ['intro'] })">
        <template v-slot:show>
          <div class="controls-container">
            <Button @click="notNow($event)">Not now</Button>
          </div>
        </template>
      </MultiSwitcher>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog.vue'
import MultiSwitcher from '@/components/MultiSwitcher.vue'
import Button from '@/components/Button.vue'

import purchaseMixin from '@/lib/purchaseMixin.js'
import popupAnalyticsMixin from '@/lib/popupAnalyticsMixin.js'
import { config } from '@/lib/dynamicConfig.js'

export default {
  name: 'UpgradeDialogWelcome',
  components: { Dialog, MultiSwitcher, Button },
  mixins: [purchaseMixin, popupAnalyticsMixin],
  data() {
    return {
      isPurchaseInProgress: false,
      name: 'welcome_upgrade',
      stage: 'intro',
      itemPurchased: undefined,
      settings: config.WELCOME_UPGRADE_TEXT,
      PREMIUM_VERSION_ITEM: config.PREMIUM_VERSION_ITEM,
      productsOffered: [config.PREMIUM_VERSION_ITEM],
    }
  },
  mounted() {
    this.logOpen()
  },
  computed: {
    premiumVersionPrice() {
      return this.$store.getters.getPriceForItem(this.PREMIUM_VERSION_ITEM)
    },
    productsAvailable() {
      return this.productsKnown.includes(this.PREMIUM_VERSION_ITEM)
        ? [this.PREMIUM_VERSION_ITEM]
        : []
    },
    productPrices() {
      return this.productsAvailable.map((x) =>
        this.$store.getters.getPriceForItem(x)
      )
    },
  },
  methods: {
    debug() {
      debugger
    },
    stageMap(stageMap) {
      return (
        Object.keys(stageMap).filter((k) =>
          stageMap[k].includes(this.stage)
        )[0] || ''
      )
    },
    continueButton(event) {
      this.logClick('continue')
      this.logClose()
      this.$emit('close', event)
    },
    notNow(event) {
      this.logClick('not_now')
      this.logClose()
      this.$emit('close', event)
    },
  },
  watch: {
    stage(new_val) {
      if (new_val == 'remind') {
        this.logClose()
        this.$emit('close')
      } else {
        this.logTransition()
      }
    },
  },
}
</script>

<style scoped>
.welcome-upgrade-inner-container {
  width: 100%;
  text-align: center;
}

.controls-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>

<style>
.welcome-upgrade-inner-container p:first-of-type {
  margin-top: 0px;
}
</style>
