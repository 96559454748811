import levelDefs from '@/assets/levels.jsonc'
import { config } from '@/lib/dynamicConfig.js'
import { Storage } from '@capacitor/storage'
import { isJson, isString } from '@/lib/helpers.js'

export const state = {
  currentLevel: -1,
  maxLevel: 0,
  levels: [],
  completedLevels: [],
}

export const mutations = {
  SET_LEVELS(state, val) {
    state.levels = val
  },
  SET_CURRENT_LEVEL(state, val) {
    state.currentLevel = val
  },
  SET_MAX_LEVEL(state, val) {
    state.maxLevel = val
    Storage.set({ key: 'maxLevel', value: String(val) })
  },
  SET_COMPLETED_LEVELS(state, val) {
    state.completedLevels = val
    Storage.set({ key: 'completedLevels', value: JSON.stringify(val) })
  },
}

export const actions = {
  /*async initialise_levels({ commit }) {
    const { value } = await Storage.get({ key: 'maxLevel' })
    const maxLevel = isJson(value) ? JSON.parse(value) : null
    commit('SET_MAX_LEVEL', isInt(maxLevel) ? maxLevel : 0)
  },*/

  async initialise_levels({ commit }) {
    // We get completedLevels from Storage
    const { value } = await Storage.get({ key: 'completedLevels' })
    let completedLevels = isJson(value) ? JSON.parse(value) : null
    if (completedLevels === null) {
      completedLevels = []
    } else {
      completedLevels = completedLevels.every((x) => isString(x))
        ? completedLevels
        : []
    }
    commit('SET_COMPLETED_LEVELS', completedLevels)

    const LEVEL_ORDER = config.LEVEL_DEF.levels
    let revisedLevelOrder = []
    completedLevels.forEach((k) => {
      const index = LEVEL_ORDER.findIndex((l) => l.key == k)
      if (index != -1) {
        revisedLevelOrder.push(LEVEL_ORDER[index])
      }
    })

    revisedLevelOrder = revisedLevelOrder.concat(
      LEVEL_ORDER.filter((l) => !completedLevels.includes(l.key))
    )

    const levels = revisedLevelOrder.map((k) => {
      const level = {
        ...k,
        ...levelDefs[k.key],
      }
      return level
    })

    commit('SET_LEVELS', levels)
    commit('SET_MAX_LEVEL', completedLevels.length)
    //commit('SET_MAX_LEVEL', levels.length - 1)
  },

  mark_level_as_completed({ state, commit }, levelKey) {
    if (!state.completedLevels.includes(levelKey)) {
      commit('SET_COMPLETED_LEVELS', [...state.completedLevels, levelKey])
    }
  },
  set_current_level({ commit, state }, level) {
    commit('SET_CURRENT_LEVEL', level)
    if (level > state.maxLevel) {
      commit('SET_MAX_LEVEL', level)
    }
  },
}

export const getters = {
  levels: (state) => state.levels,
  completedLevels: (state) => state.completedLevels,
  currentLevel: (state) => state.currentLevel,
  maxLevel: (state) => state.maxLevel,
  getLevelDefForLevel: (state) => (level) => state.levels[level],
  currentLevelDef: (state, getters) =>
    getters.getLevelDefForLevel(getters.currentLevel),
}
