<template>
  <a
    class="button"
    :class="{ throb: !disabled, disabled: disabled }"
    @click="clicked"
    v-ripple="!disabled"
  >
    <div
      :style="{ opacity: !loading ? 1 : 0 }"
      class="transition-opacity text-small"
    >
      <slot></slot>
    </div>
    <LoadingSpinner
      class="absolute-centre transition-opacity"
      :style="{ opacity: loading ? 1 : 0 }"
      width="3rem"
      height="3rem"
    />
  </a>
</template>

<script>
import LoadingSpinner from '@/components/LoadingSpinner.vue'

export default {
  name: 'Button',
  components: { LoadingSpinner },
  props: {
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },
  methods: {
    clicked(event) {
      if (!this.loading && !this.disabled) {
        this.$emit('click', event)
      }
    },
  },
}
</script>

<style scoped>
.button {
  margin-top: 1.5rem;
  max-width: 20em;
  width: 90%;
  display: inline-block;
  padding: 1em;
  text-align: center;
  border: 1px solid var(--main-item-colour);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease, color 0.5s ease;
  position: relative;
}

.button.disabled {
  filter: brightness(0.6);
}

.button.throb {
  animation-name: button-throb-animation;
  animation-duration: 0.75s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: center;
}

@keyframes button-throb-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(224, 168, 0, 0.3);
  }
}

/*.transition-opacity {
  transition: opacity 0.5s;
}*/

.absolute-centre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
