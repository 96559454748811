import { Capacitor } from '@capacitor/core'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, setUserProperties } from 'firebase/analytics'
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'
import {
  setDefaultConfigVals,
  updateConfig,
  config,
} from '@/lib/dynamicConfig.js'
import { AnalyticsLogger } from '@/lib/analytics.js'
import { initStore } from '@/lib/purchases.js'
import { initializeAdMob } from '@/lib/admob.js'
import consoleLog from '@/lib/consoleLog.js'

const isMobile = ['ios', 'android'].includes(Capacitor.getPlatform())

class FakeAnalytics {
  logEvent(name, params) {
    consoleLog(`FakeAnalytics: logEvent(${name}):`, params)
  }

  logEventFB(name, params, val) {
    consoleLog(`FakeAnalytics: logEventFB(${name}):`, params, val)
  }

  setUserProperty(name, value) {
    consoleLog(`FakeAnalytics: setUserProperty(${name}): ${value}`)
  }

  setScreenName(screenName, className) {
    consoleLog(
      `FakeAnalytics: setScreenName(): screenName = ${screenName}, className = ${className}`
    )
  }
}

class WebAnalytics {
  constructor(app) {
    this.analytics = getAnalytics(app)
  }

  logEvent(name, params) {
    logEvent(this.analytics, name, params)
    consoleLog(`WebAnalytics: logEvent(${name}):`, params)
  }

  logEventFB(name, params, val) {
    consoleLog(`WebAnalytics: (not logged) logEventFB(${name}):`, params, val)
  }

  setUserProperty(name, value) {
    setUserProperties(this.analytics, { [name]: value })
    consoleLog(`WebAnalytics: setUserProperty(${name}): ${value}`)
  }

  setScreenName(screenName, className) {
    logEvent(this.analytics, 'screen_view', {
      firebase_screen: screenName,
      firebase_screen_class: className,
    })
    consoleLog(
      `WebAnalytics: setScreenName(): screenName = ${screenName}, className = ${className}`
    )
  }
}

class MobileAnalytics {
  logEvent(name, params) {
    FirebaseAnalytics.logEvent({ name: name, params: params })
    consoleLog(`MobileAnalytics: logEvent(${name}):`, params)
  }

  logEventFB(name, params, val) {
    if (!(window.facebookConnectPlugin === undefined)) {
      consoleLog(`MobileAnalytics: logEventFB(${name}):`, params, val)
      window.facebookConnectPlugin.logEvent(
        name,
        params,
        val,
        (x) => {
          consoleLog(`MobileAnalytics: logEventFB(${name}) SUCCESS: ${x}`)
        },
        (x) => {
          consoleLog(`MobileAnalytics: logEventFB(${name}) FAILED: ${x}`)
        }
      )
    }
  }

  setUserProperty(name, value) {
    FirebaseAnalytics.setUserProperty({ name: name, value: value })
    consoleLog(`MobileAnalytics: setUserProperty(${name}): ${value}`)
  }

  setScreenName(screenName, className) {
    FirebaseAnalytics.setScreenName({
      screenName: screenName,
      nameOverride: className,
    })
    consoleLog(
      `MobileAnalytics: setScreenName(): screenName = ${screenName}, className = ${className}`
    )
  }
}

let logger

function initialiseServices() {
  return new Promise((resolve) => {
    let analyticsWrapper

    const firebaseConfig = {
      apiKey: 'AIzaSyBFH0hRbRDRzheAC7OHvmXt3pxYTE297Ao',
      authDomain: 'squarepusher-dev.firebaseapp.com',
      projectId: 'squarepusher-dev',
      storageBucket: 'squarepusher-dev.appspot.com',
      messagingSenderId: '699071621453',
      appId: '1:699071621453:web:5a47745215e06d3939af04',
      measurementId: 'G-811MC32RNC',
    }

    // Initialize Firebase
    consoleLog('initialising firebase')
    const app = initializeApp(firebaseConfig)

    // initialise remote config
    const remoteConfig = getRemoteConfig()
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000
    //remoteConfig.settings.minimumFetchIntervalMillis = 0
    setDefaultConfigVals()

    consoleLog('fetching remoteConfig')
    new Promise((resolve, reject) => {
      fetchAndActivate(remoteConfig).then(resolve)
      setTimeout(reject, 1000 * 10)
    })
      .catch(() => {
        consoleLog('remoteConfig fetch timed out after 10 seconds')
        return updateConfig()
      })
      .then(updateConfig)
      .then(() => {
        consoleLog('remoteConfig fetched and updated')
        return Promise.all([
          new Promise((resolve) => {
            // Initialise analytics
            consoleLog('initialising analytics')
            if (config.FAKE_ANALYTICS) {
              analyticsWrapper = new FakeAnalytics()
            } else if (!isMobile) {
              consoleLog('Initialising WebAnalytics')
              analyticsWrapper = new WebAnalytics(app)
            } else {
              consoleLog('Initialising MobileAnalytics')
              analyticsWrapper = new MobileAnalytics()
            }

            logger = new AnalyticsLogger(analyticsWrapper)

            // Initialise in-app purchases
            initStore()
            resolve()
          }),
          initializeAdMob(),
        ])
      })
      .then(resolve)
  })
}

export { initialiseServices, logger, FakeAnalytics }
