<template>
  <div
    class="svg-container"
    :style="svgContainerWidthStyle"
    ref="svg-container"
  >
    <!-- 
      Using height="100%" on the svg element works on Chrome + Firefox, but not
      on stupid Safari. So we set its height explicitly to match the container
      with the svgHeightStyle.
     -->
    <svg
      version="1.1"
      width="100%"
      :viewBox="`-35 -35 ${xDim * 100 + 70} ${yDim * 100 + 70}`"
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      id="svg-main"
      :style="svgHeightStyle"
    >
      <template v-for="g in goalCoordinates">
        <rect
          :key="g.id"
          :id="g.id"
          :x="g.x"
          :y="g.y"
          width="90"
          height="90"
          rx="10"
          ry="10"
          class="goal-rectangle"
          :class="{
            key2: g.type == 2,
            key3: g.type == 3,
            key4: g.type == 4,
            key5: g.type == 5,
            complete: completeGoals.map((x) => x.id).includes(g.id),
          }"
        />
      </template>
      <template v-for="b in ballCoordinates">
        <circle
          :key="b.id"
          :id="b.id"
          :cx="b.x"
          :cy="b.y"
          r="35"
          class="ball"
          :class="{
            hidden: b.type == 0 || hideAllBalls,
            normal: b.type == 1,
            key2: b.type == 2,
            key3: b.type == 3,
            key4: b.type == 4,
            key5: b.type == 5,
            [b.id]: true,
          }"
          @mousedown="mousedownOnBall(b, $event)"
          @touchstart="mousedownOnBall(b, $event)"
        />
        <circle
          v-if="colourblindModeEnabled && b.type >= 2"
          :key="`${b.id}_bg`"
          :id="b.id"
          :cx="b.x"
          :cy="b.y"
          class="ball"
          :class="{ [b.id]: true }"
          r="20"
          fill="rgba(0,0,0,0.5)"
          @mousedown="mousedownOnBall(b, $event)"
          @touchstart="mousedownOnBall(b, $event)"
        />
        <text
          v-if="colourblindModeEnabled && b.type >= 2"
          text-anchor="middle"
          dominant-baseline="central"
          :key="`${b.id}_text`"
          :x="b.x"
          :y="b.y"
          class="noselect ball text"
          :class="{ [b.id]: true }"
          fill="white"
          @mousedown="mousedownOnBall(b, $event)"
          @touchstart="mousedownOnBall(b, $event)"
        >
          {{ b.type - 1 }}
        </text>
      </template>

      <template v-if="colourblindModeEnabled">
        <template v-for="g in goalCoordinates">
          <rect
            :key="`${g.id}_bg`"
            :x="g.x"
            :y="g.y + 92"
            width="90"
            height="35"
            rx="10"
            ry="10"
            fill="rgba(0,0,0,0.5)"
          />
          <text
            text-anchor="middle"
            dominant-baseline="central"
            :key="`${g.id}_text`"
            :x="g.x + 45"
            :y="g.y + 109.5"
            fill="white"
            class="noselect text"
          >
            {{ g.type - 1 }}
          </text>
        </template>
      </template>
      <path
        v-if="linePath.length > 0"
        :d="linePath"
        class="line"
        :class="{ square: squareMade }"
      />
    </svg>
  </div>
</template>

<script>
import gsap from 'gsap'
import {
  canPointsBeCombined,
  doPointsMakeASquare,
  findAllPointsBetween,
} from '@/lib/squares.js'
import { playPop, playTarget } from '@/lib/sound.js'
import { logger } from '@/lib/firebase.js'
import consoleLog from '@/lib/consoleLog.js'
import { config } from '@/lib/dynamicConfig.js'

export default {
  name: 'GameContainer',
  data() {
    return {
      ballGrid: [],
      goalGrid: [],
      linePoints: [],
      linePointsLog: [],
      lineEnd: undefined,
      squareMade: false,
      drawing: false,
      svgPoint: undefined,
      overElementId: undefined,
      hideAllBalls: false,
      moveCount: 0,
      svgHeightStyle: undefined,
    }
  },
  props: {
    resetGame: Number,
    undoMove: Number,
    level: Number,
  },
  computed: {
    svgContainerWidthStyle() {
      return {
        /*
        Viewbox width is 100*xDim + 70 
        */
        width: `clamp(0%, calc(5rem * ${this.xDim} + 5rem * (7/10)), 100%)`,
      }
    },
    yDim() {
      return this.ballGrid.length
    },
    xDim() {
      if (this.yDim > 0) {
        return this.ballGrid[0].length
      } else {
        return 0
      }
    },
    ballCoordinates() {
      let i = 0
      return this.ballGrid.flatMap((row, yIndex) =>
        this.ballGrid[yIndex].map((val, xIndex) => {
          return {
            index: i++,
            id: `ball-${val}_${xIndex}_${yIndex}`,
            gridX: xIndex,
            gridY: yIndex,
            x: 100 * xIndex + 50,
            y: 100 * yIndex + 50,
            type: val,
            goal: this.goalGrid[yIndex][xIndex],
          }
        })
      )
    },
    goalCoordinates() {
      let i = 0
      return this.goalGrid
        .flatMap((row, yIndex) =>
          this.goalGrid[yIndex].map((val, xIndex) => {
            return {
              index: i++,
              id: `goal-${val}_${xIndex}_${yIndex}`,
              gridX: xIndex,
              gridY: yIndex,
              x: 100 * xIndex + 5,
              y: 100 * yIndex + 5,
              type: val,
            }
          })
        )
        .filter((x) => x.type > 0)
    },
    completeGoals() {
      if (this.ballGrid.length == 0) {
        return []
      }
      return this.goalCoordinates.filter(
        (g) => this.ballGrid[g.gridY][g.gridX] == g.type
      )
    },
    linePath() {
      let d = ''
      if (this.linePoints.length > 0) {
        d =
          d +
          this.linePoints.reduce((s, p, i) => {
            return s + `${i == 0 ? 'M' : 'L'} ${p.x} ${p.y}`
          }, '')
      }
      if (!(this.lineEnd === undefined) && !this.squareMade) {
        const lineEndX = Math.min(Math.max(this.lineEnd.x, 0), this.xDim * 100)
        const lineEndY = Math.min(Math.max(this.lineEnd.y, 0), this.yDim * 100)
        d = d + ` L ${lineEndX} ${lineEndY}`
      }
      return d
    },
    canReset() {
      return this.linePointsLog.length > 0
    },
    canUndo() {
      return this.linePointsLog.length > 0
    },
    colourblindModeEnabled() {
      return this.$store.getters.colourblindModeEnabled
    },
    levelDef() {
      return this.$store.getters.getLevelDefForLevel(this.level)
    },
  },
  mounted() {
    this.svgPoint = document.getElementById('svg-main').createSVGPoint()
    this.setupLevel()
    window.addEventListener('resize', this.resize)
    this.resize()
    this.showInstructionIfNeeded()

    logger.levelStart({
      level_id: this.level,
      level_name: this.levelDef.key,
      level_undos: this.levelDef.lives,
      undos_unlocked: this.$store.getters.isUndoUnlocked,
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    setupLevel() {
      this.ballGrid = this.levelDef.startGrid.map((r) => [...r])
      this.goalGrid = this.levelDef.goalGrid.map((r) => [...r])
      this.$store.commit('SET_CAN_RESET', false)
      this.$store.commit('SET_CAN_UNDO', false)
    },
    mousedownOnBall(ball, event) {
      consoleLog('mousedownOnBall')
      consoleLog(ball)
      consoleLog(event)
      this.drawing = true
      this.squareMade = false
      this.linePoints.push(ball)
      window.addEventListener('mousemove', this.mousemove)
      window.addEventListener('touchmove', this.mousemove)
      window.addEventListener('mouseup', this.mouseup)
      window.addEventListener('touchend', this.mouseup)
    },
    mouseup() {
      consoleLog('mouseup')
      this.removeAllEventListeners()
      setTimeout(
        () => {
          this.lineEnd = undefined
          this.linePoints = []
          this.drawing = false
        },
        config.SCREENSHOT_MODE ? 2000 : 0
      )
    },
    mousemove(event) {
      const clientX =
        event.type == 'touchmove'
          ? event.changedTouches[0].clientX
          : event.clientX
      const clientY =
        event.type == 'touchmove'
          ? event.changedTouches[0].clientY
          : event.clientY
      this.lineEnd = this.mapScreenCoordsToSvg(clientX, clientY)

      const overElement = document.elementFromPoint(clientX, clientY)
      if (
        overElement.classList.contains('ball') &&
        this.overElementId != overElement.id
      ) {
        this.overElementId = overElement.id
        const index = this.ballCoordinates.findIndex(
          (x) => x.id == this.overElementId
        )
        if (index != -1) {
          this.mouseenterBall(this.ballCoordinates[index])
        }
      } else if (this.overElementId != overElement.id) {
        this.overElementId = undefined
      }
    },
    removeAllEventListeners() {
      window.removeEventListener('mousemove', this.mousemove)
      window.removeEventListener('touchmove', this.mousemove)
      window.removeEventListener('mouseup', this.mouseup)
      window.removeEventListener('touchend', this.mouseup)
    },
    mouseenterBall(ball) {
      if (!this.drawing || this.squareMade) {
        return
      }

      if (doPointsMakeASquare([...this.linePoints, ball])) {
        consoleLog('SQUARE!')
        this.addLinePoint(ball)
        this.linePoints.push(ball)
        this.squareMade = true
        this.squareMadeAnimation([...this.linePoints])
        const moveId = this.makeMoveId([...this.linePoints])
        logger.makeMove(moveId)
      } else if (canPointsBeCombined([...this.linePoints, ball])) {
        this.addLinePoint(ball)
      }
    },
    // Adds a new points to the line and any missing points on the line
    // inbetween the last point and the new one
    addLinePoint(newPoint) {
      if (this.linePoints.length == 0) {
        this.linePoints.push(newPoint)
      } else {
        const pointsToAdd = findAllPointsBetween(
          this.linePoints[this.linePoints.length - 1],
          newPoint,
          this.ballCoordinates
        )
        const existingIds = this.linePoints.map((x) => x.id)
        pointsToAdd.forEach((p) => {
          if (!existingIds.includes(p.id)) {
            this.linePoints.push(p)
          }
        })
      }
    },
    mapScreenCoordsToSvg(x, y) {
      this.svgPoint.x = x
      this.svgPoint.y = y
      const svgP = this.svgPoint.matrixTransform(
        document.getElementById('svg-main').getScreenCTM().inverse()
      )
      return { x: svgP.x, y: svgP.y }
    },
    squareMadeAnimation(linePoints) {
      this.linePointsLog.push([...linePoints])
      playPop()

      const tl = gsap.timeline({ repeat: 0 })
      tl.fromTo(
        linePoints.map((x) => '#' + x.id),
        {
          scale: 1.2,
          transformOrigin: '50% 50%',
        },
        {
          scale: 1,
          duration: 0.3,
          ease: 'bounce.out',
          onComplete: () => {
            consoleLog('squareMadeAnimation onComplete')
          },
          clearProps: 'all',
        }
      )

      setTimeout(
        () => {
          this.permuteAnimation(linePoints, tl)
        },
        config.SCREENSHOT_MODE ? 2000 : 0
      )
    },
    permuteAnimation(linePoints, tl) {
      if (tl === undefined) {
        tl = gsap.timeline({ repeat: 0 })
      }
      let i

      for (i = 1; i < linePoints.length; i++) {
        tl.to(
          //'#' + linePoints[i - 1].id,
          '.' + linePoints[i - 1].id,
          {
            x: linePoints[i].x - linePoints[i - 1].x,
            y: linePoints[i].y - linePoints[i - 1].y,
            duration: 0.3,
            clearProps: 'all',
            onComplete:
              i == 1
                ? () => {
                    this.permuteBalls([...linePoints])
                    this.moveCount += 1
                  }
                : undefined,
          },
          'switch'
        )
      }
    },
    permuteBalls(linePoints) {
      let oldPoints = []
      let newPoints = []
      let i

      for (i = 1; i < linePoints.length; i++) {
        oldPoints.push(linePoints[i])
        newPoints.push(linePoints[i - 1])
      }

      let positionsCopy = this.ballGrid.map((x) => [...x])
      oldPoints.forEach((v, i) => {
        positionsCopy[v.gridY][v.gridX] =
          this.ballGrid[newPoints[i].gridY][newPoints[i].gridX]
      })
      this.ballGrid = positionsCopy
    },
    makeMoveId(linePoints) {
      const gridX = linePoints.map((x) => x.gridX)
      const gridY = linePoints.map((x) => x.gridY)

      let moveId = `C${Math.min(...gridX)},${Math.min(...gridY)}_`
      moveId += `C${Math.max(...gridX)},${Math.max(...gridY)}_`

      const minGridYlinePoints = linePoints.filter(
        (l) => l.gridY == Math.min(...gridY)
      )

      let direction = ''
      for (let i = 0; i < minGridYlinePoints.length - 1; i++) {
        const diff =
          minGridYlinePoints[i].gridX - minGridYlinePoints[i + 1].gridX
        if (diff != 0) {
          direction = diff < 0 ? 'CW' : 'ACW'
          break
        }
      }
      moveId += direction
      return moveId
    },
    showInstructionIfNeeded() {
      this.$store.dispatch('show_instruction_if_needed', {
        currentLevel: this.level,
        currentLevelKey: this.$store.getters.levels[this.level].key,
        moveCount: this.moveCount,
        ballGrid: this.ballGrid,
      })
    },
    resize() {
      const height = this.$refs['svg-container'].getBoundingClientRect().height
      this.svgHeightStyle = { height: height + 'px' }
    },
  },
  watch: {
    completeGoals(new_val, old_val) {
      consoleLog('completeGoals:', new_val, old_val)
      const oldIds = old_val.map((x) => x.id)

      const tl = gsap.timeline({ repeat: 0 })

      new_val
        .filter((x) => !oldIds.includes(x.id))
        .forEach((g, i) => {
          consoleLog('animating:', g.id)
          if (i == 0) {
            playTarget()
          }

          tl.fromTo(
            '#' + g.id,
            {
              scale: 1.1,
              transformOrigin: 'center center',
            },
            {
              scale: 1,
              duration: 0.3,
              ease: 'bounce.out',
              onComplete:
                i == 0
                  ? () => {
                      if (
                        this.completeGoals.length == this.goalCoordinates.length
                      ) {
                        this.$emit('level_complete')
                        this.$store.dispatch(
                          'mark_level_as_completed',
                          this.levelDef.key
                        )
                        logger.levelEnd()
                      }
                    }
                  : undefined,
            },
            'bounce'
          )
        })
    },
    resetGame() {
      if (this.moveCount > 0) {
        logger.resetMoves()
        this.linePointsLog = []
        this.$store.commit('SET_HAS_RESET', true)
        const tl = gsap.timeline({ repeat: 0 })

        tl.to('.ball', {
          scale: 0,
          duration: 0.5,
          transformOrigin: 'center center',
          onComplete: () => {
            this.hideAllBalls = true
            this.setupLevel()
            this.$nextTick(() => {
              this.hideAllBalls = false
              gsap.fromTo(
                '.ball',
                {
                  scale: 0,
                  transformOrigin: 'center center',
                },

                {
                  scale: 1,
                  duration: 0.5,
                  clearProps: 'all',
                  onComplete: () => {
                    this.moveCount = 0
                  },
                }
              )
            })
          },
        })
      }
    },
    undoMove() {
      consoleLog('undo')
      if (this.linePointsLog.length > 0) {
        let refreshedLinePoints = []
        const lastMove = this.linePointsLog.pop()
        const lastMoveId = this.makeMoveId(lastMove)
        lastMove.forEach((p) => {
          const index = this.ballCoordinates.findIndex(
            (b) => b.gridX == p.gridX && b.gridY == p.gridY
          )
          refreshedLinePoints.push(this.ballCoordinates[index])
        })
        this.permuteAnimation(refreshedLinePoints.reverse())
        this.$emit('move_undone')
        this.$store.commit('SET_HAS_UNDONE', true)
        logger.undoMove(lastMoveId)
      }
    },
    moveCount() {
      this.showInstructionIfNeeded()
    },
    canReset(new_val) {
      this.$store.commit('SET_CAN_RESET', new_val)
    },
    canUndo(new_val) {
      this.$store.commit('SET_CAN_UNDO', new_val)
    },
  },
}
</script>

<style scoped>
/*.svg-container {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
}*/

.svg-container {
  height: 100%;
}

.ball {
  cursor: pointer;
}

.ball.hidden {
  visibility: hidden;
}

.ball.normal {
  fill: var(--main-item-colour);
}

.ball.square {
  stroke: black;
}

.line {
  stroke: var(--red-colour);
  opacity: 0.5;
  stroke-width: 20;
  stroke-linecap: round;
  stroke-linejoin: round;
  pointer-events: none;
  fill: none;
}

.line.square {
  stroke: var(--yellow-colour);
}

.goal-rectangle {
  opacity: 0.5;
  transition: stroke 0.3s;
  stroke-width: 4;
  stroke: transparent;
}

.goal-rectangle.complete {
  stroke: var(--yellow-colour);
}

.key2 {
  fill: var(--green-colour);
}

.key3 {
  fill: var(--blue-colour);
}

.key4 {
  fill: var(--purple-colour);
}

.key5 {
  fill: var(--grey-colour);
}

.text {
  font-size: 24px;
}
</style>
