<template>
  <div class="fullscreen-container">
    <TopSection
      @next_level="nextLevel"
      @prev_level="prevLevel"
      @home="homeScreen"
    />
    <transition name="fade">
      <div v-if="showBlackScreen" class="black-screen"></div>
    </transition>
    <div class="game-container-outer-wrapper" :style="slideVarStyle">
      <transition name="slide">
        <!--<transition
        @before-enter="levelBeforeEnter"
        @enter="levelEnter"
        @leave="levelLeave"
      >-->
        <div
          class="game-container-inner-wrapper"
          :key="`${currentLevel}_${showInterstitialAd}`"
        >
          <WelcomeScreen
            v-if="currentLevel == -1 && !showInterstitialAd"
            @start="nextLevel"
            @continue_from_max="continueFromMaxLevel"
          />
          <GameContainer
            v-if="
              currentLevel >= 0 &&
              currentLevel < levelCount &&
              !showInterstitialAd
            "
            :level="currentLevel"
            :resetGame="resetGame"
            :undoMove="undoMove"
            @level_complete="levelComplete"
            @move_undone="moveUndone"
          />
          <EndOfLevelsScreen
            v-if="currentLevel == levelCount && !showInterstitialAd"
          />
          <InterstitialAd
            v-if="showInterstitialAd"
            @done="showInterstitialAdDone"
          />
        </div>
      </transition>
    </div>
    <transition name="fade">
      <CompanyText v-if="currentLevel == -1" />
    </transition>
    <BottomSection
      :style="{
        opacity:
          currentLevel == -1 || currentLevel == levelCount || showBlackScreen
            ? 0
            : 1,
      }"
      :lives="lives"
      :undosUsed="undoCount"
      @reset="reset"
      @undo="undo"
    />
    <LevelComplete
      v-if="showLevelComplete"
      @next_level="nextLevel"
      @close="showLevelComplete = false"
    />
    <transition name="fade">
      <RewardedAd v-if="showRewardedAd" @done="showRewardedAdDone" />
    </transition>
    <transition name="popup">
      <InstructionPopup v-if="showInstructionPopup" />
    </transition>
    <transition name="popup">
      <SettingsDialog v-if="showSettingsPopup" />
    </transition>
    <transition name="popup">
      <UpgradeDialogWelcome
        v-if="$store.getters.showUpgradeDialog == 'welcome_upgrade'"
        @close="$store.commit('SET_SHOW_UPGRADE_DIALOG', undefined)"
      />
      <UpgradeDialogInterstitial
        v-if="$store.getters.showUpgradeDialog == 'interstitial'"
        @close="$store.commit('SET_SHOW_UPGRADE_DIALOG', undefined)"
      />
      <UpgradeDialogUndoReward
        v-if="$store.getters.showUpgradeDialog == 'undo_reward'"
        @close="$store.commit('SET_SHOW_UPGRADE_DIALOG', undefined)"
        @watch_video="showRewardedAd = true"
      />
    </transition>
    <transition name="popup">
      <BackdoorDialog v-if="showBackdoorDialog" />
    </transition>
    <transition name="popup">
      <RatingsDialog
        v-if="!(showRatingsDialog === undefined)"
        :variant="showRatingsDialog"
      />
    </transition>
    <transition name="popup">
      <ClearProgressDialog v-if="!(showClearProgressDialog === undefined)" />
    </transition>
  </div>
</template>

<script>
import TopSection from '@/components/TopSection.vue'
import GameContainer from '@/components/GameContainer.vue'
import BottomSection from '@/components/BottomSection.vue'
import CompanyText from '@/components/CompanyText.vue'
import LevelComplete from '@/views/LevelComplete.vue'
import WelcomeScreen from '@/views/WelcomeScreen.vue'
import EndOfLevelsScreen from '@/views/EndOfLevelsScreen.vue'
import InstructionPopup from '@/components/InstructionPopup.vue'
import SettingsDialog from '@/components/SettingsDialog.vue'
import InterstitialAd from '@/components/InterstitialAd.vue'
import RewardedAd from '@/components/RewardedAd.vue'
import UpgradeDialogWelcome from '@/components/UpgradeDialogWelcome.vue'
import UpgradeDialogInterstitial from '@/components/UpgradeDialogInterstitial.vue'
import UpgradeDialogUndoReward from '@/components/UpgradeDialogUndoReward.vue'
/*import UpgradeDialogBegging from '@/components/UpgradeDialogBegging.vue'*/
import BackdoorDialog from '@/components/BackdoorDialog.vue'
import RatingsDialog from '@/components/RatingsDialog.vue'
import ClearProgressDialog from '@/components/ClearProgressDialog.vue'

import { playWin, stopMusic, startMusic } from '@/lib/sound.js'
import { config } from '@/lib/dynamicConfig.js'
import { logger } from '@/lib/firebase.js'
import { isAndroid, isiOS, devMode } from '@/lib/helpers.js'
import consoleLog from '@/lib/consoleLog.js'

import { AdMob } from '@capacitor-community/admob'
import { interstitialAdOptions, rewardedAdOptions } from '@/lib/admob.js'

export default {
  name: 'MainScreen',
  components: {
    GameContainer,
    TopSection,
    BottomSection,
    LevelComplete,
    WelcomeScreen,
    EndOfLevelsScreen,
    InstructionPopup,
    SettingsDialog,
    InterstitialAd,
    RewardedAd,
    UpgradeDialogWelcome,
    UpgradeDialogInterstitial,
    UpgradeDialogUndoReward,
    CompanyText,
    BackdoorDialog,
    RatingsDialog,
    ClearProgressDialog,
  },
  data() {
    return {
      resetGame: Date.now(),
      undoMove: Date.now(),
      showLevelComplete: false,
      slideDirectionSign: 1,
      slideVarStyle: {
        '--slide-direction': '100%',
      },
      nudgeX: 0,
      undoCount: 0,
      currentGameLevelCount: 0,
      // ad stuff
      showInterstitialAd: false,
      interstitalAdReady: false,
      showRewardedAd: false,
      rewardedAdReady: false,
    }
  },
  computed: {
    currentLevel() {
      return this.$store.getters.currentLevel
    },
    currentLevelDef() {
      return this.$store.getters.currentLevelDef
    },
    lives() {
      if (this.currentLevel >= 0) {
        return this.$store.getters.levels[this.currentLevel]?.lives || 0
      } else {
        return 0
      }
    },
    levelCount() {
      return this.$store.getters.levels.length
    },
    showInstructionPopup() {
      return this.$store.getters.showInstructionPopup
    },
    showSettingsPopup() {
      return this.$store.getters.showSettingsPopup
    },
    musicEnabled() {
      return this.$store.getters.musicEnabled
    },
    showBlackScreen() {
      return this.$store.getters.showBlackScreen
    },
    showInformationPopup() {
      return this.$store.getters.showInformationPopup
    },
    showBackdoorDialog() {
      return this.$store.getters.showBackdoorDialog
    },
    isUndoUnlocked() {
      return this.$store.getters.isUndoUnlocked
    },
    anyPurchaseMade() {
      return this.$store.getters.anyPurchaseMade
    },
    showRatingsDialog() {
      return this.$store.getters.showRatingsDialog
    },
    showClearProgressDialog() {
      return this.$store.getters.showClearProgressDialog
    },
  },
  methods: {
    reset() {
      this.resetGame = Date.now()
      this.undoCount = 0
    },
    undo() {
      if (
        this.anyPurchaseMade ||
        this.isUndoUnlocked ||
        !this.rewardedAdReady
      ) {
        this.undoMove = Date.now()
      } else {
        this.$store.commit('SET_SHOW_UPGRADE_DIALOG', 'undo_reward')
      }
    },
    moveUndone() {
      this.undoCount += 1
    },
    levelComplete() {
      this.showLevelComplete = true
      playWin()
    },
    moveToNextLevel() {
      this.showLevelComplete = false
      setTimeout(() => {
        this.nextLevel()
      }, 500)
    },
    nextLevel() {
      this.slideVarStyle = {
        '--slide-direction': '100%',
      }

      if (
        config.MONETISATION_STRATEGY == 'level_def' &&
        !(this.currentLevelDef === undefined) &&
        this.currentLevelDef.showAdAfter === true &&
        !this.anyPurchaseMade
      ) {
        this.showInterstitialAd = true
      }

      const nextLevel = Math.min(this.currentLevel + 1, this.levelCount)
      if (nextLevel == 0) {
        this.currentGameLevelCount = 0
      } else {
        this.currentGameLevelCount += 1
      }

      this.undoCount = 0
      this.rewardedAdReady = false
      this.$store.commit('SET_IS_UNDO_UNLOCKED_FOR_LEVEL', false)
      this.$store.dispatch('set_current_level', nextLevel)

      this.prepareAds()
      this.showDialogs()

      if (nextLevel == this.levelCount) {
        this.animateBackgroundContinuously()
      } else {
        this.nudgeBackground()
      }
    },
    continueFromMaxLevel() {
      this.slideVarStyle = {
        '--slide-direction': '100%',
      }

      this.undoCount = 0
      this.rewardedAdReady = false
      this.currentGameLevelCount = 0
      this.$store.commit('SET_IS_UNDO_UNLOCKED_FOR_LEVEL', false)
      this.$store.dispatch('set_current_level', this.$store.getters.maxLevel)
      this.prepareAds()
      this.nudgeBackground()
    },
    prevLevel() {
      this.slideVarStyle = {
        '--slide-direction': '-100%',
      }

      this.$store.dispatch(
        'set_current_level',
        Math.max(this.currentLevel - 1, 0)
      )
    },
    homeScreen() {
      this.slideVarStyle = {
        '--slide-direction': '-100%',
      }
      this.$store.dispatch('set_current_level', -1)
      this.nudgeBackground()
    },
    nudgeBackground() {
      this.$emit('nudge_background')
    },
    animateBackgroundContinuously() {
      this.$emit('animate_background')
    },
    showDialogs() {
      const ratingsDialogVariant = this.currentLevelDef?.showRatingReminder
      if (
        !(ratingsDialogVariant === undefined) &&
        !this.$store.getters.hasAttemptedRating &&
        (isAndroid || isiOS || devMode)
      ) {
        setTimeout(() => {
          this.$store.commit('SET_SHOW_RATINGS_DIALOG', ratingsDialogVariant)
        }, 1000)
      }
    },
    prepareAds() {
      if (config.MONETISATION_STRATEGY == 'level_def') {
        if (
          !(this.currentLevelDef === undefined) &&
          this.currentLevelDef.showAdAfter === true &&
          !this.anyPurchaseMade
        ) {
          this.prepareInterstitialAd()
        }
        if (
          !this.isUndoUnlocked &&
          !(this.currentLevelDef === undefined) &&
          this.currentLevelDef.lives > 0 &&
          !this.anyPurchaseMade
        ) {
          this.prepareRewardedAd()
        }
      }
    },
    prepareRewardedAd() {
      if (!this.rewardedAdReady) {
        consoleLog('Admob: attempting to prepare next rewarded ad')

        AdMob.prepareRewardVideoAd(rewardedAdOptions)
          .then((info) => {
            consoleLog('Admob: Rewarded ad prepared successfully: ', info)
            this.rewardedAdReady = true
          })
          .catch((error) => {
            consoleLog('Admob: Rewarded ad preparation failed: ', error)
            this.rewardedAdReady = false
          })
      }
    },
    prepareInterstitialAd() {
      if (!this.interstitalAdReady) {
        AdMob.prepareInterstitial(interstitialAdOptions)
          .then((info) => {
            consoleLog('Admob: Interstitial ad prepared successfully: ', info)
            this.interstitalAdReady = true
          })
          .catch((error) => {
            consoleLog('Admob: Interstitial ad preparation failed: ', error)
            this.interstitalAdReady = false
          })
      }
    },
    showInterstitialAdDone() {
      this.interstitalAdReady = false
      this.showInterstitialAd = false
    },
    showRewardedAdDone() {
      this.showRewardedAd = false
    },
  },
  watch: {
    musicEnabled(new_val) {
      if (!new_val) {
        stopMusic()
      } else {
        startMusic()
      }
    },
    currentLevel(new_val, old_val) {
      if (old_val == -1 && new_val > -1) {
        logger.setScreen('game')
      }
      this.$store.commit('SET_THROB_RESET_BUTTON', false)
    },
  },
}
</script>

<style scoped>
.fullscreen-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-container-outer-wrapper {
  flex-grow: 1;
  width: 100%;
  position: relative;
}

.game-container-inner-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-screen {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: black;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.5s;
}

.slide-enter {
  transform: translateX(var(--slide-direction));
}

.slide-enter-active {
  /*
  to match gsap power1.out ease 
  https://greensock.com/forums/topic/13193-default-greensock-ease-as-a-css-easing-function/
  */
  transition: transform 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slide-leave-to {
  transform: translateX(calc(var(--slide-direction) * -1));
}

.slide-leave-active {
  /*
  to match gsap power1.out ease 
  https://greensock.com/forums/topic/13193-default-greensock-ease-as-a-css-easing-function/
  */
  transition: transform 0.9s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

/*.popup-enter {
  transform: translateY(-2rem);
  opacity: 0;
}

.popup-enter-active {
  transition: transform 0.5s, opacity 0.5s;
}

.popup-leave-to {
  transform: translateY(2rem);
  opacity: 0;
}

.popup-leave-active {
  transition: transform 0.5s, opacity 0.5s;
}*/
.popup-enter {
  transform: translateY(-4rem);
  opacity: 0;
}

.popup-enter-active {
  transition: transform 0.5s, opacity 0.4s;
}

.popup-leave-to {
  transform: translateY(-4rem);
  opacity: 0;
}

.popup-leave-active {
  transition: transform 0.5s, opacity 0.4s ease 0.1s;
}
</style>
