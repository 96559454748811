import { getRemoteConfig, getValue } from 'firebase/remote-config'
import { devMode, isMobile } from '@/lib/helpers.js'
import consoleLog from '@/lib/consoleLog.js'

const STATIC_CONFIG = {
  APP_VERSION: '1.4.0',
  /*
    FAKE_ANALYTICS = true does not prevent the native first_open event being sent to 
    GA4. This can screw up your user counts for the day. To prevent this:

    In XCode remember to set FIREBASE_ANALYTICS_COLLECTION_ENABLED to NO in info.plist
    In Android Studio, set the value of firebase_analytics_collection_enabled to false in your app's
    AndroidManifest.xml in the application tag.

    (https://firebase.google.com/docs/analytics/configure-data-collection?platform=android)
  */
  FAKE_ANALYTICS: devMode,
  USE_PRODUCTION_ADS: true,
  SCREENSHOT_MODE: false,
}

function setDefaultConfigVals() {
  const remoteConfig = getRemoteConfig()

  remoteConfig.defaultConfig = {
    MONETISATION_STRATEGY: 'level_def',
    PREMIUM_VERSION_ITEM: 'ad_free_version_production',
    PREMIUM_VERSION_PRODUCTS: JSON.stringify([
      'ad_free_version_production',
      'coffee_production',
      'beer_production',
    ]),
    PURCHASE_TIMEOUT: 1000 * 120,
    WELCOME_UPGRADE_TEXT: JSON.stringify({
      version: '1',
      introText: `
      <p>Hello! We hope you're enjoying the game!</p>
      <p>We want to keep things free to play for you, but we've all got bills to pay and ads help support the work
      that goes into producing a game like this.</p>
      <p>Want to get rid of the ads forever? Just upgrade to the premium version below.</p>
    `,
      thanksText: `
      <p>Premium version enabled.</p>
      <p>Thanks for your support &#x1F64F;</p>
    `,
      errorText: `
      <p>Sorry - looks like something went wrong with your upgrade.</p>
      <p>Please try again later.</p>
      <p>You can find the upgrade option in the settings in the top-right
      corner.</p>
    `,
      ctaText: `Upgrade for {PRICE}`,
    }),
    INTERSTITIAL_UPGRADE_TEXT: JSON.stringify({
      version: '1',
      introText: `
      <p>We're sorry about the ads!</p>
      <p>We want to keep things free to play for you, but we've all got bills to pay and ads help support the work that goes into producing a game like this.</p>
      <p>Want to get rid of the ads forever? Just upgrade to the premium version below.</p>
    `,
      settingsIntroText: `
      <p>Upgrade to the premium version to remove ads.</p>
    `,
      thanksText: `
      <p>Premium version enabled.</p>
      <p>Thanks for your support &#x1F64F;</p>
    `,
      errorText: `
      <p>Sorry - looks like something went wrong with your upgrade.</p>
      <p>Please try again later.</p>
      <p>You can find the upgrade option in the settings in the top-right
      corner.</p>
    `,
      remindText: `
      <p>No problem!</p>
      <p>In case you change your mind, you can always upgrade to the premium version by opening the settings in the top-right corner.</p>
    `,
      ctaText: `Upgrade for {PRICE}`,
    }),
    REWARDED_UPGRADE_TEXT: JSON.stringify({
      version: '1',
      introTextFirst: `
        <p>Watch the following video to unlock the undos for the rest of the game!</p>
        <p>
          Watching ads helps support all the hard work that goes into making a
          game like this.
        </p>
        <p>
          Don't like these ads? Upgrade to the premium version to get rid of
          them forever.
        </p>
      `,
      introTextAll: `
        <p>Watch the following video to unlock the undos!</p>
        <p>
          Watching ads helps support all the hard work that goes into making a
          game like this.
        </p>
        <p>
          Don't like these ads? Upgrade to the premium version to get rid of
          them forever.
        </p>
      `,
      thanksText: `
        <p>Premium version enabled.</p>
        <p>Thanks for your support &#x1F64F;</p>
      `,
      errorText: `
        <p>Sorry - looks like something went wrong with your upgrade.</p>
        <p>Please try again later.</p>
      `,
      ctaText: `Upgrade for {PRICE}`,
    }),

    SETTINGS_UPGRADE_TEXT: JSON.stringify({
      version: '1',
      introText: `
      <p>Upgrade to the premium version to remove ads.</p>
    `,
      thanksText: `
      <p>Premium version enabled.</p>
      <p>Thanks for your support &#x1F64F;</p>
    `,
      errorText: `
      <p>Sorry - looks like something went wrong with your upgrade.</p>
      <p>Please try again later.</p>
    `,
      ctaText: `Upgrade for {PRICE}`,
    }),

    RATINGS_DIALOG_TEXT: JSON.stringify({
      version: '1',
      firstContentiOS: `
        <p>Phew, that was a tough one!</p>
        <p style="font-size: 2rem; margin-top: 0rem; margin-bottom: 0rem">
          &#x1F92F;
        </p>
        <p>
          If you're enjoying the game, perhaps you'd like to help support our work
          by rating us on the App Store?
        </p>
      `,
      firstContentAndroid: `
        <p>Phew, that was a tough one!</p>
        <p style="font-size: 2rem; margin-top: 0rem; margin-bottom: 0rem">
          &#x1F92F;
        </p>
        <p>
          If you're enjoying the game, perhaps you'd like to help support our work
          by rating us on Google Play?
        </p>
      `,
      secondContentiOS: `
        <p>You're making great progress!</p>
        <p style="font-size: 2rem; margin-top: 0rem; margin-bottom: 0rem">
          &#x1F44D;
        </p>
        <p>
          Help spread the word about Bikit by rating us on the App Store.
        </p>
      `,
      secondContentAndroid: `
        <p>You're making great progress!</p>
        <p style="font-size: 2rem; margin-top: 0rem; margin-bottom: 0rem">
          &#x1F44D;
        </p>
        <p>
          Help spread the word about Bikit by rating us on Google Play.
        </p>
      `,
    }),
    LEVEL_DEF: JSON.stringify({
      version: '2',
      unlockUndosWithRewarded: 'first',
      levels: [
        // tutorial
        { key: '2x2_t2_1', lives: 0 },
        { key: '2x3_t2_1', lives: 0 },
        { key: '2x3_t2_2', lives: 0 },
        { key: '3x3_t2_6', lives: 0 },
        { key: '3x3_t2_2', lives: 0 },
        // end tutorial
        { key: '3x3_t2_5', lives: 1 },
        { key: '3x3_t2_4', lives: 1, showAdAfter: true },
        { key: '3x3_t2_1', lives: 1 },
        { key: '3x3_t22_1', lives: 2, showAdAfter: true },
        { key: '3x3_t23_1', lives: 2 },
        { key: '3x4_t2_1', lives: 2, showAdAfter: true },
        { key: '4x4_t23_1', lives: 2 },
        { key: '5x5_t2_1', lives: 2, showAdAfter: true },
        { key: '4x4_t23_2', lives: 2 },
        {
          key: '3x4_t2_2',
          lives: 2,
        },
        { key: '4x3_t23_1', lives: 2, showRatingReminder: 'first' },
        { key: '5x5_t23_1', lives: 2, showAdAfter: true },
        { key: '5x4_t2_2', lives: 2 },
        { key: '4x5_t234_1', lives: 2, showAdAfter: true },
        { key: '5x5_t23_2', lives: 2 },
        { key: '5x5_t2_2', lives: 2, showAdAfter: true },
        { key: '6x6_t23_1', lives: 2 },
        { key: '6x6_t22_1', lives: 2, showAdAfter: true },
        { key: '6x5_t2_1', lives: 2 },
        { key: '6x5_t23_1', lives: 2, showAdAfter: true },
        { key: '5x5_t234_1', lives: 2 },
        { key: '6x6_t2345_1', lives: 2, showAdAfter: true },
        { key: '6x4_t2233_1', lives: 2 },
        {
          key: '5x5_t2_3',
          lives: 2,
          showAdAfter: true,
          showRatingReminder: 'second',
        },
        { key: '6x6_t2_2', lives: 2 },
        { key: '5x5_t2345_1', lives: 2, showAdAfter: true },
        { key: '6x5_t2_2', lives: 2 },
        { key: '4x4_t2345_1', lives: 2, showAdAfter: true },
        { key: '5x5_t2345_2', lives: 2 },
        { key: '5x5_t234_2', lives: 2, showAdAfter: true },
        { key: '6x6_t2_1', lives: 2 },
        { key: '6x4_t2_1', lives: 2, showAdAfter: true },
        { key: '5x4_t2_1', lives: 2 },
        { key: '6x6_t23_2', lives: 2, showAdAfter: true },
        { key: '5x6_t23_1', lives: 2 },
      ],
    }),
  }
}

/* Config getting strategies */
const getStatic = (k) => {
  return Promise.resolve(STATIC_CONFIG[k])
}

const remoteJSON = (k) => {
  const jsonString = getValue(getRemoteConfig(), k).asString()
  return Promise.resolve(JSON.parse(jsonString))
}

const remoteString = (k) => {
  return Promise.resolve(getValue(getRemoteConfig(), k).asString())
}

const remoteNumber = (k) => {
  return Promise.resolve(getValue(getRemoteConfig(), k).asNumber())
}

/*const remoteStringFromDefault = (k) => {
  const remoteConfig = getRemoteConfig()
  return Promise.resolve(remoteConfig.defaultConfig[k])
}*/

/*const remoteBool = (k) => {
  return Promise.resolve(getValue(getRemoteConfig(), k).asBoolean())
}*/

/*const remoteOnceThenStorageString = (k) => {
  consoleLog('remoteOnceThenStorageString: ', k)
  return Storage.get({ key: k }).then(({ value }) => {
    consoleLog('remoteOnceThenStorageString: returned value ', value)
    if (value === null) {
      const remoteVal = getValue(getRemoteConfig(), k).asString()
      Storage.set({ key: k, value: remoteVal })
      consoleLog('remoteOnceThenStorageString: returning ', remoteVal)
      return remoteVal
    } else {
      consoleLog('remoteOnceThenStorageString: returning ', value)
      return value
    }
  })
}*/

let config = {}

const configUpdater = {
  APP_VERSION: getStatic,
  FAKE_ANALYTICS: getStatic,
  USE_PRODUCTION_ADS: getStatic,
  SCREENSHOT_MODE: getStatic,
  //MONETISATION_STRATEGY: remoteString, //devMode ? remoteString : remoteOnceThenStorageString,
  //MONETISATION_STRATEGY: remoteOnceThenStorageString,
  MONETISATION_STRATEGY: remoteString,
  PREMIUM_VERSION_ITEM: remoteString,
  PREMIUM_VERSION_PRODUCTS: remoteJSON,
  PURCHASE_TIMEOUT: remoteNumber,
  WELCOME_UPGRADE_TEXT: remoteJSON,
  INTERSTITIAL_UPGRADE_TEXT: remoteJSON,
  REWARDED_UPGRADE_TEXT: remoteJSON,
  SETTINGS_UPGRADE_TEXT: remoteJSON,
  RATINGS_DIALOG_TEXT: remoteJSON,
  LEVEL_DEF: remoteJSON,
}

function updateConfig() {
  const keys = Object.keys(configUpdater)
  return Promise.all(keys.map((k) => configUpdater[k](k))).then((vals) => {
    keys.forEach((k, i) => {
      consoleLog(`config[${k}] = `, vals[i])
      config[k] = vals[i]
    })

    if (!devMode && !isMobile) {
      config.MONETISATION_STRATEGY = 'none'
    }
  })
}

export { config, updateConfig, setDefaultConfigVals }
