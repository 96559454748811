<template>
  <div class="bottom-section">
    <div
      class="icon-wrapper"
      :class="{ disabled: !canReset }"
      v-ripple="!canReset"
      @click="reset"
    >
      <svg
        class="icon"
        :class="{ throb: throbResetButton && canReset }"
        viewBox="0 0 24 24"
      >
        <path :d="mdiRestore" />
      </svg>
    </div>

    <div
      class="icon-wrapper"
      v-ripple
      :style="{ width: lives == 0 ? 0 : undefined }"
      @click="undo"
      :class="{ disabled: !canUndo }"
    >
      <svg
        class="icon"
        :class="{ throb: throb && canUndo }"
        viewBox="0 0 24 24"
      >
        <path :d="mdiUndoVariant" />
      </svg>
      <div class="icon-count" :style="{ opacity: lives == 0 ? 0 : undefined }">
        <span>{{ remainingLives }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiRestore, mdiUndoVariant } from '@mdi/js'
export default {
  name: 'BottomSection',
  data() {
    return {
      mdiRestore,
      mdiUndoVariant,
      livesDisplayed: 0,
      throb: false,
    }
  },
  props: {
    lives: { type: Number, default: 0 },
    undosUsed: { type: Number, default: 0 },
  },
  computed: {
    remainingLives() {
      return this.lives - this.undosUsed
    },
    canReset() {
      return this.$store.getters.canReset
    },
    canUndo() {
      return this.$store.getters.canUndo && this.remainingLives > 0
    },
    isUndoUnlocked() {
      return this.$store.getters.isUndoUnlocked
    },
    throbResetButton() {
      return this.$store.getters.throbResetButton
    },
  },
  methods: {
    undo() {
      if (this.canUndo) {
        this.$emit('undo')
        this.throb = false
      }
    },
    reset() {
      if (this.canReset) {
        this.$store.commit('SET_THROB_RESET_BUTTON', false)
        this.$emit('reset')
      }
    },
  },
  watch: {
    lives(new_val) {
      if (new_val > 0) {
        this.livesDisplayed = new_val
      }
    },
    isUndoUnlocked(new_val, old_val) {
      if (!old_val && new_val) {
        this.throb = true
      }
    },
  },
}
</script>

<style scoped>
.bottom-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem;
  transition: opacity 0.5s;
}

.icon-wrapper {
  position: relative;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.5rem;
  height: 4.5rem;
  transition: width 0.5s, filter 0.5s;
}

.icon-wrapper.disabled {
  filter: brightness(0.3);
}

.icon-count {
  position: absolute;
  top: 85%;
  transition: opacity 0.5s;
}

.icon {
  width: 3.5rem;
  cursor: pointer;
}

.icon path {
  fill: var(--main-item-colour);
}

.icon.throb path {
  animation-name: icon-throb-animation;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  transform-origin: center;
}

@keyframes icon-throb-animation {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
</style>
