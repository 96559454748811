import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Ripple from 'vue-material-design-ripple'
import 'vue-material-design-ripple/dist/vue-material-design-ripple.css'

Vue.directive('ripple', Ripple)
Vue.config.productionTip = false

new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app')
