import { Capacitor } from '@capacitor/core'
import Vue from 'vue'
import consoleLog from '@/lib/consoleLog.js'

const platform = Capacitor.getPlatform()
const isMobile = ['ios', 'android'].includes(platform)
const isWeb = !isMobile
const isAndroid = platform == 'android'
const isiOS = platform == 'ios'
const devMode = Vue.config.devtools

consoleLog('devMode is ', devMode)

const isBool = (x) => x === true || x === false
const isInt = (x) => typeof x == 'number'
const isString = (x) => typeof x === 'string' || x instanceof String
const isJson = (str) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export {
  isBool,
  isInt,
  isJson,
  isString,
  isMobile,
  devMode,
  isAndroid,
  isWeb,
  isiOS,
  platform,
}
