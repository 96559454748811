<template>
  <div class="fullscreen-background" ref="main-container">
    <canvas class="canvas-container" id="canvas"></canvas>
    <div class="background-overlay"></div>
  </div>
</template>

<script>
import gsap from 'gsap'
import consoleLog from '@/lib/consoleLog.js'

export default {
  name: 'BackGround2',
  data() {
    return {
      ctx: undefined,
      points: 100,
      maxPointGap: 20,
      colours: [
        '#c95fcf',
        '#cc52b5',
        '#cb479c',
        '#c63f85',
        '#bf396f',
        '#b4365b',
        '#a83549',
        '#9b353a',
        '#8c352c',
        '#7d3420',
        '#6e3417',
        '#60320f',
        '#513009',
        '#442d04',
      ],
      waves: [],
      animationFrameRequestId: undefined,
      xNudge: undefined,
      lastXnudge: undefined,
    }
  },
  props: {
    nudgeVal: Number,
    animateVal: Number,
  },
  mounted() {
    const canvasElem = document.getElementById('canvas')
    this.width = this.$refs['main-container'].offsetWidth
    this.height = this.$refs['main-container'].offsetHeight
    canvasElem.width = this.width
    canvasElem.height = this.height
    this.ctx = canvasElem.getContext('2d')

    this.makeWaves()
    this.drawAllWaves()

    /*this.animationFrameRequestId = window.requestAnimationFrame(
      this.animateScenery.bind(this, this.waves, undefined)
    )*/
  },
  beforeDestroy() {
    consoleLog('beforeDESTROY')
    window.cancelAnimationFrame(this.animationFrameRequestId)
  },
  methods: {
    stopAnimation() {
      window.cancelAnimationFrame(this.animationFrameRequestId)
    },
    nudge() {
      this.animationFrameRequestId = window.requestAnimationFrame(
        this.drawAnimationFrame
      )
      gsap.fromTo(
        this,
        { xNudge: 0 },
        {
          xNudge: this.width * 3,
          duration: 1,
          ease: 'power1.out',
          onComplete: () => {
            window.cancelAnimationFrame(this.animationFrameRequestId)
            this.xNudge = undefined
            this.lastXnudge = undefined
          },
        }
      )
    },
    makeWaves() {
      this.colours.forEach((c, i) => {
        // frequency looks good for width of 700, so we scale according to the new width
        const frequency =
          (Math.floor(Math.random() * 5) + 3) * (this.width / 700)
        this.waves.push({
          xPosArray: this.fillArrayUntilGreaterThan([0], this.width),
          frequency: frequency,
          angleOffset: (frequency * Math.PI * Math.random()) % (2 * Math.PI),
          // amplitude looks good for height of approx 700, so we scale according to new height
          amplitude:
            (Math.floor(Math.random() * 50) + 60) * (this.height / 700),
          height: ((this.height - 50) / this.colours.length) * i,
          colour: c,
          multiplier: i / (this.colours.length - 1) / 3,
        })
      })
    },
    updateWaves(xShift) {
      this.waves.forEach((w) => {
        let newXposArray = w.xPosArray

        if (!(xShift === undefined)) {
          const xShiftMultiplied = xShift * w.multiplier
          newXposArray = this.shiftXposArrayRight(
            newXposArray,
            xShiftMultiplied
          )
          newXposArray = this.fillArrayUntilGreaterThan(
            newXposArray,
            this.width
          )

          w.angleOffset =
            (w.angleOffset +
              (xShiftMultiplied / this.width) * (w.frequency * Math.PI)) %
            (2 * Math.PI)
        }
        w.xPosArray = newXposArray
      })
    },
    animateScenery(waves, lastTimestamp, timestamp) {
      if (!(lastTimestamp === undefined)) {
        const xShift = timestamp - lastTimestamp
        this.updateWaves(xShift)
      }

      this.drawAllWaves()

      this.animationFrameRequestId = window.requestAnimationFrame(
        this.animateScenery.bind(this, waves, timestamp)
      )
    },
    drawAllWaves() {
      this.ctx.fillStyle = this.colours[0]
      this.ctx.fillRect(0, 0, this.width, this.height)

      this.waves.forEach((w) => {
        this.drawWave(
          w.xPosArray,
          w.height,
          w.colour,
          w.frequency,
          w.angleOffset,
          w.amplitude
        )
      })
    },
    drawWave(xPosArray, height, colour, frequency, angleOffset, amplitude) {
      this.ctx.beginPath()

      xPosArray.reduce((s, x) => {
        const angle =
          ((s + x) / this.width) * (frequency * Math.PI) + angleOffset
        this.ctx.lineTo(s + x, height + Math.cos(angle) * amplitude)
        return s + x
      }, 0)

      this.ctx.lineTo(this.width, this.height)
      this.ctx.lineTo(0, this.height)
      this.ctx.fillStyle = colour
      this.ctx.fill()
    },
    fillArrayUntilGreaterThan(array, target) {
      let newArray = [...array]
      const sum = (a) => a.reduce((s, x) => s + x, 0)

      while (sum(newArray) <= target) {
        const randVal = Math.floor(Math.random() * this.maxPointGap) + 1
        newArray.push(
          // breaks look good for width of approx 700, so we scale for new width
          randVal * (this.width / (this.points * (this.width / 700)))
        )
      }

      return newArray
    },
    shiftXposArrayRight(xPosArray, xShift) {
      let newArray = [...xPosArray]

      newArray[0] = newArray[0] - xShift

      while (Math.abs(newArray[0]) > newArray[1]) {
        newArray[1] = newArray[0] + newArray[1]
        newArray.shift()
      }

      return newArray
    },
    drawAnimationFrame() {
      if (!(this.lastXnudge === undefined) && !(this.xNudge === undefined)) {
        this.updateWaves(this.xNudge - this.lastXnudge)
        this.drawAllWaves()
      }
      this.lastXnudge = this.xNudge
      this.animationFrameRequestId = window.requestAnimationFrame(
        this.drawAnimationFrame
      )
    },
  },
  watch: {
    /*xNudge(new_val, old_val) {
      if (
        !(old_val === undefined) &&
        !(new_val === undefined) &&
        new_val > old_val
      ) {
        this.updateWaves(new_val - old_val)
        this.drawAllWaves()
      }
    },*/
    nudgeVal() {
      window.cancelAnimationFrame(this.animationFrameRequestId)
      this.nudge()
    },
    animateVal() {
      window.cancelAnimationFrame(this.animationFrameRequestId)

      let lastTimestamp = undefined
      const animationFrame = (timestamp) => {
        if (!(lastTimestamp === undefined)) {
          this.updateWaves(timestamp - lastTimestamp)
          this.drawAllWaves()
        }
        lastTimestamp = timestamp
        this.animationFrameRequestId =
          window.requestAnimationFrame(animationFrame)
      }
      this.animationFrameRequestId =
        window.requestAnimationFrame(animationFrame)
    },
  },
}
</script>

<style scoped>
.fullscreen-background {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.canvas-container {
  width: 100%;
  height: 100%;
}

.background-overlay {
  background-color: black;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.4;
}
</style>
