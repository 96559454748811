<template>
  <div id="app" :style="colourStyle">
    <BackGround2
      style="z-index: -1"
      :nudgeVal="nudgeVal"
      :animateVal="animateVal"
    />
    <transition name="fade">
      <MainScreen
        v-if="ready"
        @nudge_background="nudgeBackground"
        @animate_background="animateBackground"
      />
    </transition>
    <transition name="fade">
      <div v-if="showOverlay" class="background-overlay"></div>
    </transition>
    <transition name="bottom-popup">
      <CookiePopup
        v-if="showCookiePopup && ready && isWeb"
        @hide="showCookiePopup = false"
      />
    </transition>
  </div>
</template>

<script>
import MainScreen from '@/views/MainScreen.vue'
import BackGround2 from '@/components/Background2.vue'
import CookiePopup from '@/components/CookiePopup.vue'

import colours from '@/lib/colours.js'
import { initialiseServices, logger } from '@/lib/firebase.js'
import { SplashScreen } from '@capacitor/splash-screen'
import { isAndroid, isWeb } from '@/lib/helpers.js'
import consoleLog from '@/lib/consoleLog.js'

export default {
  name: 'App',
  data() {
    return {
      ready: false,
      showOverlay: isAndroid,
      nudgeVal: Date.now(),
      animateVal: Date.now(),
      showCookiePopup: false,
      isWeb,
    }
  },
  components: {
    MainScreen,
    BackGround2,
    CookiePopup,
  },
  computed: {
    colourStyle() {
      return Object.keys(colours).reduce((s, x) => {
        s['--' + x] = colours[x]
        return s
      }, {})
    },
    productsKnown() {
      return this.$store.getters.productsKnown
    },
    productsOwned() {
      return this.$store.getters.productsOwned
    },
  },
  mounted() {
    Promise.all([
      initialiseServices().then(() => {
        return this.$store.dispatch('initialise')
      }),
      document.fonts.ready.then(() => {
        return SplashScreen.hide().then(() => {
          this.showOverlay = false
        })
      }),
      new Promise((resolve) => {
        this.showCookiePopup = !(
          window.localStorage.getItem('cookieConsentGiven') == 'true'
        )
        resolve()
      }),
    ]).then(() => {
      logger.setAttProperty()
      this.ready = true
    })

    if (!(window.facebookConnectPlugin === undefined)) {
      window.facebookConnectPlugin.getApplicationId((id) => {
        consoleLog(`Facebook application ID: ${id}`)
      })
      window.facebookConnectPlugin.getApplicationName((name) => {
        consoleLog(`Facebook application name: ${name}`)
      })
    }
  },
  methods: {
    nudgeBackground() {
      this.nudgeVal = Date.now()
    },
    animateBackground() {
      this.animateVal = Date.now()
    },
  },
  watch: {
    productsKnown(new_val, old_val) {
      consoleLog('productsKnown changed:', old_val, 'to', new_val)
    },
    productsOwned(new_val, old_val) {
      /*
        We add any new products owned to the persisted state, but leave
        removing products to the refunded event handled in purchases.js
      */
      consoleLog('productsOwned changed:', old_val, 'to', new_val)
      new_val
        .filter((x) => !old_val.includes(x))
        .forEach((x) => {
          this.$store.dispatch('add_product_owned', x)
        })
    },
  },
}
</script>

<style>
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-VariableFont_wght.ttf')
    format('truetype-variations');
  font-weight: 100 900;
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

#app {
  font-family: 'Montserrat', sans-serif;
  color: var(--main-item-colour);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

* {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.text-small {
  font-size: 1rem;
  font-weight: 300;
}

@media screen and (min-height: 700px) {
  .text-small {
    font-size: 1.05rem;
    font-weight: 300;
  }
}

@media screen and (max-height: 600px) {
  .text-small {
    font-size: 0.925rem;
    font-weight: 300;
  }
}
</style>

<style scoped>
.background-overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #303030;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.bottom-popup-enter /deep/ .cookie-fullscreen-overlay,
.bottom-popup-leave-to /deep/ .cookie-fullscreen-overlay {
  opacity: 0;
}

.bottom-popup-enter /deep/ .cookie-popup-container,
.bottom-popup-leave-to /deep/ .cookie-popup-container {
  transform: translate(-50%, 100%);
}

.bottom-popup-enter-active /deep/ .cookie-fullscreen-overlay,
.bottom-popup-leave-active /deep/ .cookie-fullscreen-overlay {
  transition: opacity 0.5s;
}

.bottom-popup-enter-active /deep/ .cookie-popup-container,
.bottom-popup-leave-active /deep/ .cookie-popup-container {
  transition: transform 0.5s;
}

.bottom-popup-enter-active,
.bottom-popup-leave-active {
  transition: opacity 0.5s;
}

.bottom-popup-enter-active,
.bottom-popup-leave-active {
  transition: transform 0.5s;
}
</style>
