import { config } from '@/lib/dynamicConfig.js'
import { devMode, platform, isiOS } from '@/lib/helpers.js'
import { AppTrackingTransparency } from 'capacitor-plugin-app-tracking-transparency'

class AnalyticsLogger {
  level_id // The ordinal level index
  level_name // The level key
  level_count // How many levels passed since app open/start of new game
  level_undos // How many undos available in the level
  level_start_time // Start time of the level

  first_welcome // If this is the first welcome screen view after app opens

  move_count_since_reset // How many moves made since last reset / start of level
  move_count_total // Total number of moves made in the level so far
  reset_count // Total number of resets made in the level so far
  undo_count // Total number of undos made in the level so far
  undos_unlocked // Are the undos unlocked for the level yet

  constructor(analyticsWrapper) {
    this.analyticsWrapper = analyticsWrapper

    this.setUserProp({
      name: 'app_version',
      value: config.APP_VERSION,
    })
    this.setUserProp({
      name: 'monetisation_strategy',
      value: config.MONETISATION_STRATEGY,
    })

    this.setUserProp({
      name: 'level_def_version',
      value: config.LEVEL_DEF.version,
    })

    this.setUserProp({
      name: 'dev_mode',
      value: devMode,
    })

    this.setUserProp({
      name: 'capacitor_platform',
      value: platform,
    })
  }

  logEvent(event, params) {
    this.analyticsWrapper.logEvent(event, params)
  }

  logEventFB(event, params = {}, val = 1) {
    this.analyticsWrapper.logEventFB(event, params, val)
  }

  setUserProp(params) {
    this.analyticsWrapper.setUserProperty(params.name, params.value)
  }

  setScreen(name) {
    this.analyticsWrapper.setScreenName(name, name)
  }

  setAttProperty() {
    if (isiOS) {
      AppTrackingTransparency.getStatus().then((response) => {
        this.setUserProp({
          name: 'att',
          value: response.status,
        })
      })
    } else {
      this.setUserProp({
        name: 'att',
        value: 'na',
      })
    }
  }

  showPopup(name, additionalVals) {
    let params = {
      name: name,
      ...this.getLevelDetails(),
      ...additionalVals,
    }

    this.logEvent('show_popup', params)
  }

  closePopup(name, additionalVals) {
    let params = {
      name: name,
      ...this.getLevelDetails(),
      ...additionalVals,
    }

    this.logEvent('close_popup', params)
  }

  transitionPopup(name, additionalVals) {
    let params = {
      name: name,
      ...this.getLevelDetails(),
      ...additionalVals,
    }

    this.logEvent('transition_popup', params)
  }

  buttonClick(name, additionalVals) {
    let params = {
      name: name,
      ...this.getLevelDetails(),
      ...additionalVals,
    }

    this.logEvent('button_click', params)
  }

  tutorialBegin() {
    this.logEvent('tutorial_begin')
    this.logEventFB('tutorial_begin')
  }

  tutorialComplete() {
    this.logEvent('tutorial_complete')
    this.logEventFB('tutorial_complete')
  }

  levelStart({ level_id, level_name, level_undos, undos_unlocked }) {
    this.level_count = (this.level_count || 0) + 1
    this.level_id = level_id
    this.level_name = level_name
    this.level_undos = level_undos
    this.level_start_time = Date.now()

    this.resetMoveMetrics(level_undos, undos_unlocked)

    this.logEvent('level_start', {
      ...this.getLevelDetails(),
    })
  }

  levelEnd() {
    let params = {
      ...this.getLevelDetails(),
      ...this.getMoveDetails(),
    }
    if (!(this.level_start_time === undefined)) {
      params['time_elapsed'] = (Date.now() - this.level_start_time) / 1000
    }
    this.logEvent('level_end', params)
    this.logEventFB('level_end', params, 1)
  }

  purchase(vals) {
    this.logEvent('purchase', vals)
    this.logEventFB('purchase', vals, 1)
  }

  purchaseCancelled(vals) {
    this.logEvent('purchase_cancelled', vals)
  }

  purchaseError(vals) {
    this.logEvent('purchase_error', vals)
  }

  purchaseTimeout(vals) {
    this.logEvent('purchase_timeout', vals)
  }

  resetMoveMetrics(undos_unlocked = true) {
    this.move_count_since_reset = 0
    this.move_count_total = 0
    this.reset_count = 0
    this.undo_count = 0
    this.undos_unlocked = undos_unlocked
  }

  makeMove(move_id) {
    this.move_count_since_reset += 1
    this.move_count_total += 1

    let params = {
      move_id: move_id,
      ...this.getLevelDetails(),
      ...this.getMoveDetails(),
    }

    this.logEvent('move', params)
  }

  undoMove(move_id) {
    this.undo_count += 1
    this.undos_unlocked = true

    let params = {
      move_id: move_id,
      ...this.getLevelDetails(),
      ...this.getMoveDetails(),
    }

    this.logEvent('undo', params)
  }

  unlockUndos(vals = {}) {
    this.undos_unlocked = true
    let params = {
      ...this.getLevelDetails(),
      ...this.getMoveDetails(),
      ...vals,
    }

    this.logEvent('unlock_undos', params)
  }

  resetMoves() {
    this.move_count_since_reset = 0
    this.reset_count += 1

    let params = {
      ...this.getLevelDetails(),
      ...this.getMoveDetails(),
    }

    this.logEvent('reset', params)
  }

  getLevelDetails() {
    const keys = ['level_id', 'level_name', 'level_count', 'level_undos']
    return keys.reduce((s, k) => {
      if (!(this[k] === undefined)) {
        s[k] = this[k]
      }
      return s
    }, {})
  }

  getMoveDetails() {
    const keys = [
      'move_count_since_reset',
      'move_count_total',
      'reset_count',
      'undo_count',
      'undos_unlocked',
    ]
    const vals = keys.reduce((s, k) => {
      if (!(this[k] === undefined)) {
        s[k] = this[k]
      }
      return s
    }, {})
    vals['undos_unlocked'] =
      vals['undos_unlocked'] || this.monetisation_strategy != 'rewarded'
    return vals
  }
}

export { AnalyticsLogger }
